
.cart-location {
  // width: 50% !important;
  // display: flex !important;

  .modal-dialog {
    max-width: 437px;
    text-align: center;
  }

  .modal-content {
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 0 66px 22px !important;
    }
  }

  .cart-modal {
    width: 437px;
    height: 395px;
    text-align: center;
    overflow-y: hidden;
    margin: auto;
  }

  .cart-added {
    width: 141.03px !important;
    height: 135.85px !important;
  }

  .cart-data {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #363636;
  }

  .modal-footer {
    display: inline;
    border: none;
  }

  .shopp {
    background: #009DE0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 14px 21px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .shopp1 {
    background: #FFFFFF;
    border: 1px solid #009DE0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 14px 52px;
    color: #009DE0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .btn-close {
    padding: 10px;
    background-image: url(.//..//../cross.svg);
    background-repeat: no-repeat;
    border: none;
    background-color: white;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}



.cart-location1 {
  // width: 50% !important;
  // display: flex !important;

  .modal-dialog {
    max-width: 437px;
    text-align: center;
  }

  .modal-content {
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 0 66px 22px !important;
    }
  }

  .cart-modal {
    width: 437px;
    height: 395px;
    text-align: center;
    overflow-y: hidden;
    margin: auto;
  }

  .cart-added {
    width: 100px !important;
    height: 135.85px !important;
  }

  .cart-data {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #363636;
    margin-top: 15px;
  }

  .cart-data1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #009DE0;
    margin-top: 125px;
  }

  .modal-footer {
    display: inline;
    border: none;
  }

  .shopp {
    background: #009DE0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 14px 21px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
  }

  .shopp1 {
    background: #FFFFFF;
    border: 1px solid #009DE0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 14px 52px;
    color: #009DE0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .btn-close {
    padding: 10px;
    background-image: url(.//..//../cross.svg);
    background-repeat: no-repeat;
    border: none;
    background-color: white;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}