.browsecategory {
    padding: 60px 0;
    padding-bottom: 30px;

    .main-heading {
        margin-bottom: 30px;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            color: #264653;

            a {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 155%;
                color: #92A2A9;
                margin-left: 10px;

                &:hover {
                    color: #009DE0;
                }
            }
        }
    }

    .card-browse {
        // background: #FFFFFF;
        // border: 1px solid #D9F0FA;
        border-radius: 10px;
        width: 100%;
        height: 138px;
        transition: 0.7s linear;
        cursor: pointer;

        // &:hover {
        //     background: #FFFFFF;
        //     border: 1px solid #D9F0FA;
        //     box-shadow: 0px 13px 30px rgba(0, 157, 224, 0.1) !important;
        //     border-radius: 10px;
        // }

        .inner-img {
            border-radius: 185px;
            background: #F7F8FA;
            padding: 20px;
            width: 140px;
            height: 140px;
            margin: 0 auto;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            text-align: center;
            color: #264653;
            margin-top: 25px;
            // padding: 0 20px;
            white-space: nowrap;
        }
    }

    .owl-carousel .owl-nav {
        position: absolute;
        top: -75px;
        right: 0;
    }

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
        background: #EAF8FE;
        border-radius: 5px;
        width: 30px;
        height: 30px;

        i {
            color: #009DE0;
        }
    }
}


.Browse__by__Category {
    .owl-stage-outer {
        height: 210px;
        overflow-x: hidden !important;
        // overflow: auto !important;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background: #d8f1fc;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #d8f1fc;
        }
    }
}

.loader-image-landing {
    text-align: center;

    img {
        width: 100px;
    }
}

@media (max-width:600px) {
    .browsecategory {
        padding: 20px 0;
    }

    // .browsecategory .main-heading h4 a{
    //     // display: none;
    // }
}