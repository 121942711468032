.main-success {
    background: #FFFFFF;
    padding: 28px 0px;

    .maincart-card {
        background: #F3F3F3;
        padding: 27px 60px;
        border-width: 1px 0px;

        .bread {
            li {
                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 155%;
                    color: #264653;
                }
            }
        }
    }

    .parent1 {
        padding-top: 63px;
        padding-bottom: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .heading {
            h2 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 155%;
                color: #264653;
            }
        }
    }

    .orderbtn {
        margin-top: 20px;
        margin-bottom: 29px;
        display: flex;
        justify-content: center;

        button {
            background: #009DE0;
            border-radius: 5px;
            padding: 20px;
            width: 400px;
            height: 42px;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .back {
        a {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #009DE0;
        }
    }

    .upload-img {
        background: #FFFFFF;
        border: 1px dashed #009DE0;
        border-radius: 8px;
        max-width: 400px;
        width: 100%;
        height: 250px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        cursor: pointer;
        

        h6 {
            font-size: 12px;
            line-height: 100%;
            color: #848A8D;
            
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        //     object-fit: contain;
        // }
        .upload-img-set {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
        }
        .uploaimmg{
            cursor: pointer;
        }
    }
}



.bank-detail-card {
    background: #F5F5F5;
    border-radius: 10px;
    padding: 25px;

    .main-heading {
        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 100%;
            color: #264653;
            text-align: center;
        }
    }

    .bottom-detail {
        margin-top: 30px;

        .inner-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                color: #264653;
            }

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                color: #264653;
            }
        }
    }
}
.bank-side{
    margin-top: 85px;
}
.info-text {
    text-align:-webkit-center !important;
    padding: 10px;
    margin-top: 20px;

    p {
        background: #FFF6EA;
        border-radius: 5px;
        // margin:0 150px;
        width: 1000px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #E15151;
        text-align: center;
    }
}
.info-text1 {
    text-align:-webkit-center !important;
    padding: 10px;
    margin-top: 20px;
    display: none !important;

    p {
        background: #FFF6EA;
        border-radius: 5px;
        // margin:0 150px;
        width: 1000px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #E15151;
        text-align: center;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
   
    .main-success .orderbtn button {
        width: 100%;
    }
    .info-text {
        text-align:-webkit-center !important;
        padding: 10px;
        margin-top: 200px;
        display: none;
    
        p {
            background: #FFF6EA;
            border-radius: 5px;
            // margin:0 150px;
            // width: 1000px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #E15151;
            text-align: center;
        }
    }

    .info-text1 {
        // width: 300px;       
        display: block !important;
        // text-align:-webkit-center !important;
        display: flex;
        justify-content: center;
    margin-top: 100px;
        p {
            background: #FFF6EA;
            border-radius: 5px;
            // margin:0 150px;
            width: 300px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #E15151;
            text-align: center;
        }
    }
    
    .parent1{
        padding-top: 163px !important;
    }
}