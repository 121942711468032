.featured{
    padding: 30px 0; 
    background: #F6FCFF;
    .main-heading{
        margin-bottom: 30px;
        h4{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            color: #264653;
            a{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 155%;
                color: #92A2A9;
                margin-left: 10px;
                &:hover{
                    color: #009DE0;
                }
            }
        }
    }
    .card-featured{
        background: transparent;
        border: 2px solid transparent;
        border-radius: 10px;
        transition: 0.2s linear;
        cursor: pointer;
        &:hover{
            transition: 0.2s linear;
           .inner-img{
            background: #F6FCFF;
            border: 2px solid #009DE0;
            box-shadow: 0px 20px 20px -20px rgba(0, 157, 224, 0.4);
            border-radius: 10px;
           }
        }
        .inner-img{
                width: 100%;
                height: 185px;
                border-radius: 10px;
                border: 1px solid #D9F0FA;
                background: #FFF;
                overflow: hidden;
                background: transparent;
                margin: 0 auto;
                // margin-top: 20px;
                transition: 0.7s linear;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
            img{
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
        h6{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            text-transform: uppercase;
            color: #92A2A9;
            margin-top: 12px;
            margin-bottom: 5px;
        }
        h5{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 110%;
            color: #264653;
            transition: 0.3s linear;
            &:hover{
                color: #009DE0;
            }
        }
    }
    .owl-carousel .owl-nav{
        position: absolute;
        top: -75px;
        right: 0;
    }
    .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
        background: #EAF8FE;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        i{
            color: #009DE0;
        }
    }
}

@media (max-width:600px){
    .featured{
       
        padding: 20px 0;
    }
   
    .main-heading h4{
        font-size: 20px !important;
    }
}