@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');



.mycheckboxmain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 12px 0px;
  margin: 0px 12px;
  border-bottom: 1px solid #D9F0FA;

  &:last-child {
    border-bottom: none;
  }

  .checkboxpara {
    color: #264653;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .checkboxbluepara {
    color: #009DE0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .checkbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 1.5px solid #DADADA;
    background: #FFF;
    transition: all 0.2s ease;
  }

  .checkbox input:checked~.checkmark {
    background: #009DE0;
    border: 1.5px solid transparent;
  }



  .checkbox .checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 4px;
    height: 8px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .checkbox input:checked~.checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #FFFFFF;
  min-height: 100vh;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
  cursor: pointer;
  color: #009DE0;
}

button:focus {
  outline: none;
  box-shadow: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}




.ptb20 {
  padding: 20px 0;
}

.cursor-pointer {
  cursor: pointer;
}


.white {
  color: #fff !important;
}


.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

.common-btn {
  background: transparent;
  border: 1.5px solid #009DE0;
  border-radius: 5px;
  transition: 0.3s linear;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  color: #009DE0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:hover {
    background: #009DE0;
    border: 1.5px solid transparent;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;

    .change-my-color path {
      fill: #fff;
    }

  }
}

.bg-green {
  background: #0DD675;
}

.common-color {
  color: #009DE0;
}

.common-bg {
  color: #009DE0;
}

.btn-white {
  background: transparent;
  border: 1.5px solid #009DE0;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  text-transform: uppercase;
  color: #009DE0;
}

.btn-red {
  background: #DB4A4D;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .xs-0 {
    padding: 0px;
  }

  .padd-sm {
    padding: 0;
  }

  .owl-carousel .owl-nav {
    display: none;
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }

  .right-side {
    margin-top: 30px;

    h5 {
      font-weight: 500;
    }

    li {
      margin-right: 8px !important;
    }
  }



  .ptb {
    padding: 20px 0;
  }
}

// 12 pro max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }



  .ptb {
    padding: 20px 0;
  }
}


iframe {
  // position: unset ;
  // top: 0px !important;
  // left: 0px !important;
  // width: 0px !important;
  // height: 0px !important;
  display: none;
}


@media (max-width: 600px) {
  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}

.breadcrumb {
  margin: 0 !important;
}

.offcanvas {
  max-width: 252px;

}

.offcanvas-header {
  background: #009DE0;
  padding: 20px;
}

.offcanvas-body {
  background: #FFFFFF;
  padding: 20px;

}

.offcanvas-backdrop.show {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  opacity: 1;
  // position: fixed !important;
}

.bread-crumb {
  background: #F3F3F3;
  padding: 27px 75px;
  border-width: 1px 0px;

  .bread {
    li {
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #264653;
      }
    }
  }
}

@media (max-width:1199px) {
  .padd-lg {
    padding: 0 !important;
  }
}

@media (max-width:991px) {
  .padd-md {
    padding: 0 !important;
  }
}


@media (max-width:600px) {
  .bread-crumb {
    display: none;
  }
}


.outofstock {
  background: #E5F5FC;
  border: 1.5px solid #E5F5FC;
  border-radius: 5px;
  transition: 0.3s linear;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  color: #AFE0F4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.outimage {
  // filter: blur(1px);
  opacity: 0.5;
}


@media(max-width:991px) {
  .searchresultpara {
    color: #264653;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding: 22px 0px 5px;
  }

  .sidebar-search{
    width: 100% !important;
    max-width: 100% !important;
  }

  .dropsss {
    width: 100%;

    .dropdown-toggle::after {
      display: none !important;
    }

    .dropdown-menu {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #DCF2FB;
      background: #F5FBFE;
      max-height: 468px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 15px;
        margin-top: 20px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d8f1fc;
        border-radius: 15px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      // .mycheckboxmain {
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-start;
      //   gap: 10px;
      //   padding: 12px 0px;
      //   margin: 0px 12px;
      //   border-bottom: 1px solid #D9F0FA;

      //   &:last-child {
      //     border-bottom: none;
      //   }

      //   .checkboxpara {
      //     color: #264653;
      //     font-size: 14px;
      //     font-style: normal;
      //     font-weight: 400;
      //     line-height: 100%;
      //   }

      //   .checkboxbluepara {
      //     color: #009DE0 !important;
      //     font-size: 14px;
      //     font-style: normal;
      //     font-weight: 400;
      //     line-height: 100%;
      //   }

      //   .checkbox {
      //     position: relative;
      //     display: flex;
      //     align-items: center;
      //     gap: 4px;
      //     cursor: pointer;
      //     user-select: none;
      //     -webkit-user-select: none;
      //     -moz-user-select: none;
      //     -ms-user-select: none;
      //     font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      //   }

      //   .checkbox input {
      //     position: absolute;
      //     width: 0;
      //     left: 50px;
      //     height: 0;
      //     opacity: 0;
      //     cursor: pointer;
      //   }

      //   .checkbox .checkmark {
      //     position: relative;
      //     display: block;
      //     top: 0;
      //     left: 0;
      //     width: 18px;
      //     height: 18px;
      //     border-radius: 3px;
      //     border: 1.5px solid #DADADA;
      //     background: #FFF;
      //     transition: all 0.2s ease;
      //   }

      //   .checkbox input:checked~.checkmark {
      //     background: #009DE0 !important;
      //     border: 1.5px solid transparent;
      //   }



      //   .checkbox .checkmark::after {
      //     position: absolute;
      //     display: block;
      //     content: "";
      //     left: 50%;
      //     top: 40%;
      //     width: 4px;
      //     height: 8px;
      //     border: solid #FFF;
      //     border-width: 0 2px 2px 0;
      //     transform: translate(-50%, -50%) rotate(45deg);
      //     -webkit-transform: translate(-50%, -50%) rotate(45deg);
      //     -moz-transform: translate(-50%, -50%) rotate(45deg);
      //     -ms-transform: translate(-50%, -50%) rotate(45deg);
      //     opacity: 0;
      //     transition: all 0.2s ease;
      //   }

      //   .checkbox input:checked~.checkmark::after {
      //     opacity: 1;
      //     transition: all 0.2s ease;
      //   }
      // }
    }

    .btn {
      .numbers {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: #009DE0;
        width: 22px;
        height: 22px;
      }

      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px;
      border: 1px solid #DCF2FB;
      background: #F5FBFE;
      width: 100%;
      color: #264653;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      gap: 10px;
      position: relative;
      padding: 12px 15px;

      &:hover{
        border: 1px solid #DCF2FB;
        background: #F5FBFE;
        color: #264653;
      }

      .arrowicon {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
}


.custom-container{
  max-width: 1400px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.gcgsgcgscccscxcgcsc{
  .nav-link{
    border: none !important;
    box-shadow: none !important;
  }
}

.gcgsgcgscccscxcgcsc .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  border: none !important;
  box-shadow: none !important;
}

