.corporateorders-up {
    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        margin-top: 58px;
        font-family: poppins;
        color: #264653;
    }

    .upper-text {
        font-family: Poppins;
        font-size: 16px !important;
        line-height: 22px !important;
        line-height: 21.6px !important;
        text-align: center;
        margin-top: 29px;
        font-weight: 400;
        color: #A4A4A4;
    }


}

.corporateorders-center {
    text-align: center;
 margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .left {
        background-color: #009DE0;
        padding: 28px 30px 23px 30px;
        border-radius: 17px;
        width: 100%;
        height: 250px;

        h4 {
            margin-top: 24px;
            font-size: 28px;
            line-height: 28px;
            font-weight: 500;
            color: #FFFFFF;
        }

        p {
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 10px;
        }
    }


}

.corporateorders-down {
    margin-top: 82px;
    background-color: #F2FBFF;

    .left {
        img {
            padding: 60px 0 42px 93px;
            width: 496px;
            height: 428px;
        }
    }

    .right {
        margin-top: 75px;

        .upper-textt {
            margin-top: 30px;
        }

        h3 {
            font-size: 40px;
            line-height: 40px;
            color: #264653;
            font-weight: 500;
        }

        p {
            margin: 9px 0 22px 0;
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 400;
            color: #A4A4A4;
            line-height: 135%;
            font-family: poppins;

            span {
                font-weight: 700;
                color: #A4A4A4;
            }
        }


    }

}



.main-heading-orders {
    margin-top: 82px;

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #264653;
    }
}

.location-orders {
    margin-top: 30px;
    margin-bottom: 30px;

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        color: #264653;
        margin-bottom: 20px;
    }

    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #264653;
        margin-bottom: 10px;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #264653;
        margin-left: 5px;
    }
}

.social-head-orders {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 17px;
        color: #264653;
    }
}

.img-icons-orders {
    img {
        margin-right: 13px;

        &:last-child {
            margin-right: 10px;
        }
    }
}

.drop-head-orders {
    .drop-sub-1 {
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: #264653;
        font-size: 25px;
        margin-bottom: 15px;
        margin-top: 82px;
    }

    .drop-sub {
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: #264653;
        margin-bottom: 30px;
        font-size: 14px;
    }
}

.main-field-orders {
    input {
        border: 1px solid #D9F0FA;
        border-radius: 5px;
        padding: 10px 15px;
        width: 100%;
        background-color: #FFFFFF;
        margin-bottom: 20px;

        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #BDD8E3;
        }
    }

    textarea {
        border: 1px solid #D9F0FA;
        border-radius: 5px;
        padding: 10px 15px;
        width: 100%;
        background-color: #FFFFFF;
        margin-bottom: 20px;
        height: 142px;

        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #BDD8E3;
        }
    }

    .secondfeild {
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            border: 1px solid #D9F0FA;
            border-radius: 5px;
            padding: 10px 15px;
            width: 100%;
            background-color: #FFFFFF;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 155%;
                color: #BDD8E3;
            }
        }

        .firstfeild {
            margin-right: 20px;
        }
    }

    .messagebtn {
        button {

            background: #009DE0;
            border-radius: 5px;
            padding: 14px 61px;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .corporateorders-up {
        margin-top: 150px;
    }

    .corporateorders-up h2 {
        font-size: 28px !important;
    }

    .corporateorders-up .upper-text {
        font-size: 10px !important;
    }

    .corporateorders-center .left {
        margin-top: 30px;
    }

    .corporateorders-down {
        margin-top: 0;
    }

    .corporateorders-down .right h3 {
        font-size: 28px;
        text-align: center;

    }

    .corporateorders-down .right p {
        font-size: 11px;
        text-align: center;
    }

    .corporateorders-down .right {
        margin-top: 0;
    }

    .drop-head-orders .drop-sub-1 {
        font-size: 16px;
    }

    .drop-head-orders .drop-sub {
        font-size: 12px;
    }

    .corporateorders-down .left img {
        padding: 25px;
    }
    .corporateorders-center {
        margin-top: 0 !important;
    }
}
