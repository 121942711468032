.mainoterdiv {
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;

  h1.allcad {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 45px;
    
  }

  .allinonecardss {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    margin-bottom: 40px;

    .card-browsesnd {
      display: flex;
      padding: 5px;
      justify-content: space-between;
      align-items: center;
      gap: 15px;

      // flex: 1 0 0;
      .inner-imgssss {

        padding: 10px;

        width: 174px;
        height: 174px;
        border-radius: 10px;
        border: 1px solid #d9f0fa;
        background: #fff;
      }

      h5 {
        color: #264653;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }
  }
}

.outermaininputc {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .inputscrhbar {
    position: relative;
  }

  input.featurinputonly {
    // position: relative;
    border-radius: 35px;
    border: 1px solid #DCF2FB;
    background: #F5FBFE;
    color: #264653;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
    padding: 8px 17px;
    height: 45px;
  }

  svg.inputimgonly {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

//
@media (max-width: 600px) {
  .outermaininputc input.featurinputonly {

    width: 100%;
  }

  .outermaininputc .inputscrhbar {

    width: 100%;
    margin-bottom: 30px;
  }

  .outermaininputc {

    flex-direction: column;
    text-align: center;
  }

  .mainoterdiv .allinonecardss {
    display: flex;
    // padding: 50px 20px;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    align-self: stretch;
  }

  .mainoterdiv h3.allcad {

    text-align: center;

    font-size: 25px;

    margin-bottom: 25px;
  }

  .main-category .headingall {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .main-category .new-category {
    display: none;
  }

  .main-category .upper-content {
    display: none;
  }

  .main-category {
    padding-top: 115px;
  }

  .main-category .row>* {
    padding: 0 !important;
  }

  // .main-category .mobile-btns .button2{
  //   width: 100% !important;
  //   margin: 0 auto;
  // }
  .inner-imgssss {
    width: 142px !important;
    height: 142px !important;
  }
}

@media (max-width: 1199px) {
  .mainoterdiv .allinonecard {
    display: flex;
    align-items: start;
    justify-content: space-between;

    gap: 20px;
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .showmore {
    display: block !important;
    background: #264653;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 90px;
  }

  .main-category .wrapper-shop {
    display: none !important;
  }

  .main-category .main-para p {
    display: none !important;
  }

  .mobile-btns {
    display: block !important;
    // margin-top: -70px;
    margin-bottom: 20px;
  }

  .cart-sidebar .offcanvas-header .btn-close {
    position: absolute;
    top: 18px !important;
    right: 31px !important;
  }

  .main-category .headingall {
    margin-top: 50px;
    margin-bottom: 25px;
  }
}

.cart-sidebar {
  .offcanvas-header {
    background: transparent;
  }

  .shop-category {
    .categoryhead {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        color: #264653;
        margin-bottom: 20px;
      }
    }

    .categormblmain {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      white-space: wrap;
      width: 100%;
      max-height: 250px;
      gap: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .accordion-item {
    border: none !important;

    .accordion-header {
      .twice-btns {
        margin-bottom: 10px;

        .accordion-button {
          box-shadow: none !important;
        }

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #757575;

          background: #f3f3f3;
          border-radius: 5px;
          padding: 12px;
          white-space: nowrap;

          &:hover {
            background: #009de0;
            color: #ffff;
          }
        }
      }

      .fruits {
        background: transparent;
        border-radius: 5px;
        padding: 8px 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #ffffff;
        border: none !important;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .accordion-body {
      .buttonbody {
        .btn1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          border: none;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            border: none;
            color: #757575;
            display: flex;
            justify-content: space-between;
          }

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #b2b2b2;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .pricerange {
    .categormblmain {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      white-space: wrap;
      width: 100%;
      max-height: 250px;
      gap: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .rangehead {
      margin-bottom: 20px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        margin-top: 30px;
        color: #264653;
      }
    }

    .buttons {
      .allbtn {
        margin-bottom: 10px;

        button {
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #757575;
          border: none;
          margin-bottom: 10px;
          text-align: start;

          &:hover {
            // background: #009de0;
            color: #ffff;
            cursor: pointer;
          }

          &.active {
            background: #009de0;
            color: #ffff;
            cursor: pointer;
          }
        }

        .button-active {
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          background: #009de0;
          color: #ffff;
          border: none;
          margin-bottom: 10px;
          text-align: start;

          &:hover {
            background: #5ba7c8;
            color: rgb(194, 194, 194);
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sub-items {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}

.twice-btns {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #757575;

  background: #f3f3f3;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #009de0;
    color: #ffff;
    cursor: pointer;
  }
}

.twice-btns-active {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;

  background: #009de0;
  color: #ffff;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #5ba7c8;
    color: rgb(194, 194, 194);
    cursor: pointer;
  }
}

.price-range-filter-btns {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #757575;

  background: #f3f3f3;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #009de0;
    color: #ffff;
    cursor: pointer;
  }
}

.price-range-filter-active {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;

  background: #009de0;
  color: #ffff;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #5ba7c8;
    color: rgb(194, 194, 194);
    cursor: pointer;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .main-category .left-sidee .loader-image img {
    margin-top: 135px;
  }

  .main-category .headingall h2 {
    margin-top: 110px;
    color: white;
  }

  .twice-btns {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #757575 !important;

    background: #f3f3f3 !important;
    border-radius: 5px;
    padding: 12px;
    white-space: nowrap;
  }

  .twice-btns-active {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;

    background: #009de0;
    color: #ffff;
    border-radius: 5px;
    padding: 12px;
    white-space: nowrap;

    &:hover {
      background: #5ba7c8;
      color: rgb(194, 194, 194);
      cursor: pointer;
    }
  }

  .apply22 {
    background-color: #009de0;
    padding: 12px 18px;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    width: 97%;
    margin-right: 20px;
  }
}

.no-products {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-top: 300px;
}

.set-offcanvas-new-style {
  height: 80vh !important;
  width: 100% !important;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #ecf9ff;
  background: #fff;

  .new-category {
    border-radius: 10px;
    border: 1px solid #ecf9ff;
    background: #fff;

    .main-heading {
      border-bottom: 1px solid #ecf9ff;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        color: #264653;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
    }

    .accordion {
      .accordion-item {
        border-radius: 0;
        background-color: transparent;

        .accordion-button {
          border-radius: 0;
          background-color: transparent;
          color: #264653;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          padding: 14px 15px;
          box-shadow: none;

          &::after {
            background-image: url("../../assets/plus-icon.svg") !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }

          &[aria-expanded="true"] {
            &::after {
              background-image: url("../../assets/minus.svg") !important;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }

        .accordion-body {
          padding: 10px 15px;

          .inner-content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            flex-direction: column;

            a {
              color: #36535f !important;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              background-color: transparent !important;
              border: none !important;
              padding: 0 !important;
            }

            .a-active {
              a {
                color: #009de0 !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                svg {
                  display: block !important;
                }
              }
            }
          }

          .brand-sec {
            .option-field {
              position: relative;
              border-bottom: 1px solid #ecf9ff;
              background: #fff;
              padding: 8px 0;

              input {
                border: none;
                color: #36535f;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                width: 100%;
                padding-left: 24px;

                &::placeholder {
                  color: #a3a3a3;
                }
              }

              .search-iconn {
                position: absolute;
                top: 12px;
                left: 0px;
              }
            }

            .bottom-content {
              .twice-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 12px;

                a {
                  color: #36535f !important;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  background-color: transparent !important;
                  border: none !important;
                  text-transform: capitalize;
                }

                p {
                  color: #a3a3a3;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  /* 12px */
                }
              }

              .a-active {
                a {
                  color: #009de0 !important;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;

                  svg {
                    display: block !important;
                  }
                }
              }
            }
          }

          .shopbyprice {
            .twice-field {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;

              .option-field {
                flex: 0 0 48%;

                label {
                  color: #264653;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  margin-bottom: 5px;
                }

                input {
                  border-radius: 4px;
                  border: 1px solid #ecf9ff;
                  background: #fff;
                  padding: 10px;
                  color: #264653;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  /* 14.4px */
                  width: 100%;

                  &::placeholder {
                    color: #a3a3a3;
                  }
                }
              }
            }

            button {
              border-radius: 4px;
              border: 1px solid #009de0;
              background: #fff;
              padding: 8px 10px;
              color: #009de0;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              /* 14.4px */
              width: 100%;
            }
          }
        }
      }
    }
  }

  .offcanvas-header {
    padding: 0;
  }

  .offcanvas-body {
    padding: 0 !important;
  }

  .bottom-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 15px 25px;
    background-color: #fff;
    z-index: 99;

    button {
      flex: 0 0 48%;
      padding: 17px 35px;
      border: none;
    }

    .btn-closee {
      border-radius: 5px;
      background: #ecf9ff;
      color: #009de0;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      text-transform: uppercase;
    }

    .btn-showw {
      border-radius: 5px;
      background: #009de0;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 14px */
      text-transform: uppercase;
    }
  }
}