.bannerslider {
    .slick-slider {
        .slick-dots {
            display: none !important;
        }

        .slick-prev {
            display: block;
            position: absolute;
            bottom: -127px;
            left: 52px;
            top: 359px;
            z-index: 1;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 5px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            width: 30px;
            height: 30px;
            opacity: 1;
        }

        .slick-next {
            display: block;
            position: absolute;
            bottom: -127px;
            left: 90px;
            top: 359px;
            z-index: 1;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 5px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            width: 30px;
            height: 30px;
            opacity: 1;
        }

        .slick-prev:before {
            background: url("../../assets/arrow-right.svg") !important;
            color: transparent !important;
            background-size: 10px !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
        }

        .slick-next:before {
            background: url("../../assets/arrow-left.svg") !important;
            color: transparent !important;
            background-size: 10px !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
        }

        .slick-slide {
            .parent {
                position: relative;
                height: 403px;
            }

            .bgimg {
                z-index: -1;
                position: static;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;

                img {
                    width: 100%;
                    // object-fit: cover;
                    border-radius: 10px;
                }
            }

            .slid-text {
                padding: 60px 50px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;

                h2 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 38px;
                    line-height: 110%;
                    color: #008D00;
                    margin-bottom: 20px;
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 155%;
                    color: #264653;
                    margin-bottom: 35px;
                }

                button {
                    padding: 8px 15px;
                    background: #009DE0;
                    border-radius: 5px;
                    border: none;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 155%;
                    text-transform: uppercase;
                    color: #FFFFFF;

                }
            }

            .slide-text2 {
                h2 {
                    color: #C59171;
                }

                button {
                    background: #C59171;
                }
            }

            .slide-text3 {
                h2 {
                    color: #C62A31;
                }

                button {
                    background: #C62A31;
                }
            }

            .slide-text4 {
                h2 {
                    color: #FFB300;
                }

                button {
                    background: #FFB300;
                }
            }
        }
    }

}

@media (min-width:2560px) {
    .bannerslider .slick-slider .slick-prev {
        position: absolute;
        bottom: -127px;
        left: 92px;
        top: 627px
    }

    .bannerslider .slick-slider .slick-next {
        position: absolute;
        bottom: -127px;
        left: 140px;
        top: 627px
    }
}

@media only screen and (min-device-width: 1820px) and (max-width:1920px) {
    .bannerslider .slick-slider .slick-prev {
        position: absolute;
        bottom: -127px;
        left: 52px;
        top: 457px
    }

    .bannerslider .slick-slider .slick-next {
        position: absolute;
        bottom: -127px;
        left: 100px;
        top: 457px
    }
}
@media only screen and (min-device-width: 1500px) and (max-width:1819px) {
    .bannerslider .slick-slider .slick-prev {
        position: absolute;
        bottom: -127px;
        left: 52px;
        top: 357px
    }

    .bannerslider .slick-slider .slick-next {
        position: absolute;
        bottom: -127px;
        left: 100px;
        top: 357px
    }
}

@media (max-width:600px) {
    .bannerslider .slick-slider .slick-slide .slid-text {
        padding: 20px;
    }

    .bannerslider .slick-slider .slick-slide .slid-text h2 {
        font-size: 24px;
    }

    .bannerslider .slick-slider .slick-slide .slid-text p {
        font-size: 12px;
    }

    .bannerslider .slick-slider .slick-prev {
        left: 20px;
        top: 460px;
        display: none !important;
    }

    .bannerslider .slick-slider .slick-next {
        left: 60px;
        top: 460px;
        display: none !important;
    }

    .bannerslider .slick-slider .slick-slide .parent {
        margin-bottom: 20px;

    }

    .bannerslider .slick-slider .slick-slide .parent {
        height: 100% !important;
        // margin-top: 100px;
    }

    .banner-slider-new .slick-slider .slick-slide .bgimg img {
        margin-top: 80px;
    }
}