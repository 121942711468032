.main-cart {
    background: #FFFFFF;
   
    .maincart-card {
        background: #F3F3F3;
        padding: 27px 60px;
        border-width: 1px 0px;

        .bread {
            li {
                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 155%;
                    color: #264653;
                }
            }
        }
    }

    .parent {
        padding-top: 52px;
        padding-bottom: 89px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .heading {
            h2 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 155%;
                color: #264653;
                margin-top: 25px;
            }
        }
    }
}


@media (max-width:600px) {
    .main-cart .maincart-card {
        display: none;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .main-cart .parent {
        margin-top: 100px;
    }
}