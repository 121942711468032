.aboutus-upper {
    .left {
        margin-top: -25px;

        img {
            width: 584px;
            height: 584px;
        }
    }

    .right {
        margin-top: 107px;
        height: 505px;

        h3 {
            font-size: 40px;
            line-height: 40px;
            color: #264653;
            font-weight: 500;
        }

        p {
            margin: 31px 0 22px 0;
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 400;
            color: #A4A4A4;
            line-height: 135%;
            font-family: poppins;
        }

        .right-lower-text {
            margin: 31px 0 22px 0;
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 700;
            color: #A4A4A4;
            line-height: 135%;
            font-family: poppins;
            font-style: italic;
        }


    }
}


.aboutus-middle {
    text-align: center;
    // margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .aboutus-middle-left {
        border: 3px solid #009DE0;
        padding: 28px 30px 77px 30px;
        border-radius: 17px;
        width: 100%;
        height: 235px;

        h4 {
            margin-top: 24px;
            font-size: 28px;
            line-height: 28px;
            font-weight: 500;
            color: #009DE0;
        }

        p {
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 400;
            color: #009DE0;
            margin-top: 20px;
        }
    }


    .aboutus-middle-center {
        background-color: #009DE0;
        border: 5px solid #009DE0;;
        padding: 28px 30px 77px 30px;
        border-radius: 17px;
        width: 100%;
        height: 235px;

        h4 {
            margin-top: 24px;
            font-size: 28px;
            line-height: 28px;
            font-weight: 500;
            color: #FFFFFF;
        }

        p {
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 20px;
        }
    }

    .aboutus-middle-center:hover {
        box-shadow: 3px 3px 20px #009DE0;
        transition: box-shadow 0.4s ease-in-out;
    }

    .aboutus-middle-left:hover {
        box-shadow: 3px 3px 20px #62b7dc;
        transition: box-shadow 0.4s ease-in-out;
    }



}

.aboutus-end {
    .aboutus-end-left {
        background-color: #009DE0;
        padding: 45px 100px 35px 80px;
        // margin: 145px 0 54px 0;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 500;
            color: #FFFFFF;
        }

        p {
            font-size: 16px;
            line-height: 21.6px;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 20px;
        }

        .last-text {
            margin-top: 40px;
            .bullet-1 {
                background-color: #FFFFFF;
                margin-top: 25px;
                padding: 11px;
                border-radius: 4px;
            }

            .bullet-text {
                margin: 32px 0 0 18px;
            }
        }

    }

    .aboutus-end-left:hover {
        box-shadow: 2px 1px 15px #000000;
        transition: box-shadow 0.4s ease-in-out;

    }

    .aboutus-end-right {
        // margin-top: 93px;
        max-width: 100%;

        img {
            width: 100%;
        }
    }

}


@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .aboutus-middle {
        margin-top: 0;
    }

    .aboutus-upper .left {
        margin-top: 50px;
    }

    .aboutus-upper .left img {
        width: 377px;
        height: 377px;
    }

    .aboutus-upper .right {
        margin-top: 25px;
        text-align: center;
    }

    .aboutus-upper .right p {
        font-size: 15px;
        margin-top: 24px;
    }

    .aboutus-upper .right h3 {
        font-size: 25px;
    }

    .aboutus-middle .aboutus-middle-left {
        margin-bottom: 20px;
    }



    .aboutus-middle .aboutus-middle-center {
        margin-bottom: 20px;
    }

    .aboutus-end .aboutus-end-left {
        margin-top: 0;
    }

    .aboutus-end .aboutus-end-left h3 {
        font-size: 30px;
    }

    .aboutus-end .aboutus-end-left p {
        font-size: 16px;
    }

    .aboutus-end .aboutus-end-right {
        margin-top: 0;

    }
}


@media (max-width:600px){
    .main-term{
        padding-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .aboutus-end .aboutus-end-left{
        padding: 15px !important;
    }
    .aboutus-end .aboutus-end-left{
        margin-bottom: 0 !important;
    }
}

.cdvyvycdvyvcyvd{
    img{
        min-height: 500px;
    }
}

@media (max-width:600px){
    .cdvyvycdvyvcyvd img{
        min-height: 100%;
        height: auto;
    }
}