.main-privacy {
    background: #FFFFFF;
    padding: 28px 0px;
    padding-bottom: 90px;

    .backbtn {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
        color: #009DE0;
        cursor: pointer;
        margin-top: 50px;
        margin-bottom: 30px;
        text-decoration: underline;
    }

    .privacy-heading {
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 45px;
            line-height: 100%;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 20px;
            text-align: center;
        }

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 27px;
            line-height: 155%;
            text-transform: uppercase;
            color: #000000;
            margin: 80px 0 20px 0;
        }

        .consent {
            font-style: normal;
            font-weight: 500;
            font-size: 27px;
            line-height: 155%;
            text-transform: uppercase;
            color: #000000;
            margin: 30px 0 20px 0;


        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23.8px;
            margin: 25px 0 30px 0;
            color: #000000;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 23.8px;
                margin-bottom: 40px;
                color: #000000;
            }
        }
        .list {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23.8px;
            margin: 25px 0 30px 30px;
            color: #000000;

        }




        .font-bold {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 23.8px;
            margin: 15px 0 30px 0;
            color: #000000;
        }
    }

    // .privacy-policy-heading {
    //     h2 {
    //         font-style: normal;
    //         font-weight: 700;
    //         font-size: 36px;
    //         line-height: 100%;
    //         text-transform: uppercase;
    //         color: #000000;
    //         margin-bottom: 20px;
    //         text-align: center;
    //     }

    //     h3 {
    //         font-style: normal;
    //         font-weight: 600;
    //         font-size: 22px;
    //         line-height: 155%;
    //         text-transform: uppercase;
    //         color: #000000;
    //         margin: 60px 0 20px 0;

    //     }

    //     p {
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 14px;
    //         line-height: 23.8px;
    //         margin: 30px 0 30px 0;
    //         color: #000000;

    //         span {
    //             font-style: normal;
    //             font-weight: 800;
    //             font-size: 16px;
    //             line-height: 23.8px;
    //             margin-bottom: 40px;
    //             color: #000000;
    //         }
    //     }
    // }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {   
    .main-privacy .privacy-heading h2 {
        font-size: 32px;
    }
    .main-privacy .privacy-heading h4 {
        font-size: 22px;
        margin-top: 40px;
    }
    .main-privacy .privacy-heading .consent {
        font-size: 20px;
      
    }
}