.otherissues-main {
    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 45px;
        line-height: 100%;
        // text-transform: uppercase;
        color: #000000;
        margin-bottom: 20px;
        text-align: center;
    }

  
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23.8px;
        margin:50px 0 30px 0;
        color: #000000;
    

        span {
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 23.8px;
            margin-bottom: 40px;
            color: #000000;
        }

    }

    .font-bold {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 23.8px;
        margin-bottom: 30px;
        color: #000000;
    }

}