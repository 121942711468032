.list {
    .link {
        a {
            color: #264653 !important;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
        }
    }
}

.wish {
    text-align: center;
    margin-top: 45px;
    margin-bottom: 80px;

    h4 {
        font-weight: 500;
        font-size: 24px;
        color: #264653;
        margin-top: 20px;
    }
}


.tap {


    .table {
        margin-bottom: 60px;

        tr {
            background: #F7F7F7;

            td {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                color: #B0B0B0;
                background: #F7F7F7;
                padding: 40px 40px 20px;

            }
        }

        .head {
            background-color: #EDEDED;

            th {
                font-weight: 500;
                font-size: 18px;
                color: #747474;
                background: #EDEDED;
                padding: 19px 0 19px 102px;
            }

            .unit-price {
                font-weight: 500;
                font-size: 18px;
                color: #747474;
                background: #EDEDED;
                padding: 19px;
            }

        }

        .product-img-main {
            background-color: #fff;
            border-radius: 100%;
            margin-left: 20px;

            .product-img {
                width: 71px;
                height: 71px;
                border-radius: 100%;

            }
        }

        .product-text-main {
            .product-text {
                font-size: 12px;
                color: #264653;
                padding-top: 9px;
                font-weight: 400;
                margin-left: 10px;
                line-height: 12px;
            }

            p {
                font-size: 14px;
                color: #264653;
                padding-top: 18px;
                font-weight: 500;
                margin-left: 10px;
                line-height: 14px;
            }
        }

        .price {
            color: #009DE0;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            padding-left: 20px;
        }

        .cart-btn {
            background: transparent;
            border: 1.5px solid #009DE0;
            border-radius: 5px;
            transition: 0.3s linear;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            color: #009DE0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            width: 204px;
            padding: 12px 18px;

            &:hover {
                background: #009DE0;
                border: 1.5px solid transparent;
                border-radius: 5px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 100%;
                text-transform: uppercase;
                color: #FFFFFF;

                .change-my-color path {
                    fill: #fff;
                }

            }
        }
    }
}

@media (max-width:1024px){ 

    .tap .table .head {
        white-space: nowrap;
    }
} 
@media (max-width:768px){ 

    .tap .table .head {
        white-space: nowrap;
    }
    .tap .table {
        overflow-x: scroll !important;
    white-space: nowrap !important;
    }
    .tap .table::-webkit-scrollbar {
        display: none;
      }
}