.banner-new {
  padding-top: 135px;
  // padding-bottom: 60px;
  .parent-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .first-item {
      flex: 1 0 420px;
      .first-box {
        border-radius: 10px;
        // background: url("../../../bannernew/card4-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 285.5px;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        .item-image {
          position: absolute;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
        .inner-content {
          max-width: 300px;
          width: 100%;
          h6 {
            color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          a {
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 21.7px */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .first-box:nth-child(1) {
        background: url("../../../bannernew/card4-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .first-box:nth-child(2) {
        background:url("../../../bannernew/card4-bg.png");
      }
      .first-box:nth-child(3) {
        background: url("../../../bannernew/card4-bg.png");
      }

      .first-box:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background:url("../../../bannernew/card1-bg.png");
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .first-box:hover:after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }

      .first-box:active:after {
        opacity: 0;
      }
      .second-box {
        border-radius: 10px;
        background: url("../../../bannernew/card2-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 285.5px;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow: hidden;
        .item-image {
          position: absolute;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
        .inner-content {
          max-width: 300px;
          width: 100%;
          h6 {
               color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          a {
               color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 21.7px */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .second-box:nth-child(1) {
        background:url("../../../bannernew/card2-bg.png");
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
      }
      .second-box:nth-child(2) {
        background:url("../../../bannernew/card2-bg.png");
      }
      .second-box:nth-child(3) {
        background:url("../../../bannernew/card2-bg.png");
      }

      .second-box:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background:url("../../../bannernew/card2-bg.png");
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .second-box:hover:after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }

      .second-box:active:after {
        opacity: 0;
      }
    }
    .second-item {
      flex: 1 0 494px;
      .first-box {
        border-radius: 10px;
        background: url("../../../bannernew/card3-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 398px;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        .item-image {
          position: absolute;
          bottom: 0;
          right: 0;
          pointer-events: none;
          object-position: top;
          height: 100%;
        }
        .inner-content {
          max-width: 300px;
          width: 100%;
          h6 {
            color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          a {
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 21.7px */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .first-box:nth-child(1) {
        background: url("../../../bannernew/ramadan1.webp");
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
      }
      .first-box:nth-child(2) {
        background:url("../../../bannernew/card3-bg.png");
      }
      .first-box:nth-child(3) {
        background: url("../../../bannernew/card3-bg.png");
      }

      .first-box:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background:url("../../../bannernew/card3-bg.png");
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .first-box:hover:after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }

      .first-box:active:after {
        opacity: 0;
      }
      .second-box {
        border-radius: 10px;
        background: url("../../../bannernew/card1-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 173px;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow: hidden;
        .item-image {
          position: absolute;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
        .inner-content {
          max-width: 250px;
          width: 100%;
          h6 {
               color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          a {
               color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 21.7px */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .second-box:nth-child(1) {
        background:url("../../../bannernew/card1-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .second-box:nth-child(2) {
        background:url("../../../bannernew/card1-bg.png");
      }
      .second-box:nth-child(3) {
        background:url("../../../bannernew/card1-bg.png");
      }

      .second-box:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background:url("../../../bannernew/card1-bg.png");
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .second-box:hover:after {
        opacity: 1;
        top: 100%;
        left: 100%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }

      .second-box:active:after {
        opacity: 0;
      }
    }
    .third-item {
      flex: 1 0 316px;
      .first-box {
        border-radius: 10px;
        background: url("../../../bannernew/card5-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 227px;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        .item-image {
          position: absolute;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
        .inner-content {
          max-width: 300px;
          width: 100%;
          h6 {
               color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          a {
               color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 21.7px */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .first-box:nth-child(1) {
        background:url("../../../bannernew/card5-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .first-box:nth-child(2) {
        background:url("../../../bannernew/card5-bg.png");
      }
      .first-box:nth-child(3) {
        background:url("../../../bannernew/card5-bg.png");
      }

      .first-box:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background:url("../../../bannernew/card5-bg.png");
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .first-box:hover:after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }

      .first-box:active:after {
        opacity: 0;
      }
      .second-box {
        border-radius: 10px;
        background: url("../../../bannernew/card6-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 344px;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow: hidden;
        .item-image {
          position: absolute;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
        .inner-content {
          max-width: 250px;
          width: 100%;
          h6 {
               color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          a {
               color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 21.7px */
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .second-box:nth-child(1) {
        background:url("../../../bannernew/card6-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .second-box:nth-child(2) {
        background:url("../../../bannernew/card6-bg.png");
      }
      .second-box:nth-child(3) {
        background:url("../../../bannernew/card6-bg.png");
      }

      .second-box:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background:url("../../../bannernew/card6-bg.png");
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .second-box:hover:after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }

      .second-box:active:after {
        opacity: 0;
      }
    }
  }
}

@media (max-width: 1371px) {
  .banner-new .parent-items .first-item {
    flex: 1 0 30%;
  }
  .banner-new .parent-items .second-item {
    flex: 1 0 38%;
  }
  .banner-new .parent-items .third-item {
    flex: 1 0 27%;
  }
}

@media (max-width: 600px) {
  .banner-new {
    padding-top: 100px;
    padding-bottom: 0;
  }
  .banner-new .parent-items {
    flex-direction: column;
  }
  .banner-new .parent-items .first-item {
    flex: 0 0 100%;
    width: 100%;
  }
  .banner-new .parent-items .second-item {
    flex: 0 0 100%;
    width: 100%;
  }
  .banner-new .parent-items .third-item {
    flex: 0 0 100%;
    width: 100%;
  }
  .set-custom-border-top {
    display: none;
  }
  .banner-new .parent-items .second-item .first-box .item-image {
    object-fit: cover;
    object-position: bottom;
  }
  .banner-new .parent-items .second-item .first-box .inner-content {
    max-width: 170px;
    width: 100%;
  }

  .banner-new .parent-items .second-item .first-box {
height: 300px;
  }
}


