.password {
    .main {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 90px;

        .imgmain {
            width: 275.49px;
            height: 275.49px;
        }

        h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 100%;
            color: #264653;
            text-align: center;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #828282;
            margin-top: 15px;
            margin-bottom: 20px;
            text-align: center;
        }

        .inputmsg {
            position: relative;

            .imgmsg {
                width: 24px;
                height: 24px;
                position: absolute;
                left: 15px;
                top: 13px;
            }

            .emailinput {
                width: 524px;
                background: #FFFFFF;
                border: 1px solid #D1EDFA;
                border-radius: 5px;
                padding: 13px 46px;
                margin-bottom: 30px;

                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    color: #C8C8C8;
                }
            }
        }

        .snd-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px;
            width: 524px;
            background: #009DE0;
            border-radius: 5px;
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }
}

@media (max-width:600px) {
    .password .main .inputmsg .emailinput {
        width: 100%;
    }

    .password .main .snd-btn {
        width: 100%;
    }

    .password .main .inputmsg {
        width: 100%;
    }
}