body.dropdown-active {
    position: fixed;
    overflow-y: hidden;
}

.loyalityprogram-up {
    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        margin-top: 58px;
        font-family: poppins;
        color: #264653;
    }

    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        margin-top: 20px;
        font-family: poppins;
        color: #264653;
    }

    .upper-text {
        font-family: Poppins;
        font-size: 16px !important;
        line-height: 22px !important;
        line-height: 135% !important;
        text-align: center;
        margin-top: 16px;
        font-weight: 400;
        color: #A4A4A4;
    }


}

.loyalityprogram-center {
    img {
        width: 579px;
        height: 421px;
        margin-top: 40px;
    }

    .center-right {
        margin-top: 89px;

        h4 {
            font-size: 28px;
            font-weight: 500;
            line-height: 28px;
            color: #264653;
        }

        img {
            width: 14px;
            height: 11px;
            margin-top: 34px;
        }

        p {
            margin: 30px 0 0 6px;
            color: #A4A4A4;
            font-size: 16px;
            line-height: 16px;

        }
    }

}

.loyalityprogram-down {
    margin-top: 71px;
    background-color: #009DE0;
    border-radius: 28px;

    .left {
        padding: 80px 0 80px 62px;

        h2 {
            color: #FFFFFF;
            font-size: 37px;
            line-height: 40px;
            font-weight: 500;
            font-family: poppins;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 21.6px;
            margin-top: 5px;
            color: #FFFFFF;
        }
    }

    .down-right {
        position: relative;
        margin: 50px 0 0 0;

        .loyality-input {
            padding: 14px 10px 14px 15px;
            color: #264653;
            border-radius: 5px;
            border: 1px solid #264653;
            width: 50%;
            border: none;
            margin: 0 10px 0 0;
        }

        .loyality-input-second {
            padding: 14px 10px 14px 15px;
            color: #264653;
            border-radius: 5px;
            border: 1px solid #264653;
            width: 50%;
            border: none;

        }

        .loyality-email {
            padding: 14px 170px 14px 15px;
            color: #264653;
            border-radius: 5px;
            border: 1px solid #264653;
            width: 100%;
            border: none;
            margin-top: 10px;
        }

        input::placeholder {
            color: #A4A4A4;
            font-size: 14px;
            font-weight: 400;
            line-height: 21.7px;

        }

        .subcribe-btn {
            width: 100%;
            background-color: #264653 !important;
            border-radius: 2px;
            padding: 10px 35px;
            border: none;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            border-radius: 4px;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .main-term {
        margin-top: 80px;

    }

    .loyalityprogram-up h2 {
        font-size: 23px;
    }

    .loyalityprogram-up .upper-text {
        font-size: 11px !important;
    }

    .loyalityprogram-center img {
        height: 274px;
    }

    .loyalityprogram-center .center-right p {
        font-size: 12px;
    }

    .loyalityprogram-down .left h2 {
        font-size: 23px;
    }

    .loyalityprogram-down .left {
        text-align: center;
        margin-top: 50px;
        padding: 0;
    }

    .loyalityprogram-down .down-right input {
        width: 230px;
        padding: 8px 10px;
        margin: 30px 0 30px 0;
        font-size: 13px;
    }

    .loyalityprogram-down .down-right input::placeholder {
        font-size: 13px;
    }

    .loyalityprogram-down .down-right {
        text-align: center;
    }

    .loyalityprogram-center .center-right h4 {
        font-size: 23px;
    }

    .loyalityprogram-down .left p {
        font-size: 15px;
    }

    .loyalityprogram-center .center-right {
        margin-top: 55px;
    }

    .loyalityprogram-down .down-right .loyality-input {
        margin-right: 10px;
    }

    .loyalityprogram-down .down-right input {
        margin: 0;
    }

    .loyalityprogram-down .down-right .subcribe-btn {
        margin-bottom: 20px;
    }
}