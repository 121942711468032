.homeshop {
    background: #ffffff;
    padding-bottom: 30px;
    position: relative;

    .maincart-card {
        background: #f3f3f3;
        padding: 27px 60px;
        border-width: 1px 0px;

        .bread {
            li {
                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 155%;
                    color: #264653;
                }
            }
        }
    }

    .phone-design {
        display: none;
    }

    .shophead {
        padding-top: 50px;
        padding-bottom: 46px;

        h2 {
            font-weight: 500;
            font-size: 40px;
            line-height: 100%;
            color: #264653;
            text-align: center;
        }
    }

    .main-tables {
        width: 100%;

        table {
            width: 100%;
            background: #f7f7f7;
            white-space: nowrap;

            th {
                background-color: #ededed;
                padding: 19px 40px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: #747474;
                line-height: 18px;

                &:last-child {
                    text-align: end;
                }
            }

            td {
                padding: 19px 40px;
                border-bottom: 1.5px solid #d9f0fa;

                .parent {
                    display: flex;
                    align-items: center;

                    .main-img {
                        height: 100px;
                        width: 100px;
                        background-color: white;
                        // padding: 10px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-right: 10px;
                            width: 70%;
                            height: 70%;
                            object-fit: cover;
                            margin: 0px;
                        }
                    }

                    .texttable {
                        h5 {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 100%;
                            color: #264653;
                            margin-left: 10px;
                            padding-top: 10px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 100%;
                            color: #264653;
                            margin: 9px 0 0 10px;
                            white-space: break-spaces;
                        }
                    }
                }

                .pricetable {
                    h4 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        min-width: 80px;
                        color: #009de0;
                        line-height: 16px;
                    }
                }

                .counter {
                    .btn__container {
                        display: flex;

                        .control__btn {
                            background: #ebebeb;
                            border: 1.5px solid transparent;
                            color: #aaaaaa !important;
                            align-items: center;
                            width: 42.67px;
                            height: 42px;
                            touch-action: manipulation;
                        }

                        .reset {
                            background: #ffffff;
                            border: 1.5px solid #ffffff;
                            color: #000000;
                            width: 42.67px;
                            height: 42px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 155%;
                        }
                    }
                }

                .imgtable {
                    text-align: end;
                    cursor: pointer;

                    &:hover {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .mainbutton {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftbtn {
            button {
                background: #ffffff;
                border: 1px solid #009de0;
                border-radius: 5px;
                padding: 12px 18px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18.6px;
                text-align: center;
                text-transform: uppercase;
                margin-right: 15px;
                color: #009de0;
                width: 204px;
            }

            img {
                margin-right: 10px;
            }
        }

        .rightbtn {
            button {
                background: #ffffff;
                border: 1px solid #009de0;
                border-radius: 5px;
                padding: 12px 18px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 155%;
                text-align: center;
                text-transform: uppercase;
                width: 204px;
                color: #009de0;
            }

            img {
                margin-right: 9px;
            }
        }
    }

    .brdr {
        border-top: 1.5px solid #d9f0fa;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .codehead {
        margin-bottom: 25px;

        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 100%;
            color: #264653;
        }
    }

    .shippingaddresses {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        gap: 15px;

        .mainaddress {
            padding: 20px 25px;
            background: #ffffff;
            border: 1px solid #d9f0fa;
            border-radius: 5px;

            .addresshead {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .heading {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #363636;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 200px;
                    text-overflow: ellipsis;
                }
            }

            .inneraddress {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                .imglocate {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 6px 10px;
                    gap: 6px;
                    background: #f5fbfe;
                    border: 1px solid #dcf2fb;
                    border-radius: 5px;

                    .locatetext {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #009de0;
                    }
                }

                .uppertext {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #777676;
                }

                .number {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #a7a7a7;
                }
            }
        }

        .mainaddress-active {
            padding: 20px 25px;
            background: #ffffff;
            border: 1px solid #009de0;
            box-shadow: 0 0 5px rgba(0, 157, 224, 2);
            border-radius: 5px;

            .addresshead {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .heading {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #363636;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 200px;
                    text-overflow: ellipsis;
                }
            }

            .inneraddress {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                .imglocate {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 6px 10px;
                    gap: 6px;
                    background: #f5fbfe;
                    border: 1px solid #dcf2fb;
                    border-radius: 5px;

                    .locatetext {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #009de0;
                    }
                }

                .uppertext {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #777676;
                }

                .number {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #a7a7a7;
                }
            }
        }

        .mainaddress:focus {
            outline: none;
            border: 1px solid #009de0;
            box-shadow: 0 0 5px rgba(0, 157, 224, 2);
        }

        .addnewbtn {
            background: #009de0;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 155%;
            text-align: center;
            color: #ffffff;
            width: 100%;
            border: none;
            padding: 10px;
            margin-top: 10px;
            white-space: nowrap;
        }
    }

    .felidinput {
        display: flex;

        .input-group {
            display: flex;

            input {
                background: #ffffff;
                border: 1px solid #00ae11;
                border-radius: 5px;
                padding: 9px 15px;
                border-color: #00ae11;
                // width: 100%;
                color: #00ae11;

                &::placeholder {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 155%;
                    color: #00ae11;
                }
            }

            .apply {
                background: #00ae11;
                border-radius: 0px 5px 5px 0px;
                padding: 12px 15px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 155%;
                text-align: center;
                color: #ffffff;
            }
        }
    }

    .coupen-remove-main {
        justify-content: space-between;
        display: flex;
        margin-top: 17px;
        background-color: #f5f5f5;
        padding: 10px 8px;
        border-radius: 4px;

        .coupen-name {
            font-size: 12px;
            color: #264653;
            font-weight: 600;
            line-height: 18.6px;
            margin: 3px 10px 0 10px;
        }

        .coupen-remove {
            font-size: 9px;
            color: #009de0;
            font-weight: 600;
            line-height: 9.3px;
            margin: 10px 0 0 0;
        }

        .coupen-discount {
            img {
                cursor: pointer;
            }

            .coupen-price {
                padding: 5px 13px;
                background-color: rgba(84, 159, 239, 0.398);
                font-size: 9px;
                line-height: 9px;
                font-weight: 500;
                color: #009de0;
                border-radius: 10px;
                margin-top: 3px;
            }
        }
    }

    .coupen-remove-main:hover {
        box-shadow: 2px 1px 15px #ffffff;
        transition: box-shadow 0.4s ease-in-out;
    }

    .centerheading {
        margin-bottom: 25px;

        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 100%;
            color: #264653;
        }
    }

    .checkedmain {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 12px;
        gap: 10px;
        background: #ffffff;
        border: 1px solid #d9f0fa;
        border-radius: 5px;
        margin-bottom: 18px;

        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
            padding: 0px;
        }

        .checkedpara {
            font-size: 16px;
            line-height: 100%;
            color: #565656;
        }
    }

    .maindrop {
        .dropdown {
            button {
                background: #ffffff;
                border: 1px solid #d9f0fa;
                border-radius: 5px;
                padding: 9.5px 15px;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 155%;
                color: #81cdee;
                text-align: start;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
            }
        }
    }

    .btndown {
        button {
            background: #009de0;
            border-radius: 5px;
            padding: 10px 0px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 155%;
            color: #ffffff;
            border: none;
        }
    }

    .promoheading {
        .promohead {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 100%;
            color: #264653;
            margin-bottom: 25px;
        }
    }

    .aboutdiscount {
        padding-top: 25px;

        h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: #009de0;
        }
    }

    .subtotal {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 25px 19px;
        margin-top: 15px;

        .parenttotal {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: #264653;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                text-align: right;

                color: #264653;
            }
        }

        .parenttotal1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;

            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: #264653;
            }

            .pdis {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: #00ae11;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                text-align: right;

                color: #264653;
            }
        }

        .warning {
            margin-top: 14px;

            h5 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #e15151;
            }
        }

        .totalhead {
            margin-bottom: 15px;

            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: #264653;
            }
        }

        span {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #9f9f9f;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #264653;
        }
    }

    .brdr2 {
        border: 1px solid #d9f0fa;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .totalsub {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            color: #264653;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            text-align: right;
            color: #264653;
        }
    }

    .btntotal {
        button {
            padding: 9px 0px;
            background: #009de0;
            border-radius: 5px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18.6px;
            color: #ffffff;
            border: none;
        }

        .secondary {
            padding: 9px 0px;
            background: #ffffff;
            border: 1px solid #009de0 !important;
            border-radius: 5px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18.6px;
            color: #009de0;
            border: none;
        }

        .login-check-homeshop {
            font-size: 13px;
            font-weight: 500;
        }
    }
}

.leftssideimgg {
    width: 48.714px;
    height: 48.714px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
    mix-blend-mode: darken;
    flex-shrink: 0;
    padding: 1px;
}

.login-user {
    color: #009de0 !important;
    text-decoration: underline;
}

.left_cover {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #ccebf9;
    background: #fff;

    .left_top {
        width: 100%;

        h1.nbads {
            color: #264653;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .cards_covertop {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            flex-wrap: wrap;

            .inercardss {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
                flex: 1 0 0;
                border-radius: 10px;
                border: 1.5px solid #009de0;
                background: #fafdfe;

                &:hover {
                    border-radius: 10px;
                    border: 1.5px solid #009de0;
                    background: #fafdfe;
                }

                h5.decvbliver {
                    color: #264653;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%;
                }

                p.delivercx {
                    color: #92a2a9;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }
    }

    .sec_left {
        width: 100%;

        .dathdc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            margin-bottom: 15px;

            h5.delibgf {
                color: #264653;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%;
                text-transform: uppercase;
            }

            h5.asssddress {
                color: #009de0;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                cursor: pointer;
            }
        }

        .cards_coversec {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            flex-wrap: wrap;
            flex-direction: column;

            .inercardss_sec {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 10px;
                border: 1px solid #e2edf2;
                background: #fff;
                width: 100%;
                cursor: pointer;

                &:hover {
                    border-radius: 10px;
                    border: 1.5px solid #009de0;
                    background: #fafdfe;
                }

                .inercardflex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;

                    h5.decvbliverss {
                        color: var(--Black, #363636);
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                    }
                }

                p.delivercxs {
                    color: #777676;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                }

                p.delivercxv {
                    color: #009de0;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    span {
                        color: #a7a7a7;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }
                }
            }

            .inercardss_sec1 {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 10px;
                border: 1.5px solid #009de0;
                background: #fafdfe;
                width: 100%;
                cursor: pointer;

                &:hover {
                    border-radius: 10px;
                    border: 1.5px solid #009de0;
                    background: #fafdfe;
                }

                .inercardflex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;

                    h5.decvbliverss {
                        color: var(--Black, #363636);
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%;
                    }
                }

                p.delivercxs {
                    color: #777676;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                }

                p.delivercxv {
                    color: #009de0;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    span {
                        color: #a7a7a7;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }
                }
            }
        }
    }
}

.right_coverhead {
    border-radius: 10px;
    border: 1px solid #ccebf9;
    background: #fff;

    .ddarcc {
        display: flex;
        padding: 15px;
        align-items: center;
        gap: 5px;
        border-bottom: 1px solid #ccebf9;
        align-self: stretch;

        h5.adarr {
            color: #264653;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%;
            text-transform: uppercase;
        }
    }

    .force-overflow {
        min-height: 450px;
    }

    .largesecscrolydiv::-webkit-scrollbar-track {
        // border: 1px solid #009DE0;
        padding: 2px 0;

        padding: 5px 0px 180px 0px;
        background: #EAF9FF;
    }

    .largesecscrolydiv::-webkit-scrollbar {
        width: 8px;
    }

    .largesecscrolydiv::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        border-radius: 10px;
        background: #009DE0;
        border: 1px solid #009DE0;
    }

    .largesecscrolydiv {
        height: 288px;

        overflow-y: scroll;



        .inersoclyrow {
            .inersoclyrow {
                display: flex;
                padding: 10px 15px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                background: #fff;
                border-top: 1.5px solid #eef1f5;
                background: #fff;
            }
        }
    }

    .inersoclyrow {
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-top: 1.5px solid #eef1f5;
        background: #fff;

        .leftsside {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .rightcounter {
            display: flex;
            width: 94px;
            padding: 7px;
            justify-content: space-between;
            align-items: center;
            border-radius: 51px;
            border: 1px solid #ddf5ff;
            background: #fff;
        }
    }
}

.secleftbtmm {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    margin: 20px auto;
    border: 1px solid #ccebf9;
    background: #fff;
    border-radius: 10px;

    .felidinput {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 10px;

        input.form-control {
            display: flex;
            padding: 14px;
            justify-content: space-between;
            align-items: center;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1.5px solid #009DE0;
            background: #fff;
            width: 100%;
        }

        button#button-addon2 {
            display: flex;
            background-color: #009DE0;
            width: 107px;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #ffffff;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }

    .promoheadingssd {
        .promoheadcxsd {
            color: #2fc87e;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            padding-bottom: 10px;
            padding-top: 10px;
        }

        p.promoheadcxsd_normal {
            color: #92a2a9;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
}

.thrdrightbutm {
    /* display: flex; */
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #ccebf9;
    background: #fff;

    .inerthdbtm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        padding-bottom: 15px;

        p.lakjhalkfja {
            color: #264653;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;

            span.discount {
                color: #009de0;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                cursor: pointer;
            }
        }
    }

    .inerthdbtm1 {

        align-items: center;
        align-self: stretch;
        padding-bottom: 15px;

        p.lakjhalkfja {
            color: #264653;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            margin: 10px 0;

            span.discount {
                color: #009de0;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }
        }

        p.lakjhalkfjas {
            color: #264653;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin: 10px 0;

            span.discount {
                color: #009de0;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }
        }
    }

    .inerthdbtmccxxccv {
        .redalrt {
            color: var(--Red, #e15151);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }
    }
}

.thrdchecoutbtn {
    margin: 20px auto;

    button.thrdchecoutbtn_endd {
        display: flex;
        padding: 18px 35px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        width: 100%;
        border-radius: 8px;
        background: #009de0;
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 14px */
        border: 1px solid transparent;
    }

    button.thrdchecoutbtn_enddd {
        display: flex;
        padding: 18px 35px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        width: 100%;
        border-radius: 8px;
        background: #fff;
        color: #009de0;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 14px */
        border: 1px solid #009de0;
    }
}

.mobileview_mobileviewdisplaynone {
    display: none !important;
}

.backktopage {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #009de0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    background: transparent;
    border: 1px solid transparent;
    margin: 20px 0px;
}

.mobileviewcoverdiv {
    // border-radius: 10px;
    // border: 1px solid #ccebf9;
    // background: #fff;
    // display: flex;
    // padding: 20px;
    // flex-direction: column;
    // align-items: flex-start;
    // gap: 25px;

    // margin-bottom: 15px;
    .nbadsccxx {
        color: #264653;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        text-transform: uppercase;
    }
}

@media (max-width: 1199px) {
    .right_coverhead {

        margin: 20px 0px;
    }
}

@media (max-width: 992px) {
    .left_cover {
        margin-bottom: 20px;
    }

    .homeshop .main-tables {
        overflow-x: scroll;
    }

    .homeshop .main-tables table {
        width: 1000px;
    }

    .homeshop .subtotal {
        margin-top: 20px;
    }
}

.loader-image-myaccounts {
    text-align: center;

    img {
        width: 150px;
    }
}

@media (max-width: 600px) {
    .left_cover .sec_left .cards_coversec {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
        width: 100%;

    }

    .left_cover .sec_left .cards_coversec .inercardss_sec {
        width: 100%;
    }

    .mobileview_mobileviewdisplaynone {
        display: block !important;
    }

    .desktoponle_mobileviewdisplaynone {
        display: none !important;
    }

    .left_cover .left_top h1.nbads {
        font-size: 16px !important;
    }

    .homesshop {
        margin-top: 105px !important;
    }

    .left_cover .left_top .cards_covertop {
        display: flex;
        // padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
        align-self: stretch;
    }

    .left_cover .left_top .cards_covertop .inercardss {
        flex: unset;

        width: 100%;
    }

    .homeshop .mainbutton {
        flex-direction: column;
        // align-items: flex-start;
    }

    // .homeshop .mainbutton .rightbtn button {
    //     // margin-top: 20px;
    // }

    .homeshop .felidinput {
        margin-bottom: 20px;
    }

    // .homeshop .btndown button {
    //     // margin-bottom: 20px;
    // }

    .homeshop .mainbutton .leftbtn button {
        width: 100%;
        margin-bottom: 15px;
    }

    .homeshop .mainbutton .rightbtn {
        width: 100%;
    }

    .homeshop .mainbutton .rightbtn button {
        width: 100% !important;
        display: block;
    }

    .homeshop .subtotal {
        margin-top: 31px;
        margin-bottom: 130px;
    }

    .homeshop .shophead h2 {
        font-size: 30px;
    }

    .homeshop .codehead h2 {
        font-size: 18px;
    }

    .homeshop .centerheading h2 {
        font-size: 18px;
    }

    .homeshop .subtotal .parenttotal h3 {
        font-size: 16px;
    }

    .homeshop .subtotal .totalhead h4 {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .homeshop .totalsub h3 {
        font-size: 16px;
    }

    .homeshop .totalsub p {
        font-size: 16px;
    }

    .homeshop .main-tables table th {
        font-size: 16px;
    }

    .homeshop .main-tables table td .parent .texttable h5 {
        font-size: 12px;
    }

    .homeshop .main-tables table td .parent .texttable p {
        font-size: 10px;
    }

    .homeshop .main-tables table td .pricetable h4 {
        font-size: 14px;
    }
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #d9f0fa !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 16px !important;
    line-height: 100% !important;
    color: #565656 !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    .homeshop .centerheading h2 {
        margin-top: 10px;
    }

    .homeshop .shophead {
        margin-top: 80px;
    }

    .phone-login {
        margin-bottom: 10px !important;
    }

    .main-tables {
        display: none;
    }

    .phone-design {
        display: block !important;
        width: 100%;

        .pro-first {
            display: flex;
            justify-content: space-between;

            .pro-pic {
                display: flex;

                .pic-product {
                    width: 71px !important;
                    height: 71px !important;
                }

                .pro-name {
                    margin-top: 10px;

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: #264653;
                        width: 250px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    h4 {
                        color: #009de0;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            .delet {
                width: 24px;
                height: 24px;
                margin-top: 10px;
            }
        }

        .pro-counter {
            margin-top: 20px;
            margin-left: 72px;

            .counter {
                .btn__container {
                    display: flex;

                    .control__btn {
                        background: #ebebeb;
                        border: 1.5px solid transparent;
                        color: #aaaaaa !important;
                        align-items: center;
                        width: 42.67px;
                        height: 42px;
                        touch-action: manipulation;
                    }

                    .reset {
                        background: #ffffff;
                        border: 1.5px solid #ffffff;
                        color: #000000;
                        width: 42.67px;
                        height: 42px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 155%;
                    }
                }
            }
        }

        .pro-total {
            margin-top: 20px;
            margin-left: 72px;

            p {
                color: #009de0;
                font-size: 16px;
                font-weight: 500;
            }
        }

        hr {
            border: 1px solid #d9f0fa;
        }
    }
}

.homeshop .promoheading .promohead {
    margin-top: 15px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .homeshop .promoheading .promohead {
        margin-top: 10px;
    }
}

.homeshop-validations {
    font-size: 13px !important;
    margin-bottom: 8px;
    font-weight: 500;
    color: red !important;
    // margin-top: -20px !important;
}

.homeshopp-validations {
    font-size: 13px !important;
    margin-bottom: 8px;
    font-weight: 500;
    color: red !important;
    margin-top: -20px !important;
}

.error-input-homeshop {
    border: 2px solid red !important;
}

.error-input-homeshop::placeholder {
    color: red !important;
}

.add-adress {
    color: black !important;
    font-size: 20px;
}

.here {
    color: #009de0 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.offcaveshopnew {
    max-width: 649px;
    width: 100% !important;

    .offcanvas-header {
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
        align-items: center;
        background: #ecf9ff;
    }

    .offcanvas-title.h5 {
        color: #264653;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
    }

    .offcanvas-body {
        background: #ffffff;
        padding: 20px;
        width: 100%;

        .innerinputs {
            width: 100%;
            margin-bottom: 12px;
            display: flex;

            gap: 15px;
            align-items: center;
            position: relative;

            input.maininput {
                width: 100%;
                display: flex;
                padding: 14px 14px 14px 42px;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1.5px solid #ccebf9;
                background: #fff;
                color: #a6ddf4;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                // position: relative;
            }

            .scrhbarpostion {
                position: absolute;
                /* bottom: 37px; */
                left: 15px;
            }
        }

        .maponley {
            width: 100%;
        }

        .savebtnveyyt {
            position: absolute;
            right: 12px;
            border-radius: 6px;
            background: #009DE0;
            display: flex;
            padding: 8px 22px;
            align-items: flex-start;
            gap: 10px;
            color: #FFF;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            border: 1px solid transparent;
            line-height: 120%;
        }

        .seclastbtnns-cover {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            margin-bottom: 25px;

            span.seclastbtnns {
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 5px;
                flex: 1 0 0;
                border-radius: 8px;
                border: 1px solid #ECF3F6;
                background: #FFF;
            }
        }

        .savebtn {
            display: flex;
            padding: 18px 35px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            width: 100%;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            border: 1px solid #ECF3F6;
            background: #009DE0;
        }
    }
}