.justlanding {
  padding: 30px 0;
  padding-top: 30px;
  //    padding-bottom: 10px;
  // background: #F6FCFF;

  .banner {
    margin-bottom: 65px;

    img {
      border-radius: 5px;
      width: 100%;
    }
  }

  .parent {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    white-space: nowrap;

    .main-heading {
      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        color: #264653;
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #92a2a9;
          margin-left: 10px;
        }
      }
    }
    .social-tags {
      overflow-x: scroll;
      margin-left: 20px;
      margin-right: 80px;
      padding-bottom: 2px;
      a {
        margin: 0px 13px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #92a2a9;
      }
    }
    .social-tags::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }

    /* Set the background color of the scroll bar track */
    .social-tags::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Set the background color of the scroll bar thumb */
    .social-tags::-webkit-scrollbar-thumb {
      background: #d8f1fc;
    }

    /* Set the color of the scroll bar thumb on hover */
    .social-tags::-webkit-scrollbar-thumb:hover {
      background: #d8f1fc;
    }
  }
  .card-topsaver {
    background: #ffffff;
    border: 1px solid #d9f0fa;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    transition: 0.3s linear;
    height: 305px;
    overflow: hidden;
    &:hover {
      background: #ffffff;
      border: 1px solid #d9f0fa;
      box-shadow: 0px 13px 30px rgba(0, 157, 224, 0.1);
      border-radius: 10px;
    }
    .inner-img {
      width: 100%;
      height: 190px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      img {
        width: 100% !important;
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
      .outofstock {
        border-radius: 5px;
        background: var(--red, #e15151);
        padding: 6px 9px;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        max-width: 115px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
      }
      .btn-add {
        position: absolute;
        right: 5px;
        bottom: 8px;
      }
      .add-counter {
        border-radius: 51px;
        border: 1px solid #009de0;
        background: #fff;
        box-shadow: 0px 1px 7px 0px rgba(0, 157, 224, 0.3);
        padding: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 96px;
        width: 100%;
        span {
          color: #264653;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 155%; /* 24.8px */
        }
      }
    }
    .bottom-text {
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-transform: uppercase;
        color: #92a2a9;
        margin-top: 12px;
      }
      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 105%;
        color: #264653;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #009de0;
        margin-top: 10px;
      }
    }
    button {
      padding: 12px 18px;
      width: 100%;
    }
    .top-abs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .off {
        background: #009de0;
        border-radius: 5px;
        padding: 4px 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 155%;
        color: #ffffff;
      }
    }
  }
  .owl-carousel .owl-item img {
    width: unset;
    display: unset;
  }
  .owl-carousel .owl-nav {
    position: absolute;
    top: -75px;
    right: 0;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    background: #eaf8fe;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    i {
      color: #009de0;
    }
  }
  .bannernneeww {
    display: flex;
    padding: 56px 62px 55px 82px;
    justify-content: space-between;
    align-items: center;

    background: linear-gradient(91deg, #009DE0 0%, #0075A7 98.95%);
    width: 100%;
    flex-wrap: wrap;
    .downlodeed {
      color: #fff;
      /* font-family: Inter; */
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .listddd {
      display: flex;
      align-items: center;
      gap: 32px;
    }
  }
  .leftdownn {
    display: flex;
    /* width: 528px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  p.aasdasdsad {
    color: #fff;
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 600px) {
  .justlanding .parent {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .justlanding .social-tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    width: 100%;
    // margin-top: 15px;
    padding-bottom: 10px;
    transition: 0.3s linear;
    &::-webkit-scrollbar {
      display: none;
    }
    &:hover {
      &::-webkit-scrollbar {
        display: block !important;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #d8f1fc;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #d8f1fc;
      }
    }
    a {
      white-space: nowrap;
      margin-left: 0 !important;
      margin-right: 25px !important;
    }
  }
}

@media (max-width: 768px) {
  .parent {
    white-space: nowrap;
    .social-tags {
      overflow-x: scroll;
    }
    .social-tags::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .parent {
    white-space: nowrap;
    .social-tags {
      overflow-x: scroll;
      margin: 5px 0 0 0 !important;
    }
    .social-tags::-webkit-scrollbar {
      display: none;
    }
  }
}

.ifoutofstock {
  opacity: 0.4;
}

.multi-card-set {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195.83px, 1fr));
  gap: 15px;
}
@media (max-width: 600px) {
  .justlanding {
    padding-top: 15px;
  }
  .justlanding .bannernneeww .downlodeed {
    font-size: 33px;
  }
  .justlanding .bannernneeww .listddd {
    flex-wrap: wrap;
  }
  .justlanding .bannernneeww {
    padding: 20px;

    gap: 55px;
  }
}

@media (max-width: 1100px) {
  .justlanding .bannernneeww {
    padding: 20px;

    gap: 55px;
  }
}
