
.loginmap {
    padding-bottom: 90px;

    .breadcrumb {
        background-color: #f3f3f3;

        .link {
            a {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 155%;
                color: #264653 !important;
            }
        }
    }

    .login-main {
        display: flex;
        justify-content: center;
        .mainform {
            padding: 20px;
            background: #F5F5F5;
            border-radius: 5px;
            width: 490px;
            margin-top: 50px;

            .nav-tabs {
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: none;
                margin-bottom: 43px;

                .nav-item {
                    button {
                        border-bottom: 3px solid white;
                        text-transform: uppercase;
                    }
                }

                .nav-link {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 155%;
                    color: #B5B5B5;
                    background: #F5F5F5 !important;
                    border: none;
                    border-bottom: 3px solid #009DE0;
                    padding: 0px;
                    padding-left: 29px;
                    padding-right: 29px;
                    padding-bottom: 8px;

                    &.active {
                        background: #F5F5F5 !important;
                        border: none;
                        color: #264653 !important;
                        border-bottom: 3px solid #009DE0;

                    }
                }
            }

            .form {
                .innerinput {
                    position: relative;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 120%;
                        color: #333333;
                        margin-bottom: 10px;
                    }

                    input {
                        border: 1px solid #85B3C8;
                        border-radius: 5px;
                        background: transparent;
                        width: 100%;
                        background-color: transparent;
                        height: 42px;
                        margin-bottom: 15px;
                        font-size: 14px;
                        font-weight: 500;
                        padding-left: 15px;

                        &::placeholder {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 120%;
                            color: #BDD8E3;
                        }
                    }

                    .eye-icon {
                        position: absolute;
                        top: 35px;
                        right: 13px;
                    }
                    .password-condition {
                        p {
                            font-size: 11px;
                            

                        }
                    }
                 
                }

                .forf {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 7px;
                    margin-bottom: 27px;

                    .custom-control-label::before {
                        width: 18px;
                        height: 18px;
                        background: transparent;
                        border: 1px solid #D1EDFA;
                        border-radius: 5px;
                    }

                    .custom-control-label::after {
                        top: 4px;
                        left: 1px;
                    }

                    .custom-control-input:focus~.custom-control-label::before {
                        box-shadow: none !important;
                    }

                    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                        background-color: #009DE0;
                        border: 1px solid transparent;
                        border-radius: 5px;
                        width: 18px;
                        height: 18px;
                    }

                    .custom-control-label {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 100%;
                        color: #264653;
                    }

                    .link {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 100%;
                        color: #009DE0;
                        text-decoration: none;
                    }
                }
                .input-Errors {
                    color: red;
                    font-size: 13px;
                    font-weight: 500;
                    margin-top: -10px;
                }
                .login {
                    button {
                        background: #009DE0;
                        border-radius: 5px;
                        width: 100%;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21.7px;
                        color: #FFFFFF;
                        border: none;
                        padding: 8px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }

                    .line {
                        border-top: 1px solid #00aeff;
                        margin-top: 25px;
                        margin-bottom: 15px;
                    }
                }

                .lastbtns {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .linklog {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 120%;
                        color: #9B9B9B;
                        text-decoration: none;
                        margin-bottom: 10px;
                    }

                    button {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 12px 42px;
                        border: 1px solid #DCDCDC;
                        border-radius: 5px;
                        background-color: transparent;
                        width: 100%;
                        margin-top: 15px;
                        white-space: nowrap;

                        .btnimg {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.error-input {
  border: 2px solid red !important;
}

.text-danger {
  color: red !important;
  margin-top: -10px;
  font-size: 12px  !important;
}

@media (max-width:992px) {
    .loginmap .login-main .mainform {
        width: 100%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    .loginmap .login-main .mainform {
        margin-top: 140px;
    }
}

