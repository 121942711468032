.social{
    padding: 60px 0;
    .card-social{
        // background: #009DE0;
        border-radius: 10px;
        .banner{
            width: 100%;
        }
       
        .left-content{
            // padding: 60px;
            h1{
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 110%;
                color: #FFFFFF;
                margin-bottom: 30px;
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 155%;
                color: #B2E2F6;
                margin-bottom: 40px;
            }
        }
        .right-img{
            margin-top: -53px;
        }
    }
}


@media (max-width:1199px){
    .social{
        padding: 40px 0;
    }
    .social .card-social .left-content{
        padding: 20px;
    }
    .social .card-social .left-content h1{
        font-size: 35px !important;
        text-align: center;
    }
    .social .card-social .left-content p{
        font-size: 14px;
        text-align: center;
    }
    .social .card-social .bottom-btn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .social .card-social .right-img{
        margin-top: 20px !important;
        text-align: center;
    }
}