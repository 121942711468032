.main-banner {

    padding: 45px 0;
    background: white;
    // background: url("../../../assets/banner-bg.png");
    background-position: cover;
    background-size: cover;
    background-repeat: no-repeat;



    .blue {
        background: #009DE0;
        border-radius: 10px;
        position: relative;
        width: 100%;
        height: auto;
        // object-fit: cover;
        object-position: center;

        img {
            background: #009DE0;
            width: 100%;
            height: auto;
            border-radius: 10px;
            // object-fit: cover;
            object-position: center;
        }

        .textbtn {
            position: absolute;
            top: 45px;
            left: 40px;
            padding: 0px 45px 44px 0px;

            .text {
                h3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 155%;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }

                h6 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 23px;
                    line-height: 155%;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }

            .btn-shop {
                margin-top: 220px;

                button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 8px 15px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 155%;
                    text-transform: uppercase;
                    color: #009DE0;
                    border: 0px;
                }
            }
        }
    }

}

@media (max-width:1199px) {
    .bannerslider .slick-slider .slick-slide .parent {
        height: 500px !important;
    }
}



@media (max-width:600px) {

    // .main-banner {
    //     padding: 40px 0;
    // }
    .loader{
        margin-top: 80px;
    }
    .main-banner .slick-slider .slick-slide .slid-text {
        padding: 20px;
    }

    .main-banner .slick-slider .slick-slide .slid-text h2 {
        font-size: 24px;
    }

    .main-banner .slick-slider .slick-slide .slid-text p {
        font-size: 12px;
    }

    .main-banner .slick-slider .slick-prev {
        left: 20px;
    }

    .main-banner .slick-slider .slick-next {
        left: 60px;
    }

    .main-banner .slick-slider .slick-slide .parent {
        margin-bottom: 20px;
    }

    .main-banner .blue .textbtn {
        left: 20px;
        top: 20px;
    }

    .main-banner .blue .textbtn .btn-shop {
        margin-top: 200px;
    }
}

// @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) { 
//     // .sub-banner {
//     //     margin-top: 80px;
//     // }
//     // .banner-slider-new .slick-slider .slick-slide .bgimg img {
//     //     margin-top: 0 !important;
//     // }
// }


.main-banner .bannerslider .slick-slider .slick-slide .parent {
    height: unset !important;

}

.main-banner .bannerslider .slick-slider .slick-slide .bgimg img {
    height: auto !important;
}

.wrapper-slider .bannerslider .slick-slider .slick-slide .parent {
    height: unset !important;
}

.wrapper-slider .bannerslider .slick-slider .slick-slide .bgimg img {
    height: auto !important;
}

.wrapper-slider .blue img {
    background: #009DE0;
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-position: center;
}
