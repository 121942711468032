.loader-image-checkout {
  text-align: center;
  img {
    width: 250px;
    margin: 100px;

  }
}

.main-checkout {
  background: #ffffff;
  padding: 28px 0px;
  padding-bottom: 173px;

  .maincart-card {
    background: #f3f3f3;
    padding: 27px 60px;
    border-width: 1px 0px;

    .bread {
      li {
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #264653;
        }
      }
    }
  }

  .parent {
    text-align: center;

    .parenthead {
      margin-top: 10px;
      margin-bottom: 40px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 100%;
        color: #264653;
      }
    }

    .parentbox {
      border: 1px solid #d9f0fa;
      border-radius: 5px;
      background: #ffffff;
      padding: 11px 18px;
      width: 390px;
      // height: 24px;
      margin: 0 auto;
      margin-bottom: 50px;

      .code {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          color: #264653;
          white-space: nowrap;
          margin-left: 12.77px;
        }

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          color: #009de0;
        }
      }
    }
  }

  .main-headingzz {
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 100%;
      color: #264653;
      margin-bottom: 28px;
    }
  }

  .main-feild {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      margin-bottom: 10px;
      color: #264653;
    }

    span {
      color: #db4a4d;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      width: 7px;
      height: 22px;
    }

    input {
      border: 1px solid #d9f0fa;
      border-radius: 5px;
      padding: 10px 15px;
      width: 100%;
      background: #ffffff;
      margin-bottom: 20px;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #bdd8e3;
      }
    }
  }

  .difaddress {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }

    .form-check {
      display: flex;
      align-items: center;
      padding-left: 0px;

      .form-check-input:checked[type="checkbox"] {
        // background-image: url(\innerassets\inputtick.svg);
        background-size: 14px 10px;
      }

      .form-check-input {
        height: 22px;
        width: 22px;
        border: 1.5px solid #009de0;
        border-radius: 5px;
        margin: 0px;
        background-size: unset;

        &:focus {
          box-shadow: none;
        }

        &:active {
          filter: unset;
        }
      }

      label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #264653;
        margin-left: 10px;
      }
    }
  }

  .areatext {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      margin-bottom: 10px;
      color: #264653;
    }

    span {
      color: #db4a4d;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      width: 7px;
      height: 22px;
    }

    .areass {
      border: 1px solid #d9f0fa;
      border-radius: 5px;
      width: 100%;
      padding: 10px 15px;
      height: 140px;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #bdd8e3;
      }
    }
  }

  .product-maincard {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 25px;

    .product-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #264653;
      }
    }
  }

  .middle-card {
    background: #ffffff;
    border-radius: 5px;
    padding: 19px 20px;

    .parent-middle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-content {
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          margin-bottom: 9px;
          color: #264653;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          color: #264653;
        }
      }

      .right-content {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #009de0;
      }
    }
  }

  .brdr3 {
    border-top: 1px solid #d9f0fa;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .totalhead {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      color: #264653;
      margin-top: 25px;
    }
    .subtotal{
      font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    text-align: right;
    }
  }

  .shiping {
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      color: #009de0;
    }

    .rate {
      ul {
        li {
          list-style: disc;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
          margin-left: 35px;
          margin-top: 15px;
          color: #cbcbcb;

          &:last-child {
            margin-top: 0px;
          }
        }
      }
    }
    .warning-error {
      margin:30px 0 0 0;
      p{
        color: red !important;
        font-size: 20px;
        text-align: start;
      }
    }
  
  }

  .totalrate {
    // display: flex;
    justify-content: space-between;
    align-items: center;
   
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      color: #264653;
      // color: #009de0;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      text-align: right;
      color: #264653;
    }
  }

  .totalrate1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      color: #264653;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      text-align: right;
      color: #264653;
    }
    .spa11{
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      text-align: right;
      color: red;
    }
  }

  .endcard {
    background: #ffffff;
    border-radius: 5px;
    padding: 10px 20px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #5f5f5f;
    }
  }

  .endaddress {
    padding-top: 30px;
    margin-bottom: 0px;
  }

  .endaddresss {
    margin-bottom: 0px;
  }

  .endbtn {
    button {
      background: #009de0;
      border-radius: 5px;
      padding: 12px 18px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 155%;

      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
      border: none;
    }
  }
}

@media (max-width: 600px) {
  .main-checkout .maincart-card {
    display: none;
  }

  .main-checkout .parent .parentbox {
    width: 100%;
    overflow: hidden;
  }

  .main-checkout .parent .parentbox .code h2 {
    white-space: normal !important;
  }

  .main-checkout .middle-card .parent-middle {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px 0;
  }

  .main-checkout .middle-card .parent-middle .left-content h3 {
    font-size: 12px;
  }

  .main-checkout .middle-card .parent-middle .right-content h3 {
    font-size: 14px;
  }

  .main-checkout .shiping .rate ul li {
    font-size: 14px;
  }

  .main-checkout .totalrate p {
    font-size: 18px;
  }

  .main-checkout .main-headingzz h2 {
    margin-top: 40px;
  }
}

.main-checkout .parent .parenthead {
  margin-top: 100px;
}
.payment-method {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  padding: 30px;

  .payment-head {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #264653;
    margin-bottom: 28px;
  }
  .option-field{
    margin-bottom: 15px;
  }
  .form-group {
    display: block;
    background: #FFFFFF;
    border: 1px solid #D9F0FA;
    border-radius: 5px;
    padding: 15px 21px;
    position: relative;
  }


  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 100%;
    color: #565656;

  }
  
  .form-group label:before {
    content:'';
    // -webkit-appearance : none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    background-color: #FFFFFF;
    border: 1.5px solid #BFECFF;
    border-radius: 45px;
  }

  .form-group input:checked + label:before {
    content:'';
    // -webkit-appearance: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    background-color: #009DE0;
    border-radius: 45px;
    border: 1.5px solid transparent;
  }
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 8px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .onluipdodebtn_maindiv{
    display: flex;
    height: 108px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid #E2EDF2;
    background: #F9F9F9;
    gap: 10px;
    .onluipdodebtn {
      color: #264653;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
  }

  }

}
.error-modal {
  // width: 50% !important;
  // display: flex !important;

  .modal-dialog {
    max-width: 437px;
    text-align: center;
  }

  .modal-content {
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 0 66px 22px !important;
    }
  }

  .cart-modal {
    width: 437px;
    height: 395px;
    text-align: center;
    overflow-y: hidden;
    margin: auto;
  }

  .cart-adde {
    width:  56.26px !important;
    height: 52.37px !important;
  }

  .cart-data {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #363636;
    margin-top: 15px;
  }

  .cart-data1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #009DE0;
    margin-top: 125px;
  }

  .modal-footer {
    display: inline;
    border: none;
  }

  .shopp {
    background: #009DE0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 14px 21px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
  }

  .shopp1 {
    background: #FFFFFF;
    border: 1px solid #009DE0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 14px 52px;
    color: #009DE0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .btn-close {
    padding: 10px;
    background-image: url(.//..//../cross.svg);
    background-repeat: no-repeat;
    border: none;
    background-color: white;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}