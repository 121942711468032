.fourth{
    // padding: 30px 0;
    // padding-bottom: 30px;
    margin-top: -15px;
    // background: #F6FCFF;

    .banners{
        
        margin-bottom: 50px !important;
        margin-top: 50px !important;

        img{
            border-radius: 5px;
            width: 100%;
        }
    }

    .parent{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        white-space: nowrap;

        .main-heading{
            h4{
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 110%;
                color: #264653;
                a{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 155%;
                    color: #92A2A9;
                    margin-left: 10px;
                }
            }
        }
    .social-tags{
        overflow-x: scroll;
        margin-left: 20px;
        margin-right: 80px;
        padding-bottom: 2px;
        a{
            margin: 0px 13px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 155%;
            color: #92A2A9;
        }
    }
    .social-tags::-webkit-scrollbar {
        width: 1px;
        height: 1px;
    }

    /* Set the background color of the scroll bar track */
    .social-tags::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Set the background color of the scroll bar thumb */
    .social-tags::-webkit-scrollbar-thumb {
        background: #d8f1fc;
    }

    /* Set the color of the scroll bar thumb on hover */
    .social-tags::-webkit-scrollbar-thumb:hover {
        background: #d8f1fc;
    }
    }
    .card-topsaver{
        background: #FFFFFF;
        border: 1px solid #D9F0FA;
        border-radius: 10px;
        width: 100%;
        padding: 15px;
        transition: 0.3s linear;
        height: 305px;
        overflow: hidden;
        &:hover{
            background: #FFFFFF;
            border: 1px solid #D9F0FA;
            box-shadow: 0px 13px 30px rgba(0, 157, 224, 0.1);
            border-radius: 10px;

        }
        .inner-img{
            width: 100%;
            height: 190px;
            overflow: hidden;
            margin: 0 auto;
            position: relative;
            img{
                width: 100% !important;
                height: 100%;
                object-fit: contain;
                object-position: top;
            }
            .outofstock{
                border-radius: 5px;
                background: var(--red, #E15151);
                padding: 6px 9px;
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; 
                max-width: 115px;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;
            }
            .btn-add{
                position: absolute;
                right: 5px;
                bottom: 8px;
            }
            .add-counter{
                border-radius: 51px;
                border: 1px solid #009DE0;
                background: #FFF;
                box-shadow: 0px 1px 7px 0px rgba(0, 157, 224, 0.30);
                padding: 7px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 96px;
                width: 100%;
                span{
                    color: #264653;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 155%; /* 24.8px */
                }
            }
            
        }
        .bottom-text{
            span{
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 120%;
                text-transform: uppercase;
                color: #92A2A9;
                margin-top: 12px;
            }
            h5{
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 105%;
                color: #264653;
            }
            h6{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 110%;
                color: #009DE0;
                margin-top: 10px;
            }
        }
        button{
            padding: 12px 18px;
            width: 100%;
           
        }
        .top-abs{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .off{
                background: #009DE0;
                border-radius: 5px;
                padding: 4px 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 155%;
                color: #FFFFFF;
    
            }
        }
    }
    .owl-carousel .owl-item img{
        width: unset ;
        display: unset;
    }
    .owl-carousel .owl-nav{
        position: absolute;
        top: -75px;
        right: 0;
    }
    .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
        background: #EAF8FE;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        i{
            color: #009DE0;
        }
    }
 
}


@media (max-width:600px){
   
    .fourth .parent{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .fourth .social-tags{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        width: 100%;
        // margin-top: 15px;
        padding-bottom: 10px;
        transition: 0.3s linear;
        &::-webkit-scrollbar{
            display: none;
        }
        &:hover{
            &::-webkit-scrollbar {
                display: block !important;
                height: 3px;
              }
              
              &::-webkit-scrollbar-thumb {
                background: #D8F1FC; 
                border-radius: 10px;
              }
              
              
              &::-webkit-scrollbar-thumb:hover {
                background: #D8F1FC; 
              }
        }
        a{
            white-space: nowrap;
            margin-left: 0 !important;
            margin-right: 25px !important;
        }

    }
}

@media (max-width:768px){ 
    .parent {
        white-space: nowrap;
        .social-tags {
            overflow-x: scroll;

        }
        .social-tags::-webkit-scrollbar {
            display: none;
          }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) { 
    .parent {
        white-space: nowrap;
        .social-tags {
            overflow-x: scroll;
            margin: 5px 0 0 0 !important;
        }
        .social-tags::-webkit-scrollbar {
            display: none;
          }
    }
   
}



.ifoutofstock{
    opacity: 0.4;
}