.topsaver {
    padding: 60px 0;
    background: #F6FCFF;

    .parent {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .main-heading {
            h4 {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 110%;
                color: #264653;

                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px !important;
                    line-height: 155%;
                    color: #92A2A9;
                    margin-left: 10px;
                    &:hover{
                        color: #009DE0;
                    }
                }
            }
        }

        .timer {
            background: #009DE0;
            border-radius: 5px;
            padding: 10px;
            margin-left: 35px;

            p {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 155%;
                color: #FFFFFF;
                display: flex;
                align-items: center;

                span {
                    font-weight: 400;
                }
            }
        }
    }

    .card-topsavers {
        background: #FFFFFF;
        border: 1px solid #D9F0FA;
        border-radius: 10px;
        width: 100%;
        padding: 8px;
        padding-bottom: 12px;
        transition: 0.3s linear;
        height: 290px;
        overflow: hidden;

        &:hover {
            background: #FFFFFF;
            border: 1px solid #D9F0FA;
            box-shadow: 0px 13px 30px rgba(0, 157, 224, 0.1);
            border-radius: 10px;

        }
        .ifoutofstock{
            opacity: 0.4;
        }
        .inner-img {
            width: 100%;
            height: 180px;
            overflow: hidden;
            margin: 0 auto;
            position: relative;
            .outofstock{
                border-radius: 5px;
                background: var(--red, #E15151);
                padding: 6px 9px;
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; 
                max-width: 115px;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;
            }
            .btn-add{
                position: absolute;
                right: 5px;
                bottom: 8px;
            }
            .add-counter{
                border-radius: 51px;
                border: 1px solid #009DE0;
                background: #FFF;
                box-shadow: 0px 1px 7px 0px rgba(0, 157, 224, 0.30);
                padding: 7px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 96px;
                width: 100%;
                span{
                    color: #264653;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 155%; /* 24.8px */
                }
            }
            

            img {
                width: 100% !important;
                height: 100%;
                object-fit: contain;
                object-position: top;
            }
        }

        .bottom-text {
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 120%;
                text-transform: uppercase;
                color: #92A2A9;
                margin-top: 12px;
            }

            h5 {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 105%;
                color: #264653;
               
            }

            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 110%;
                color: #92A2A9;
                margin-top: 10px;
            }
            .dis-price {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 110%;
                color: #009DE0;
                margin-top: 10px;
                margin-left: 10px;
            }
        }

        button {
            padding: 12px 18px;
            width: 100%;

        }

        .save-price-text{
            color: var(--red, #E15151) !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: 110% !important; /* 13.2px */
            text-decoration-line: strikethrough !important;
            text-decoration-line: line-through;
            
        }

        .top-abs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 15px;
            z-index: 9;

            .off {
                background: #009DE0;
                border-radius: 5px;
                padding: 4px 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 155%;
                color: #FFFFFF;

            }
        }
    }

    .discount-card {
        background: url("../../../assets/image.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        padding: 25px;
        padding-top: 55px;
        padding-bottom: 63px !important;

        .top-content {
            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 150%;
                text-transform: uppercase;
                color: #264653;
                text-align: center;
                margin: 12px 0 57px 0;

            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 130%;
                text-align: center;
                color: rgba(38, 70, 83, 0.5);
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }

        .option-fields {
            input {
                background: #EFFAFF;
                border: 1px solid #C5E9F9;
                border-radius: 5px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 155%;
                color: #000;
                padding: 10px 18px;
                width: 100%;
                margin-bottom: 10px;

                &::placeholder {
                    color: #A7BCCF;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 155%;
                }
            }
        }

        .btn-bottom {
            button {
                padding: 12px;
                width: 100%;
                margin-top: 12px;
            }
        }
    }

    .owl-carousel .owl-nav {
        position: absolute;
        top: -75px;
        right: 0;
    }

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
        background: #EAF8FE;
        border-radius: 5px;
        width: 30px;
        height: 30px;

        i {
            color: #009DE0;
        }
    }

    .owl-carousel .owl-item img {
        width: unset;
        display: unset;
    }
}

// @media (max-width:1500px){
//     // .topsaver .discount-card{
//     //     padding-bottom: 54px;
//     // }
// }
@media (max-width:1199px) {
    .topsaver .discount-card {
        margin-top: 40px;
    }
}

@media (max-width:600px) {
    .topsaver {
        padding: 40px 0;
    }

    .topsaver .discount-card {
        margin-top: 40px;
    }

    

    .topsaver .none-mobile {
        display: none;
    }

    .topsaver .parent .timer p {
        font-size: 12px;
        white-space: nowrap;
    }
}