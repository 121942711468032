.header__search__drop__down {
  display: flex;
  align-items: center;
  gap: 8px;
  // max-width: 200px;
  // justify-content: flex-start;
  // white-space: nowrap;
  margin-left: 6px !important;

  // text-overflow: ellipsis;
  .numbers {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #009de0;
    width: 22px;
    height: 22px;
  }
}
.navmodalouterdiv{
  .removemodalhead{
    background: #ECF9FF;
    /* padding: 15px 20px; */
    border: none;
    .modal-title.h4 {
      color: #264653;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
  }
}
.removemodbody{
  color: #363636;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-align: center;

}
  .modal-dialog {
  max-width: 419px !important;
  width: 100% !important;
  margin: 0px auto !important;}
  .fade.modal.show {
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(2px);
  }
  .modal-content {
    /* display: flex; */
    // padding-bottom: 20px;
    /* flex-direction: column; */
    /* align-items: center; */
    gap: 40px;
    border-radius: 12px;
    background: #FFF;
    backdrop-filter: blur(32px);
    max-width: 419px;
    width: 100%;
}
.remofotr {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  justify-content: center;
  border: none;
  .closebbtths {
    display: flex;
    padding: 18px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #ECF9FF;
    color: #009DE0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: none;
    outline: none;
}
.closebbtthsredd {
  display: flex;
  padding: 18px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #F44E4E;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border: none;
  outline: none;
}
}
}

.sec-1 {
  background: #009de0;

  .bg-m {
    background-color: #ffffff;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0px;

    .navbar-main {
      position: fixed;
      z-index: 1000;
      // backdrop-filter: blur(10px);
      // background-color: white;
      background: #009de0;

      .di-1 {
        .pa-1 {
          margin-left: 20px;
          position: absolute;
          z-index: 10;
          color: #264653;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid #d6e3ef;
          padding: 0px;
          margin-top: 11px;
          border-bottom: none;
          border-top: none;
          border-left: none;
          text-transform: uppercase;
          padding-left: 10px;
          padding-right: 10px;
          // padding-top: 7px;
        }

        .m-lx {
          width: 35%;
          font-size: 14px;
          font-weight: 400;

          .h-dr {
            border-top: 1.5px solid#D9F0FA;
            width: 80%;
            margin: 0 auto;
            // margin-top: 3px;
            margin-bottom: 3px;
          }

          .li-h:hover {
            color: #009de0;
          }
        }

        .in-1 {
          position: relative;
          // border: 1px solid #DCF2FB;
          background-color: transparent;
          padding-top: 12px;
          padding-bottom: 13px;
          border-radius: 5px;
          border: none;
          width: 100%;
          padding-left: 15px;
        }

        .in-1::placeholder {
          font-size: 12px;
          margin-top: 5px;
          color: #6ec7ee;
          padding-left: 5px;
        }

        .search-icon {
          position: absolute;
          right: 6px;
          top: 7.5px;
          padding: 0;
        }

        .rea-1 {
          position: relative;
          top: -1px;

          // .sea-1 {
          //     position: absolute;
          //     right: 10px;
          //     bottom: 9px;
          // }
        }

        .pa-1 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          text-transform: uppercase;
          color: #264653;
          margin-top: 15px;
        }

        .pa-2 {
          font-weight: 400;
          font-size: 12px;
          color: #264653;
        }

        .pa-3 {
          color: #009de0;
          font-size: 16px;
          font-weight: 500;
        }

        .ab-mai {
          position: absolute;
          right: 0px;
          left: unset;
          right: 0;
          width: 50%;
          max-height: 444px;
          overflow-y: auto;
          background: #ffffff;
          border: 1px solid #d9f0fa;
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.03);
          border-radius: 5px;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background: #ffffff;
            border: none;
          }

          &::-webkit-scrollbar-thumb {
            background: #009de0;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #057aac;
          }
        }

        .hr-4 {
          color: #d9f0fa;
          width: 90%;
          opacity: 1;
          margin: 1rem auto;
        }
      }

      .search__box__handle {
        top: 67px;
        right: 0px;
        width: 100%;
        z-index: 1000;
        // padding: 10px;
        padding: 10px;
        max-height: 444px;
        overflow-y: auto;
        position: absolute;
        background-color: white;
        border: 1px solid #d9f0fa;
        border-radius: 5px;

        .search-prod-img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }

      /* width */
      .search__box__handle::-webkit-scrollbar {
        width: 8px;
        border-radius: 15px;
        margin-top: 20px;
      }

      /* Track */
      .search__box__handle::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      .search__box__handle::-webkit-scrollbar-thumb {
        background: #009de0;
        border-radius: 15px;
      }

      /* Handle on hover */
      .search__box__handle::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .divider__line {
        border: 1px solid #D9F0FA;;
     
        // border-radius: 5px;
      }

      .da-2 {
        background-color: #f5fbfe;
        border: 1px solid #dcf2fb;
        padding: 12px;
        border-radius: 5px;
        margin-right: auto;
        padding-right: 12px;
        padding-left: 12px;
        // padding-bottom: 3px;
        position: relative;

        .upper {
          position: absolute;
          top: 0px;
          left: 10px;

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #a4a4a4;
            padding: 7px 0 0px 2px;
          }
        }

        .street {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          /* or 19px */
          margin-top: 5px;
          text-align: right;
          color: #264653;
        }

        .loc {
          .pad-img {
            padding-bottom: 6px;
            padding-left: 11px;
            margin-top: 5px;
          }

          .locbtn {
            padding: 0px;
            margin: 0px;
            border: none;
            background-color: transparent !important;
          }

          .locdrop {
            top: 147%;
            left: -185px;
            width: 233px;

            .dropdown-item:hover {
              background-color: unset;
              color: #009de0;
            }

            .color {
              color: #009de0;
            }
          }
        }

        .pa-2 {
          font-size: 12px;
          color: #a4a4a4;
        }

        .pa-3 {
          font-size: 16px;
          color: #264653;
          font-weight: 600;
          max-width: 326px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .fl-1 {
        display: flex;
        justify-content: flex-end;
        max-width: 161px;
        width: 100%;

        .h-m {
          background-color: #f5fbfe;
          padding: 8px;
          border-radius: 40px;
          border: 1px solid #dcf2fb;
          margin-right: 10px;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .li-2 {
            list-style: none;

            .dro-1 {
              padding-bottom: 0px;
              position: relative;
            }

            .ab-mai {
              position: absolute;
              left: -302px;
              top: 54px;
              padding: 15px;
              width: 435px;
              max-height: 500px;
              overflow-y: scroll;

              /* width */
              &::-webkit-scrollbar {
                width: 8px;
                border-radius: 15px;
                margin-top: 20px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #009de0;
                border-radius: 15px;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            }

            .flex___item {
              justify-content: space-between;
            }

            .d-main1 {
              // display: flex !important;

              .d-sub {
                margin-right: 35px;
              }

              .pa-1 {
                font-size: 14px;
                color: #264653;
                font-weight: 500;
                margin-top: 10px;
              }

              .pa-1x {
                font-weight: 500;
                font-size: 14px;
              }

              .pa-21 {
                font-weight: 400;
                font-size: 9px;
                color: #264653;
              }

              .pa-3 {
                color: #009de0;
                font-size: 16px;
                font-weight: 500;
              }

              .amount-text {
                color: #555353d8;
                font-size: 14px;
                font-weight: 400;
              }

              .cart-img {
                width: 70px;
                height: 70px;
                margin-top: 12px;
              }
            }

            .d-main2 {
              display: flex !important;
              justify-content: space-evenly;

              .pa-1 {
                font-size: 11px;
                color: #264653;
                font-weight: 500;
                margin-top: 10px;
              }

              .pa-2 {
                font-weight: 400;
                font-size: 9px;
                color: #264653;
              }

              .pa-3 {
                color: #009de0;
                font-size: 16px;
                font-weight: 500;
              }
            }

            .d-main3 {
              display: flex !important;
              justify-content: space-evenly;

              .d-sub {
                margin-right: 10px;
              }

              .pa-1 {
                font-size: 11px;
                color: #264653;
                font-weight: 500;
                margin-top: 10px;
              }

              .pa-2 {
                font-weight: 400;
                font-size: 9px;
                color: #264653;
              }

              .pa-3 {
                color: #009de0;
                font-size: 16px;
                font-weight: 500;
              }
            }

            .d-main4 {
              display: flex !important;
              justify-content: space-evenly;

              .d-sub {
                margin-right: 106px;
              }

              .pa-1 {
                font-size: 11px;
                color: #264653;
                font-weight: 500;
                margin-top: 10px;
              }

              .pa-2 {
                font-weight: 400;
                font-size: 9px;
                color: #264653;
              }

              .pa-3 {
                color: #009de0;
                font-size: 16px;
                font-weight: 500;
              }
            }

            .tra-1 {
              float: right;
              margin-top: -4px;
              cursor: pointer;

              &:hover {
                margin-bottom: 5px;
              }
            }

            .tra-2 {
              float: right;
              margin-top: 0px;
            }

            .tra-3 {
              float: right;
              margin-top: 22px;
            }

            .tra-4 {
              float: right;
              margin-top: 19px;
            }

            .hr-3 {
              color: #d9f0fa;
              width: 90%;
              opacity: 1;
              margin: 1rem auto;
            }

            .dro-l {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              .pm {
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
              }

              .po {
                margin-right: 13px;
                font-size: 14px;
                font-weight: 500;
                color: #009de0;
              }
            }

            .dro-2 {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;

              .pm {
                padding: 10px;
                padding-left: 35px;
                padding-right: 35px;
                background-color: #d2d2d2;
                border: none;
                border-radius: 5px;
                margin-left: 10px;
                color: white;
                font-size: 12px;
                font-weight: 600;
              }

              .po {
                padding: 10px;
                padding-left: 35px;
                padding-right: 35px;
                background-color: #009de0;
                border: none;
                border-radius: 5px;
                margin-right: 10px;
                color: white;
                font-size: 12px;
                font-weight: 600;
                width: 100% !important;
              }
            }
          }

          .a1 {
            position: relative;
          }

          .sp-1 {
            position: absolute;
            top: 20px;
            border: 1px solid #fff;
            background: #f7c24a;
            border-radius: 20px;
            font-size: 10px;
            height: 14px;
            width: 14px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            color: #264653;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
    }
  }

  .dealtoday-cat {
    margin-top: 55px;
  }

  .hr-1 {
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 0%;
  }

  .li-3 {
    list-style-type: none;

    .droe-main {
      width: 100%;
      margin-top: 10px;
      padding-bottom: 0px;
      background: #ffffff;
      border: 1px solid #d9f0fa;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.03);
      border-radius: 5px;

      .li-1 {
        font-size: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding: 8px 15px;
        border-bottom: 1px solid #d9f0fa;
        transition: 0.3s linear;
        display: flex;
        align-items: center;

        &:last-child {
          border: none;
        }

        &:hover {
          background: transparent !important;
          color: #009de0 !important;
        }
      }

      .p-re {
        position: relative;

        .dr-2 {
          position: absolute;
          left: 150px;
          margin-top: 26px;
        }
      }

      .p-abs {
        position: absolute;
        top: -31px;
        left: 200px;

        .ingre-1 {
          height: 190px;
          width: 235px;
        }
      }

      .st-ma {
        margin-top: 20px;
        background-color: #bfedde !important;
        padding: 17px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      .li-2:hover {
        color: #009de0;
        background: transparent;
        position: relative;

        .hov-m {
          display: block;
          position: absolute;
          left: 223px;
          width: 85%;
          border-radius: 5px;
          top: -1px;

          // .bor-1x {
          //     border: 1px solid black;
          // }

          .t-b {
            font-size: 15px;
            font-weight: 700;
          }

          .li-1x {
            font-size: 14px;
            padding-top: 10px;
            background: transparent;
          }
        }
      }

      // .li-1x:hover {
      //   color: #009de0;
      // }

      .li-3:hover {
        color: #009de0;
        position: relative;

        .hov-m {
          width: 100%;

          position: absolute;
          right: -20px;

          .t-b {
            font-size: 15px;
            font-weight: 700;
          }

          .li-1x {
            font-size: 14px;
            padding-top: 10px;
          }
        }
      }

      .h-dr {
        background-color: #d9f0fa;
        width: 80%;
        margin-top: 7px;
        margin-bottom: 7px;
        float: left;
        margin-left: 20px;
      }

      .h-er {
        background-color: #d9f0fa;
        width: 40%;
        margin-top: 7px;
        margin-bottom: 7px;
        float: left;
        margin-left: 20px;
      }

      .h-mr {
        background-color: #d9f0fa;
        width: 80%;
        margin-top: 7px;
        margin-bottom: 7px;
        float: left;
        margin-left: 20px;
      }
    }

    .im-2 {
      position: absolute;
      z-index: 10;
      left: 13px;
      bottom: 12px;
    }
  }

  .btn-1 {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ccebf9;
    border-radius: 5px;
    padding: 9px 15px 9px 15px;
    color: #009de0;
    display: flex;
    align-items: center;
    margin-top: 0px !important;
  }

  .me-2 {
    margin-left: 10px;
  }

  // second

  .ul-1x {
    color: black;

    .li-1 {
      font-size: 14px;
      font-weight: 500;
      transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease;
      margin-left: 0 !important;

      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 155%;
      display: flex;
      align-items: center;

      &:hover {
        background: transparent !important;

        // margin-top: -5px;
        transform: scale(1.1);
      }
    }
  }
}

// cart box =======================

.emptycartImg {
  padding: 36px;
  text-align: center;

  img {
    width: 85px;
    height: 80px;
  }

  h5 {
    color: #a7bccf;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
  }
}

// cart box =======================

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .price__tag1 {
    color: #92A2A9 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 100% !important;
  }
  .price__distag {
    color: #009de0 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 100% !important;
    margin-left: 10px !important;
    margin-top: 2px !important;
  }
  .sidebar-catogary .offcanvas-header a {
    margin-top: 2px;
    margin-left: 20px;
  }

  .navbar {
    padding: 10px;
  }
  .main-field {
    position: relative !important;
  }
  .addd {
    position: absolute !important;
    right: 0;
    top: 41px !important;
    margin-left: 0 !important;
    width: 30px !important;
    height: 30px !important;
    cursor: pointer;
  }

  .sec-1 {
    .im-2 {
      position: absolute;
      top: 17px;
      z-index: 10 !important;
    }

    .bg-m {
      padding-left: 0px;
      // display: contents;

      .icox-1 {
        color: #009de0;
        margin-top: 6px;
      }

      .h-m {
        position: relative;
        width: 80%;
        margin-top: 10px;
        display: flex;

        .sp-1 {
          position: absolute;
          top: 0px !important;
          right: -5px;
        }
      }

      .da-2 {
        margin-top: 10px;

        .pa-2 {
          font-size: 9px !important;
        }

        .pa-3 {
          font-size: 12px !important;
        }

        .li-2 {
          .dro-1 {
            padding-bottom: 5px;
          }
        }
      }

      .di-1 {
        .in-1 {
          padding-inline-end: 0px;
          margin-left: auto;
          margin-top: 10px;
          padding-left: 112px;
          padding-right: 0px;
        }

        .pa-1 {
          font-size: 9px;
          margin-left: auto;
          margin-top: 20px;
        }

        .sea-1 {
          position: absolute;
          right: -12px;
          width: 8%;
        }
      }
    }
  }

  .accordion-item {
    border: none;
  }

  .accordion-button {
    font-size: 16px;
    color: #264653;
    padding-left: 0;
  }

  .accordion-button:focus {
    background-color: white;
  }

  .all-cat {
    color: #264653;
    font-size: 14px;
  }

  .profile {
    justify-content: start;
  }
}

.card-drop-menu {
  background: #ffffff;
  border: 1px solid #d9f0fa;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  left: unset !important;
  right: 40px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar {
    padding: 10px;
  }

  .sec-1 {
    .im-2 {
      position: absolute;
      top: 17px;
      z-index: 10 !important;
    }

    .bg-m {
      padding-left: 0px;
      // display: contents;

      .icox-1 {
        color: #009de0;
        margin-top: 6px;
      }

      .h-m {
        position: relative;

        margin-top: 10px;
        display: flex;

        .sp-1 {
          position: absolute;
          top: 0px !important;
          right: -5px;
        }
      }

      .da-2 {
        margin-top: 10px;

        .pa-2 {
          font-size: 9px !important;
        }

        .pa-3 {
          font-size: 12px !important;
        }

        .li-2 {
          .dro-1 {
            padding-bottom: 5px;
          }
        }
      }

      .di-1 {
        .in-1 {
          padding-inline-end: 0px;
          margin-left: auto;
          margin-top: 10px;
          padding-right: 0px;
          width: 100%;
        }

        .pa-1 {
          font-size: 9px;
          margin-left: auto;
          margin-top: 20px;
        }

        .sea-1 {
          position: absolute;

          width: 3%;
        }
      }
    }
  }
}

.arrow-drop {
  position: absolute;
  right: 15px;
}

.arrow-drop1 {
  position: absolute;
  right: 14px;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .navbar {
    padding: 10px;
  }

  .sec-1 {
    .im-2 {
      position: absolute;
      top: 17px;
      z-index: 10 !important;
    }

    .bg-m {
      padding-left: 0px;
      // display: contents;

      .icox-1 {
        color: #009de0;
        margin-top: 6px;
      }

      .h-m {
        position: relative;

        margin-top: 10px;
        display: flex;

        .sp-1 {
          position: absolute;
          top: 0px !important;
          right: -5px;
        }
      }

      .da-2 {
        margin-top: 10px;

        .pa-2 {
          font-size: 9px !important;
        }

        .pa-3 {
          font-size: 12px !important;
        }

        .li-2 {
          .dro-1 {
            padding-bottom: 5px;
          }
        }
      }

      .di-1 {
        .in-1 {
          padding-inline-end: 0px;
          margin-left: auto;
          margin-top: 10px;
          padding-left: 112px;
          padding-right: 0px;
          width: 100%;
          margin-left: 20px;
        }

        .pa-1 {
          font-size: 9px;
          margin-left: 20px;
          margin-top: 20px;
        }

        .sea-1 {
          position: absolute;

          width: 6%;
        }
      }
    }
  }
}

.fresh-drop {
  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #d9f0fa;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 10px 15px;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      color: #264653;
      transition: 0.7s linear;
      padding: 10px;
      border-bottom: 1px solid #d9f0fa;

      &:hover {
        background: transparent;
        color: #009de0;
      }
    }
  }
}

.sp-1 {
  position: absolute;
  top: 20px;
  border: 1px solid #fff;
  background: #f7c24a;
  border-radius: 20px;
  font-size: 10px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: #264653;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

@media (max-width: 1199px) {
  .navbar {
    padding: 25px 0;
    // padding-top: 50px;
  }

  .none-mobile {
    display: none;
  }

  // .navbar-mobile {
  //   flex-direction: row-reverse;
  //   justify-content: center;
  // }
  .navbar-logo {
    padding-left: 60px;
  }

  .navbar-collapse {
    display: none;
  }

  .sec-1 .btn-1 {
    display: none;
  }

  .search-btn-mobile {
    display: block !important;
    position: absolute;
    // top: 48px;
    top: 23px;
    right: 10px;
  }

  .cart-btn-mobile {
    position: absolute;
    top: 23px;
    // top: 48px;
    right: 65px;
    width: 40px;
    height: 40px;
    background: #f5fbfe;
    border: 1px solid #dcf2fb;
    border-radius: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .sp-1 {
    top: 0;
  }

  .sec-1 .bg-m .h-m .sp-1 {
    right: -15px;
    top: -8px !important;
  }

  .dealtoday-cat {
    display: none !important;
  }

  .mobile-toggle {
    position: absolute;
    left: 0;
  }

  .gvstvtscvtvsv {
    padding: 20px !important;
  }

  .suvctssctscsc {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.sidebar-catogary {
  .offcanvas-header {
    flex-direction: row-reverse;

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
    }

    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .offcanvas-header .btn-close {
    background: url("../../../assets/arrow-close.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
  }

  .links-deal .none-mobile {
    display: block !important;
  }

  .links-deal li {
    padding: 20px 0;
    border-bottom: 1px solid #d9f0fa;
  }

  .links-deal .fresh-drop a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .links-deal .fresh-drop .dropdown-menu {
    width: 100%;
  }
}

.sidebar-cart {
  max-width: 100%;
  width: 100% !important;
  position: fixed !important;

  .offcanvas-header {
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  .offcanvas-header .btn-close {
    background: url("../../../assets/close-icon-new.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
  }

  .bottom-items {
    .main-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1.5px solid #d9f0fa;

      .img-delete {
        .trash-img-size {
          width: 26px;
          height: 26px;
        }
      }

      .img-item {
        width: 65px;
        height: 65px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 100%;
          color: #264653;
          margin-bottom: 10px;
        }

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 100%;
          color: #264653;
          margin-bottom: 8px;
          display: none !important;
        }

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: #009de0;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .dvcdtvdtvtvcdvvcvd {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .bottom-btns {
    .upper-text {
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #264653;
      }

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #009de0;
      }
    }

    .btnsss {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        background: #28a8e7;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 155%;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        transition: 0.7s linear;

        &:hover {
          background: #009de0;
        }
      }
    }
  }

  .upper-head {
    padding: 15px;
  }

  .offcanvas-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.sidebar-search {
  max-width: 306px;

  .offcanvas-header {
    justify-content: space-between;
    flex-direction: row-reverse;

    .option-field {
      width: 100%;
      position: relative;

      input {
        background: #ffffff;
        border-radius: 5px;
        padding: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        color: #000;
        border: none;
        width: 100%;
        padding-right: 30px;
        height: 48px;

        &::placeholder {
          color: #99d8f3;
        }
      }

      .search-icon {
        position: absolute;
        top: 8px;
        right: 10px;
      }
    }
  }

  .main-field {
    display: flex;
    align-items: center;
    padding: 25px 0;
    border-bottom: 1.5px solid #d9f0fa;

    .img-item {
      width: 65px;
      height: 65px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      margin-left: 15px;

      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 100%;
        color: #264653;
        margin-bottom: 10px;
      }

      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: #264653;
        margin-bottom: 8px;
      }

      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #009de0;

        span {
          font-size: 14px;
        }
      }
    }
  }

  .filter {
    .dropdown-menu {
      background: #ffffff;
      border: 1px solid #d9f0fa;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      width: 206px;
      padding: 10px 15px;
      max-height: 340px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #d8f1fc;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #d8f1fc;
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 155%;
        color: #264653;
        display: block;
        padding: 5px 0;
        border-bottom: 1px solid #d9f0fa;
        transition: 0.3s linear;

        &:hover {
          color: #009de0;
        }
      }
    }
  }
}

.dcvsgv {
  padding-top: 20px;
}

.see-all-products {
  position: relative;
  padding-left: 20px !important;
  display: inline !important;
  width: unset !important;
  margin-top: 0 !important;
  transition: 0.3s linear;
  color: #009de0;
  border: none !important;
  display: none;
}

.sec-1 .li-3 .droe-main .li-2:hover .hov-m .li-1x {
  display: flex !important;
  width: 100% !important;
}

.p-abs {
  a {
    border: none !important;
  }
}

.bd-line {
  border-bottom: 1px solid #d9f0fa !important;
}

.p-abs .bd-line {
  border-bottom: 1px solid #d9f0fa !important;
}

.line-inner {
  position: absolute;
  top: 0;
  left: 183px;
  right: 0;
  bottom: 0;
}

.usvcvs {
  width: 90% !important;
}

.usvcvss {
  width: 92% !important;
}

.search-drop-nav {
  // max-height: 362px;
  padding-bottom: 0;
  width: 206px;
  max-height: 426px;
  min-height: fit-content;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8f1fc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d8f1fc;
  }
}

.dropdown__item__list {
  padding: 7px 15px;
}

.search-ff {
  position: relative;
  width: 100%;

  border-radius: 35px;
  border: 1px solid #dcf2fb;
  background: #f5fbfe;
  height: 48px;
}

.value-show {
  position: relative;
  width: 100%;
  // background: #f5fbfe;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.addd {
  margin-right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.price__tag {
  color: #009de0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}

.price__tag1 {
  color: #92A2A9;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.price__distag {
  color: #009de0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  margin-left: 10px;
  margin-top: 2px;
}

.category___name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 100%;
  color: #264653;
}

.text-specss {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #a4a4a4;
}

.sp-2 {
  top: -7px !important;
}

.modal-dialog {
  max-width: 800px;

  .modal-contentt {
    background: #ffffff;
    backdrop-filter: blur(32px);
    border-radius: 5px;

    .modal-header {
      padding: 20px 30px 30px;
      border-bottom: none;

      .btn-close {
        width: 18px;
        height: 18px;
        border-radius: 0%;
        opacity: unset;
        border: none;

        //   background: url(\assets\cross.svg);
        &:focus {
          box-shadow: none;
        }
      }

      .modal-title {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        color: #264653;

        ::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          color: #99d8f3;
        }
      }
    }

    .modal-body {
      padding: 0px 30px 20px;

      input {
        background: #ffffff;
        border: 1.5px solid #009de0;
        border-radius: 5px;
        width: 100%;
        padding: 16px;
        color: #99d8f3;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
      }

      img {
        width: 100%;
        // margin-top: 15px;
      }

      .map-div {
        width: 738px;
        height: 500px;
        margin-top: 15px;
      }
    }
  }
}

.marker {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-cat {
  color: #000 !important;
}

.set-upper-item {
  position: sticky;
  bottom: -25px;
  left: 0px;
  width: 100%;
  background: #fff;
  z-index: 999;
  padding: 15px 0;
}

.upper-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  padding: 15px 0;

  h6 {
    color: #292929;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    span {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: #009de0;
      color: #fff;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }

  a {
    color: #009de0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 12px */
    text-transform: uppercase;
  }
}

.scutvscfscrcsr {
  gap: 20px;
  width: 100%;
  flex: 1 1 auto;
  margin: 0 92px !important;
}

.category-drop {
  max-width: 191px;
  width: 100%;

  .dropdown-toggle {
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.09);
    border: none;
    padding: 12px 10px;
    padding-bottom: 15px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
    width: 100%;
    height: 48px;

    &[aria-expanded="true"] {
      background: #0f5877;

      &:hover {
        animation: unset !important;
      }
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 20px;
    min-width: 825px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 4px 3px 30px 0px rgba(0, 157, 224, 0.2);
    margin-top: 5px;

    .category-items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px 10px;
      flex-wrap: wrap;

      .single-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        flex: 0 0 121px;
        cursor: pointer;

        .upper-img {
          border-radius: 185px;
          background: #f7f8fa;
          // padding: 27px 18px;
          padding: 10px;
          width: 111px;
          height: 111px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .inner-text {
          h6 {
            color: #264653;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
      .single-items {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        flex: 0 0 121px;
        cursor: pointer;

        .upper-img {
          border-radius: 185px;
          background:  #E6F4C6;
          // padding: 27px 18px;
          padding: 10px;
          width: 111px;
          height: 111px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .inner-text {
          h6 {
            color: #264653;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
    }
  }
}

.sidebar-catogary-new {
  max-width: 305px !important;

  .offcanvas-header {
    background-color: #fff;
    justify-content: center;
  }

  .account-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #d9f0fa;

    .btn-acc {
      color: #264653;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .btn-signinn {
      color: #009de0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
    }
  }

  .bottom-content {
    padding-top: 13px;

    .main-heading {
      margin-bottom: 23px;

      h6 {
        color: #292929;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }

    .itemsss {
      li {
        .dropdown-item {
          padding: 12px 8px;
          padding-left: 0;
          border-bottom: 1px solid #d9f0fa;
          color: #264653;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 155%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
        }
      }
    }
  }
}
.btn-add {
  // position: absolute;
  right: 5px;
  bottom: 8px;
}
.add-counter {
  border-radius: 51px;
  border: 1px solid #009de0;
  background: #fff;
  box-shadow: 0px 1px 7px 0px rgba(0, 157, 224, 0.3);
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 96px;
  width: 100%;
  height: 40px;
  span {
    color: #264653;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%; /* 24.8px */
  }
}

.set-custom-border-top {
  border-top: 1px solid #fff;
  position: fixed;
  top: 69px;
  left: 0;
  width: 100%;
  opacity: 1;
  margin: 0;
  z-index: 1001;
}

.sign-btn {
  border-radius: 50px;
  border: 1px solid #fff;
  background: #009de0;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  white-space: nowrap;
}

.second-nav {
  padding: 11px;
  padding-bottom: 2px;
  position: fixed;
  z-index: 999;
  background: #009de0;

  .bottom-items-nav {
    .bottom-ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 56px;

      li {
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 155%;
        }
      }

      .dropdown {
        .dropdown-toggle {
          background-color: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 155%;
          padding: 0;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          li {
            a {
              color: #009de0;
            }
          }
        }
      }
    }
  }
}

.yscvydvcdycyd {
  display: flex;
  flex: 1 1 auto;
}

.search-iconvggvgvgv {
  position: absolute;
  top: 8px;
  right: 10px;
}

.cvvdvcdvvvdcv {
  input {
    border-radius: 35px !important;
    border: 1px solid #dcf2fb !important;
    background: #f5fbfe !important;
  }
}

.animationcategory {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes slideDownIn {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(-20px);
  }
}

@-webkit-keyframes slideDownIn {
  0% {
    -webkit-transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(-20);
  }
}

.slideDownIn {
  -webkit-animation-name: slideDownIn;
  animation-name: slideDownIn;
}

@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform: translateY(20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }

  0% {
    -webkit-transform: translateY(20px);
    opacity: 0;
  }
}

@keyframes slideUpIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }

  0% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.slideUpIn {
  -webkit-animation-name: slideUpIn;
  animation-name: slideUpIn;
}

.pulse-animation {
  transition: 0.3s;
}

.pulse-animation:hover {
  animation: pulse 1s infinite;
  transition: 0.3s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
