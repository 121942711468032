.careers-main {
    .career-sub {
        background-color: #009DE0;

        .career {
            font-size: 40px;
            color: #ffffff;
            font-weight: 500;
            line-height: 40px;
            margin-top: 74px;
        }

        .career-text {
            font-size: 17px;
            line-height: 21.6px;
            font-weight: 400;
            color: #ffffff;
            margin-top: 12px;
        }
        .career-text2 {
            font-size: 17px;
            line-height: 21.6px;
            font-weight: 400;
            color: #ffffff;
            margin-top: 12px;
            text-decoration: underline;
        }

        .career-img {
            width: 355px;
            height: 323px;
            float: right;
        }
    }

    .career-jobs {
        margin-top: 96px;
        border-radius: 13px;


        .career-job-card {
            display: flex;
            justify-content: space-between;
            border: 3px solid #009DE0;
            padding: 23px 35px;
            border-radius: 13px;
            position: relative;

            .top-card-text {
                color: #ffffff;
                background-color: #E03535;
                padding: 3px 13px;
                font-size: 15px;
                line-height: 23.25px;
                font-family: poppins;
                position: absolute;
                top: -16px;
                border-radius: 19px;
            }

            .career-job-text {
                font-size: 24px;
                color: #264653;
                line-height: 37.2px;
                font-weight: 500;
                margin-top: 5px;
            }

            .btn-detail {
                font-size: 14px;
                color: #009DE0;
                background-color: #ffffff;
                line-height: 14px;
                font-weight: 500;
                border: 2px solid #009DE0;
                padding: 14px 44.5px;
                border-radius: 7px;
                margin-right: 26px;
            }

            .btn-apply {
                font-size: 14px;
                color: #ffffff;
                background-color: #009DE0;
                line-height: 14px;
                font-weight: 500;
                border: 2px solid #ffffff;
                padding: 14px 48.5px;
                border-radius: 7px;
            }
        }

    }

    .career-jobs:hover {
        box-shadow: 4px 10px 25px rgba(90, 69, 69, 0.3);
        transition: box-shadow 0.4s ease-in-out;
    }

    .career-jobs-second {
        margin-top: 20px;
        border-radius: 13px;

        .career-job-card {
            display: flex;
            justify-content: space-between;
            border: 3px solid #009DE0;
            padding: 25px 35px;
            border-radius: 13px;



            .career-job-text {
                font-size: 24px;
                color: #264653;
                line-height: 37.2px;
                font-weight: 500;
                margin-top: 5px;
            }

            .btn-detail {
                font-size: 14px;
                color: #009DE0;
                background-color: #ffffff;
                line-height: 14px;
                font-weight: 500;
                border: 2px solid #009DE0;
                padding: 14px 44.5px;
                border-radius: 7px;
                margin-right: 26px;
            }

            .btn-apply {
                font-size: 14px;
                color: #ffffff;
                background-color: #009DE0;
                line-height: 14px;
                font-weight: 500;
                border: 2px solid #ffffff;
                padding: 14px 48.5px;
                border-radius: 7px;
            }
        }
    }

    .career-jobs-second:hover {
        box-shadow: 4px 10px 25px rgba(90, 69, 69, 0.3);
        transition: box-shadow 0.4s ease-in-out;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {    
    .careers-main .career-jobs .career-job-card {
        padding: 20px 7px;
    }
    .careers-main .career-jobs .career-job-card .btn-detail {
        padding: 14px 24.5px;
        margin-right: 2px;
    }
    .careers-main .career-jobs .career-job-card .career-job-text {
        font-size: 17px;
    }
    .careers-main .career-jobs .career-job-card .btn-apply {
        padding: 14px 28.5px;
    }
    .careers-main .career-jobs-second .career-job-card {
        padding: 20px 7px;
    }
    .careers-main .career-jobs-second .career-job-card .btn-detail {
        padding: 14px 24.5px;
        margin-right: 2px;
    }
    .careers-main .career-jobs-second .career-job-card .career-job-text {
        font-size: 17px;
    }
    .careers-main .career-jobs-second .career-job-card .btn-apply {
        padding: 14px 28.5px;
    }
}