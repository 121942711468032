.main-contact {
    background: #FFFFFF;
    padding: 30px 65px;
    margin-top: 110px;

    .main-imgcontact {
        margin-bottom: 70px;

        img {
            width: 100%;
        }
        .iframe-map {
            width: 100% !important;
            height: 75vh !important;
        }
        .loader-image-myaccounts {
            text-align: center;
            img {
              width: 150px;
            }}
    }
    .main-feild {
        margin: 20px auto;
    }
    .main-heading {
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 100%;
            color: #264653;
        }
    }

    .location {
        margin-top: 30px;
        margin-bottom: 30px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 100%;
            color: #264653;
            margin-bottom: 20px;
        }

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: #264653;
            margin-bottom: 10px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            color: #264653;
        }
    }

    .social-head {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            margin-bottom: 17px;
            color: #264653;
        }
    }

    .img-icons {
        img {
            // margin-right: 13px;

            &:last-child {
                margin-right: 10px;
            }
        }
    }

    .drop-head {
        .drop-sub-1 {
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            color: #264653;
            font-size: 17px;
            margin-bottom: 15px;
        }

        .drop-sub {
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            color: #264653;
            margin-bottom: 30px;
            font-size: 14px;
        }
    }

    .main-feild {
        input {
            border: 1px solid #D9F0FA;
            border-radius: 5px;
            padding: 10px 15px;
            width: 100%;
            background-color: #FFFFFF;
            margin-bottom: 20px;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 155%;
                color: #BDD8E3;
            }
        }

        textarea {
            border: 1px solid #D9F0FA;
            border-radius: 5px;
            padding: 10px 15px;
            width: 100%;
            background-color: #FFFFFF;
            margin-bottom: 20px;
            height: 142px;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 155%;
                color: #BDD8E3;
            }
        }

        .secondfeild {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                border: 1px solid #D9F0FA;
                border-radius: 5px;
                padding: 10px 15px;
                width: 100%;
                background-color: #FFFFFF;

                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 155%;
                    color: #BDD8E3;
                }
            }

            .firstfeild {
                margin-right: 20px;
            }
        }

        .messagebtn {
            button {

                background: #009DE0;
                border-radius: 5px;
                padding: 14px 61px;
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #FFFFFF;
                width: 100%;
            }
        }
    }
}

@media(max-width:600px) {
    .main-contact .main-heading h2 {
        font-size: 22px;
    }

    .main-contact .location h3 {
        font-size: 18px;
    }

    .main-contact .location h4 {
        font-size: 14px;
    }

    .main-contact .img-icons {
        margin-bottom: 23px;
    }

    .main-contact .drop-head h2 {
        font-size: 22px;
    }

    .main-contact .main-feild .messagebtn button {
        width: 100%;
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    .main-contact { 
        padding: 28px 13px;
    }
}
.loader-image-myaccounts {
    text-align: center;
    img {
      width: 150px;
    }}