.loader-image-product-detail {
  text-align: center;
  img {
    width: 150px;
  }
}
.productdetail {
  margin-top: 25px;

  .top-button {
    margin-bottom: 40px;
    padding-top: 100px;
    .back {
      width: 51px;
      height: 51px;
      cursor: pointer;
    }
    .all-product {
      margin-left: 20px;
      margin-right: 10px;
      padding: 13px 15px;
      background: #f5fbfe;
      border: 1px solid #dcf2fb;
      border-radius: 5px;
      color: #264653;
      font-size: 16px;
    }
    .all-product2 {
      padding: 13px 33px 13px 15px;
      background: #f5fbfe;
      border: 1px solid #dcf2fb;
      border-radius: 5px;
      color: #009de0;
      font-size: 16px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      // width: 181px;
    }
    .drop {
      position: absolute;
      top: 50%;
      left: calc(80% + 5px);
      transform: translate(0, -50%);
      width: 8.57px; /* Adjust the image size as needed */
    }
    .dropdown .dropdown-toggle::after {
      display: none;
      max-height: 238px;
      width: 181px;
    }
    .dropdown-menu {
      margin-top: 5px;
      max-height: 238px;
      width: 181px;
      text-overflow: ellipsis;
      overflow-y: scroll;
      background-color: #f5fbfe;
      border: 1px solid #dcf2fb;
    }
    .dropdown-item {
      font-size: 14px;
      color: #000000;
      padding: 12px 8px 12px 8px;
      border-bottom: 1px solid #f5fbfe;
    }
    .dropdown-menu::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .dropdown-menu::-webkit-scrollbar-track {
      background: #d8f1fc;
    }

    /* Handle */
    .dropdown-menu::-webkit-scrollbar-thumb {
      background: #009de0;
      border-radius: 20px;
    }

    /* Handle on hover */
    .dropdown-menu::-webkit-scrollbar-thumb:hover {
      background: #009de0;
    }
  }

  .productmargin {
    .mainproduct {
      border: 2px solid #e6f6fc;
      border-radius: 10px;
      // height: 100%;
      padding-top: 20px;
      overflow: hidden;
      h6 {
        background: #009de0;
        border-radius: 5px;
        padding: 4px 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 155%;
        color: #ffffff;
        width: 64px;
        margin-left: 17px;
      }
      .innerimage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
      .outimagee {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        opacity: 0.5;
      }
    }

    .information {
      padding: 17px 20px;

      .writteninfo {
        padding-bottom: 20px;
        border-bottom: 1.5px solid #d9f0fa;

        .para {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #afafaf;
        }

        .head {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 155%;
          color: #264653;
          margin-top: -15px;
        }

        .secondpara {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 155%;
          color: #264653;
          margin-bottom: 15px;
        }

        .weight {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #afafaf;
          margin-bottom: 15px;
        }

        .discount {
          margin-bottom: 25px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          color: #009de0;

          .price {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-decoration-line: line-through;
            color: #afafaf;
          }
        }
        .discount1 {
          margin-bottom: 25px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          margin-top: 10px;
          color: #009de0;

          .price {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-decoration-line: line-through;
            color: #afafaf;
          }
        }

        .stock {
          background: #009de0;
          border-radius: 5px;
          padding: 5px 14px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #ffffff;
        }
        .outstock {
          background: #f95b34;
          border-radius: 5px;
          padding: 5px 14px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #ffffff;
        }
      }

      .quantityinfo {
        margin-top: 20px;

        .qpara {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 155%;
          color: #afafaf;
          margin-bottom: 10px;
        }

        .innercart {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .counter {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            row-gap: 20px;
          }

          .counter__output {
            width: 42.67px;
            height: 42px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 155%;
            color: #000000;
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #f7f7f7;
            border-bottom: 2px solid #f7f7f7;
          }

          .btn__container {
            display: flex;
            justify-content: center;
            flex-direction: row;
          }

          .control__btn {
            width: 42.67px;
            height: 42px;
            font-size: 18px;
            background: #009de0;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            touch-action: manipulation;
            &:hover {
              background: #009de0;
              color: #ffffff;
            }
          }
          .control__dis {
            width: 42.67px;
            height: 42px;
            font-size: 18px;
            background: #f7f7f7;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            color: #aaaaaa;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            touch-action: manipulation;
           
          }

          .minusbtn {
            width: 42.67px;
            height: 42px;
            font-size: 18px;
            background: #f7f7f7;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            color: #aaaaaa;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            touch-action: manipulation;
          }

          .minusbtn2 {
            width: 42.67px;
            height: 42px;
            font-size: 18px;
            background: #009de0;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            touch-action: manipulation;
          }

          .cartbtn {
            display: flex;
            border: 1.5px solid #009de0 !important;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 18px;
            background: #ffffff;
            border-radius: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #009de0;
            border: none;
            margin-left: 15px;
            &:hover {
              background: #009de0;
              color: #ffffff;
            }
          }

          .cartbtn[disabled] {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 18px;
            background: #e5f5fc;
            border-radius: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #afe0f4;
            border: 1px solid #e5f5fc !important;
            margin-left: 15px;
          }
        }

        .wishlist {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 18px;

          .heart {
            width: 24px;
            height: 24px;
            margin-right: 9px;
          }

          .wishpara {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #afafaf;
            margin-left: 10px;
          }
        }
      }
    }

    .shippinginfo {
      width: 100%;

      .main {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 1px 15px 21px 15px;
        margin-bottom: 20px;
        width: 100%;

        .outermain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 20px;

          .shipimg {
            width: 45px;
            height: 45px;
            margin-right: 15px;
          }

          .innermain {
            .shiphead {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: #264653;
              margin-bottom: 8px;
            }

            .shippara {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 100%;
              color: #264653;
            }
          }
        }
      }

      .helpline {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 20px 15px;
        width: 100%;

        .helphead {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: #264653;
          margin-bottom: 8px;
        }

        .helppara {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #264653;
          margin-bottom: 20px;
        }

        .helpnumber {
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 100%;
          color: #264653;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .productdetail .productmargin .shippinginfo {
    margin-top: 40px;
  }
  .productdetail .productmargin .shippinginfo .main .outermain {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px 0;
  }
  .productdetail .productmargin .shippinginfo .helpline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .productdetail .productmargin .shippinginfo .innermain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .productdetail .productmargin .shippinginfo .main .outermain .shipimg {
    margin: 0;
  }
}
@media (max-width: 991px) {
  .productdetail .productmargin .mainproduct {
    border: none;
  }
  .productdetail .productmargin .mainproduct {
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
@media (max-width: 600px) {
  .productdetail .productmargin .information {
    padding: 40px 0px;
  }
  .productdetail .productmargin .mainproduct {
    width: 100%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .productdetail .productmargin .information .writteninfo .head {
    margin-top: 0 !important;
  }

  .loader-image-product-detail {
    text-align: center;
    img {
      width: 150px;
      margin-top: 100px;
    }
  }
  .mainproduct {
    border: 2px solid #e6f6fc !important;
    border-radius: 10px !important;
  }
  .mid-detail {
    border: none !important;
  }
  .top-button {
    margin-bottom: 0 !important;
    // margin-top: 110px !important;

    .back {
      width: 51px;
      height: 51px;
      cursor: pointer;
    }
    .all-product {
      margin-left: 20px;
      margin-right: 10px;
      padding: 13px 15px;
      background: #f5fbfe;
      border: 1px solid #dcf2fb;
      border-radius: 5px;
      color: #264653;
      font-size: 16px;
    }
    .all-product2 {
      padding: 13px 33px 13px 15px;
      background: #f5fbfe;
      border: 1px solid #dcf2fb;
      border-radius: 5px;
      color: #009de0;
      font-size: 16px;
      position: relative;
      width: 150px;
    }
    .drop {
      position: absolute;
      top: 50%;
      left: calc(80% + 5px);
      transform: translate(0, -50%);
      width: 8.57px; /* Adjust the image size as needed */
    }
    .dropdown .dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu {
      margin-top: 5px;
      max-height: 238px;
      width: 181px;
      text-overflow: ellipsis;
      overflow-y: scroll;
      background-color: #f5fbfe;
      border: 1px solid #dcf2fb;
    }
    .dropdown-item {
      font-size: 14px;
      color: #000000;
      padding: 12px 8px 12px 8px;
      border-bottom: 1px solid #f5fbfe;
    }
    .dropdown-menu::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .dropdown-menu::-webkit-scrollbar-track {
      background: #d8f1fc;
    }

    /* Handle */
    .dropdown-menu::-webkit-scrollbar-thumb {
      background: #009de0;
      border-radius: 20px;
    }

    /* Handle on hover */
    .dropdown-menu::-webkit-scrollbar-thumb:hover {
      background: #009de0;
    }
  }
}

.lowertabsss {
  border-radius: 5px;
  border: 1px solid #f7f7f7;
  background: #fff;
  display: flex;
  padding: 3px;
  align-items: flex-start;
  .lowertabssssx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  a.tabshnd {
    white-space: nowrap;
    white-space: no;
    border-radius: 5px;
    background: #fff;
    display: flex;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: #929292;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    text-transform: uppercase;
    &:hover {
      border-radius: 5px;
      background: #dbf1fa;
    }

    &:focus {
      border-radius: 5px;
      background: #dbf1fa;
    }

    &.active {
      border-radius: 5px;
      background: #dbf1fa;
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 14.4px */
      text-transform: uppercase;
    }
  }
  .linedig {
    color: #5d9bf7;
    font-size: 16px;

    font-weight: 500;
  }
}
.pasasad {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 19px;
  .tabelatsds {
    color: #264653;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}
}
.addcounterdropdownfst{
  border: none !important;
    background: transparent !important;

  .addcounterdropdown{
    border: none;
    background: transparent;
    width: 80px;
  
  
  } 

}

.mid-detail {
  border-radius: 10px;
  border: 2px solid #e6f6fc;
  padding-top: 20px;
}
