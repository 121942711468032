.collection-items {
    padding:  0;
    padding-top: 0;
    background: #F6FCFF;
    // margin: 0px -15px;
    margin: 0px 0px;

    .img-wrap {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
        transition: 0.7s linear;

        &:hover {
            box-shadow: 0px 24px 24px -20px rgba(0, 157, 224, 0.4);
            transform: translateY(-10px);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


@media (max-width:1199px) {
    .collection-items {
        // padding: 40px 0;
    }

    .collection-items .parent-cards {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0 20px;
        overflow-x: hidden;
    }

    .mobilemb-2 {
        margin-bottom: 20px;
    }


}


.collection__items__row:first-child .mobilemb-2 {
    padding-left: 0px;
}

.mobilemb-2:nth-last-child(2) {
    padding: 0px 15px !important;
  }

.collection__items__row:last-child .mobilemb-2 {
    padding-right: 0px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  
    .collection__items__row:first-child .mobilemb-2 {
        padding-left: 0px !important;
    }
    .collection__items__row:last-child .mobilemb-2 {
        padding-right: 0px !important;
    }
}
