.loader-image-logout {
  text-align: center;

  img {
    width: 250px;
    margin: 100px;
  }
}

.product-image {
  width: 71px !important;
  height: 71px !important;
  border-radius: 160px;
}

.mobile-orders-main {
  display: none;
}

.mobile-issues-main {
  display: none;
}

.left-m {
  display: none;
}

.sec-main {
  padding-bottom: 90px;

  .breadcrumbb_main {
    background: #f3f3f3;
    padding-top: 27px;
    padding-bottom: 28px;
    margin-top: 19px;

    // margin-bottom: 38px;
    li.breadcrumb-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      color: #264653 !important;
      margin-right: 7px;
    }
  }

  .blue_div {
    background: #009de0;
    border-radius: 5px;
    align-items: center;
    padding: 15px 18px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 109px;

    .d-flex {
      display: flex !important;
      text-align: center;
      align-items: center;
    }

    p.order_para {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      text-transform: uppercase;
      color: #ffffff;
      padding-left: 12px;
    }

    p.browser {
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
    }
  }

  .blue_div1 {
    background: #009de0;
    border-radius: 5px;
    align-items: center;
    padding: 15px 18px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 247px;

    .d-flex {
      display: flex !important;
      text-align: center;
      align-items: center;
    }

    p.order_para {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      text-transform: uppercase;
      color: #ffffff;
      padding-left: 12px;
    }

    p.browser {
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
    }
  }

  .blue_div2 {
    width: 100%;
    background: #009de0;
    border-radius: 5px;
    align-items: center;
    padding: 15px 18px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 130pxpx;

    .d-flex {
      display: flex !important;
      text-align: center;
      align-items: center;
    }

    p.order_para {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      text-transform: uppercase;
      color: #ffffff;
      padding-left: 12px;
    }

    p.browser {
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
    }
  }

  .main_billingedit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 15px;

    h4.address {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 110%;
      color: #264653;
    }

    p.edit {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #009de0;
    }
  }

  .leftdown {
    text-align: center;
    margin-top: 20px;
    margin-left: 34px;

    button.btn_logout {
      background: #db4a4d;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      color: #ffffff;
      padding: 20px;
      gap: 10px;
      width: 100%;
      border: 2px solid transparent;
      /* width: 320px; */
      /* height: 58px; */
    }
  }

  li.names_list {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    color: #8b8b8b;
  }

  p.Dashboard {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #009de0;
    background: transparent;
  }
}

.twoooo {
  color: #4c6c79;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 14px */
  display: flex;
  align-items: center;
  gap: 15px;
}

.left-up {
  margin-top: 38px;

  // border-radius: 5px;
  align-items: center;
  // margin-top: 38px;
  border-radius: 8px;
  border: 2px solid #F5F5F5;
  background: #FFF;

  .accountnew-tabs {
    width: 100%;
    text-align: center;
    padding: 15px;
    border: none;
    border-top: 1px solid #f0f0f0;
    background-color: white;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    &:hover {
      background: #eefaff;
    }

    &.active {
      background: #eefaff !important;
    }

  }

  .left-1 {
    color: #009de0;
    font-size: 18px;
    font-weight: 500;
  }

  .left-2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #757474;
  }

  .hr-1 {
    background-color: #e3e3e3;

    margin-left: 0px;
  }

  .nav-link.active {
    color: #009de0 !important;
    background: #eefaff !important;
    border: none;
  }

  .nav-link.active p {
    color: #009de0 !important;
  }
}

.left-down {
  margin-top: 20px;
  // margin-left: 20px;

  .btn-1 {
    width: 100%;
    background-color: #db4a4d;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    border-radius: 5px;
  }
}

.right-main {
  margin-top: 38px;
  margin-bottom: 90px;

  .pa-1 {
    font-size: 14px;
    font-weight: 500;
    font-family: poppins;
    color: #8b8b8b;
    letter-spacing: 1px;
    margin-bottom: 28px;

    .span1 {
      color: #264653;
    }

    .span2 {
      color: #009de0;
    }
  }

  .pa-2 {
    // margin-top: 20px;
    color: #8b8b8b;
    font-size: 15px;
    font-weight: 500;
    color: #264653;

    .span1 {
      color: #009de0;
    }
  }

  .flex-main {
    width: 109%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .he-1 {
      font-size: 22px;
      font-weight: 700;
      font-family: poppins;
    }

    .pe-1 {
      color: #009de0;
      margin-right: 6px;
      font-size: 14px;
      font-weight: 500;
      font-family: Poppins;
    }

    .pa-3 {
      color: #8b8b8b;
      font-size: 14px;
      font-weight: 500;
      font-family: poppins;
      margin-top: 20px;
    }
  }
}

.dropdown {
  .btnbtnbtn {
    border: 1px solid #d9f0fa;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #bdd8e3;
    height: 46px;
    text-align: start;
    width: 237px;
    margin-left: 5px;
    margin-top: 4px;
    position: relative;
    text-transform: capitalize;
  }

  .dropdown-toggle::after {
    // display: inline-block;
    // margin-left: 7.255em;
    // vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    //  text-align: end;
    position: absolute;
    right: 20px;
    top: 21px;
  }
}

.bl-main {
  background-color: #009de0;
  // margin-top: 38px;
  display: flex;
  justify-content: space-between;
  // padding: 20px;
  border-radius: 5px;
  margin-top: 38px;
  margin-bottom: 20px;
  /* width: 110%; */
  padding: 15px 18px;

  .pe-1 {
    margin-left: 5px;
    margin-top: 3px;
    color: #ffffff;
  }

  .pe-2 {
    font-size: 15px;
    color: #ffffff;
  }
}

.sec-maino {
  .left-up {
    // margin-top: 50px;

    background-color: #f3f3f3;
    padding: 25px;
    border-radius: 5px;

    .left-1:active {
      color: #009de0;
      transition: 0.1s ease-in-out;
      cursor: pointer;
    }

    .left-1:active {
      color: #009de0;
      transition: 0.1s ease-in-out;
      cursor: pointer;
    }

    .left-2:active {
      color: #009de0;
      transition: 0.1s ease-in-out;
      cursor: pointer;
    }

    .hr-1 {
      background-color: #e3e3e3;

      margin-left: 0px;
    }
  }

  .left-down {
    margin-top: 20px;
    // margin-left: 20px;

    .btn-1 {
      width: 100%;
      background-color: #db4a4d;
      color: white;
      padding-top: 12px;
      padding-bottom: 12px;
      border: none;
      border-radius: 5px;
    }
  }

  .right-main {
    margin-top: 50px;

    .bl-main {
      // background-color: #009de0;
      // margin-top: 20px;
      // display: flex;
      // justify-content: space-between;
      // padding: 20px;
      // border-radius: 5px;
      // display: none;

      .pe-1 {
        margin-left: 5px;
        margin-top: 3px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .pe-2 {
        font-size: 15px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }
}

.marker {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn {
  background: #009de0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 14px 50px;
  border: none;
  margin-top: 53px;
  margin-bottom: 40px;
}

.di-main {
  margin-top: 37px;
  border-radius: 5px;
  margin-bottom: 125px;

  .t-mainx {
    border: none;

    thead.head {
      background: #ededed;
      border: 1px solid transparent;
      text-align: center;
    }

    .th-1 {
      // background-color: #ededed;
      padding-top: 20px;
      padding-bottom: 20px;
      // padding-left: 40px;
      color: #747474;

      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
    }

    tr {
      border-bottom: 1.5px solid #d9f0fa;
    }

    .tr-1 {
      color: #264653;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
      /* background: aliceblue; */
      background: #f7f7f7;
      padding: 40px;

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;
        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }
    }

    .tr-1x {
      padding-top: 57px !important;
      padding-bottom: 57px;
      background-color: #f7f7f7;
      padding-left: 40px;
      color: #264653;
      font-size: 16px;
      font-weight: 500;

      .t1 {
        margin-top: -13px;
      }

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;
        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }
    }

    .tr-2 {
      padding-top: 60px;
      padding-bottom: 50px;
      background-color: #f7f7f7;
      padding-left: 40px;
      color: #264653;
      font-size: 16px;
      font-weight: 500;
      width: 44%;

      .t1 {
        margin-top: -13px;
      }

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;

        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }
    }
  }
}

// right up
.up-main {
  background-color: #f7f7f7;
  margin-bottom: 20px;
  border-radius: 5px;
  margin-top: 38px;
  padding: 20px;

  .df-main {
    display: flex;
    justify-content: space-between;

    .to-1 {
      color: #264653 !important;
      font-size: 18px;
      font-weight: 600;
      font-family: poppins;
    }
  }

  .df-main p {
    color: #747474;
    font-size: 15px !important;
    font-weight: 500;
    font-family: poppins;
    text-align: right;
  }

  .hr-x {
    background-color: #efefef;
  }
}

.ml-2 {
  cursor: pointer;
}

.left-down {
  margin-top: 20px;
  // margin-left: 20px;

  .btn-1 {
    width: 100%;
    background-color: #db4a4d;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    border-radius: 5px;
  }
}

.parentinput {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btnchange {
    background: #ffffff;
    border: 1.5px solid #009de0;
    border-radius: 5px;
    padding: 12px;
    width: 210px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #009de0;
    margin-top: 25px;
  }
}

.inp-2 {
  width: 100% !important;
  padding: 10px;
  border: 1px solid #d9f0fa;
  margin-top: 5px;
  border-radius: 5px;

  &::placeholder {
    color: #bdd8e3;
    font-size: 14px !important;
    font-weight: 400;
  }
}

.inp-2::-webkit-inner-spin-button,
.inp-2::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inp-2 {
  -moz-appearances: textfield;
}

.pa-1 {
  font-size: 28px;
  font-weight: 500;
  font-family: poppins;
  color: #264653;
}

.pa-2 {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;

  .spa1 {
    color: #db4a4d;
    font-size: 15px;
    font-weight: 500;
  }
}

button.btn-2x {
  background: #009de0;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 155%;
  text-transform: uppercase;
  color: #ffffff;
  gap: 7px;
  border: 2px solid transparent;
  width: 229px;
  height: 42px;
  margin-top: 20px;
  margin-bottom: 90px;
}

button.btn-2x {
  background: #009de0;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 155%;
  text-transform: uppercase;
  color: #ffffff;
  gap: 7px;
  border: 2px solid transparent;
  width: 210px;
  height: 42px;
  margin-top: 15px;
  margin-bottom: 90px;
}

button.btn_detail {
  background: #009de0;
  border-radius: 5px;
  /* padding: 12px 18px; */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 155%;
  width: 107px;
  height: 38px;
  border: 2px solid transparent;
  transition: all 1.4s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);

  :hover {
    // background: linear-gradient(#009DE0);
    background: #f3f3f3;
    color: #009de0;
    transform: rotatex(10deg) rotateY(15deg);
  }
}

.inp-1 {
  width: 110% !important;
  padding: 8px;
  border: 1px solid #d9f0fa;
  margin-top: 5px;
  border-radius: 5px;
}

.inp-1::placeholder {
  color: #bdd8e3;
  font-size: 30px !important;
  font-weight: 500;
}

.btn-1 {
  width: 100%;
  background-color: #db4a4d;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

.issue-form {
  margin-top: 38px;

  .head {
    font-weight: 500;
    font-size: 28px;
    color: #264653;
  }

  .form {
    margin-top: 22px;

    .lab {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      color: #264653;
      padding-bottom: 10px;
    }

    select {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 155%;
      color: #533e26;
      padding-bottom: 10px;
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;

      &:focus {
        background-image: linear-gradient(45deg, #009de0 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, #009de0 50%), linear-gradient(to right, #ccc, #ccc);
        background-position:
          calc(100% - 15px) 1em,
          calc(100% - 20px) 1em,
          calc(100% - 2.5em) 0.5em;
        background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
        background-repeat: no-repeat;
        border-color: #009de0;
        outline: 0;
      }
    }

    .num {
      width: 100%;

      background: #f5f5f5;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      padding: 10px 15px;
      gap: 10px;

      &::placeholder {
        font-weight: 500;
        font-size: 14px;
        color: #868686;
      }

      .form-group {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #b3b3b3;
      }
    }

    .dropdown {

      .btn-primary:not(:disabled):not(.disabled):active,
      .show>.btn-primary.dropdown-toggle {
        color: #81cdee;
        background-color: transparent;
        border-color: #d9f0fa;
      }

      .btn-primary:not(:disabled):not(.disabled):active:focus,
      .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
      }

      button {
        width: 100%;
        padding: 15px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        color: #b3b3b3;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:focus {
          box-shadow: none;
        }
      }

      .dropdown-menu {
        width: 100%;
        border-radius: 10pxs;
      }
    }

    .info2 {
      width: 100%;
      height: 140px;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      padding: 10px 15px;

      &::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #bfbfbf;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .limit {
      float: right;
      font-weight: 500;
      font-size: 16px;
      color: #bfbfbf;
    }
  }

  .sub {
    background: #009de0;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    padding: 14px 89px;
    border: none;
    margin-top: 53px;
    margin-bottom: 90px;
  }

  .sub-red {
    background: #db4a4d;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    padding: 14px 50px;
    border: none;
    margin-top: 53px;
    margin-bottom: 90px;
    margin-left: 30px;
  }
}

.billing_details {
  margin-top: 38px;

  h3.Billing_detailsheaging {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    /* line-height: 100%; */
    color: #264653;
    // padding-bottom: 10px;
  }

  .mainaddress {
    padding-bottom: 90px;

    .inneradress {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 25px;
      background: #ffffff;
      border: 1px solid #d9f0fa;
      border-radius: 5px;
      margin-bottom: 20px;

      .left {
        .lefthead {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #363636;

          .locationicon {
            margin: 0;
            margin-right: 7px;
            width: 24px;
            height: 24px;
          }
        }

        .leftpara {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #a7a7a7;
          margin-top: 10px;
        }
      }

      .right {
        .icons {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .newaddressbtn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px 52px;
      background: #009de0;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      border: none;
    }
  }
}

.sec-main .modal {
  .modal-dialog {
    max-width: 407px;
    margin-top: 185px;
  }

  .modal-content {
    padding: 20px 45px 42px 45px;
  }

  .btn-close {
    padding: 10px;
    background-image: url(.//..//../cross.svg);
    background-repeat: no-repeat;
    border: none;
    background-color: white;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .modal-header {
    padding: 0;
    border: none;
  }

  .modal-body {
    padding: 0;
  }

  .modal-main {
    text-align: center;
    margin-top: 20px;

    .pic {
      width: 135.85px;
    }

    .heading {
      font-weight: 600;
      font-size: 20px;
      color: #363636;
      margin-top: 33px;
    }

    .para {
      font-weight: 400;
      font-size: 14px;
      color: #969696;
      margin-top: 14px;
    }
  }

  .btn-1 {
    background-color: #009de0;
    width: 100%;
    margin-top: 25px;
    display: none;
  }

  .parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .address-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #777676;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    width: 267px !important;
    text-overflow: ellipsis;
  }

  .breadcrumbb_main {
    background: #f3f3f3;
    padding-top: 27px;
    padding-bottom: 28px;
    margin-top: 105px !important;
  }

  .t-main {
    display: none;
  }

  .left-m {
    display: block;
  }

  .mobile-issues-main {
    display: block;
    margin-top: 20px;

    .issues-detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .issues-date {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: #264653;
        font-family: poppins;
      }
    }

    .detail-btn-issues {
      width: 100%;
      background-color: #009de0;
      color: #ffffff;
      font-size: 12px;
      line-height: 18.6px;
      font-weight: 600;
      border: none;
      padding: 9.5px 0;
      border-radius: 5px;
    }

    .issues-hr {
      width: 100%;
      border: 1.5px solid #d9f0fa;
      color: #d9f0fa;
      margin: 30px 0;
    }
  }

  .mobile-orders-main {
    display: block;
    margin-top: 20px;

    .order-detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .order-date {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: #264653;
        font-family: poppins;
      }
    }

    .detail-btn-orders {
      width: 100%;
      background-color: #009de0;
      color: #ffffff;
      font-size: 12px;
      line-height: 18.6px;
      font-weight: 600;
      border: none;
      padding: 9.5px 0;
      border-radius: 5px;
    }

    .orders-hr {
      width: 100%;
      border: 1.5px solid #d9f0fa;
      color: #d9f0fa;
      margin: 30px 0;
    }
  }

  .di-main .t-mainx {
    display: none;
  }

  .sec-main {
    .issue-form {
      .issue-main-btn {
        .sub {
          background: #009de0;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          padding: 14px 89px;
          border: none;
          margin-top: 53px;
          margin-bottom: 0 !important;
        }

        .sub-red {
          background: #db4a4d;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          padding: 14px 97px;
          border: none;
          margin-top: 22px;
          margin-bottom: 90px;
          margin-left: 0;
        }
      }
    }

    .left-up {
      width: 100%;
      margin-left: 0px !important;
    }

    .left-down {
      .btn-1 {
        width: 110%;
        margin-left: -22px;
      }
    }

    .right-main {
      .pa-2 {
        text-align: center !important;
      }

      .pa-1 {
        text-align: center !important;
      }

      .bl-main {
        // width: 113%;
        // margin-left: -14px;

        .pe-2 {
          font-size: 13px !important;
          margin-top: 4px;
          text-align: center;
        }

        .pe-1 {
          text-align: center;
        }
      }
    }

    .inp-1 {
      width: 100% !important;
    }
  }

  .parentinput {
    .btn-change-main {
      display: flex;
      justify-content: center;

      .btnchange {
        width: 207px !important;
      }
    }
  }

  .save-btn-main {
    display: flex;
    justify-content: center;
  }

  .sec-main .leftdown {
    margin-left: 0;
  }

  .di-main {
    // margin-top: 20px;
    white-space: nowrap;
    margin-bottom: 0 !important;
  }
}

.t-main1 {
  border-radius: 5px;
  white-space: nowrap;

  .th-1 {
    background-color: #ededed;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    color: #747474;
    font-size: 18px;
    font-weight: 500;
  }

  .tr-1 {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #f7f7f7;
    padding-left: 40px;
    color: #264653;
    font-size: 16px;
    font-weight: 500;
    width: 43%;

    .pr-1 {
      font-size: 14px;
      font-weight: 500;
      color: #264653;
      margin-top: 15px;
      margin-left: 10px;
    }

    .pr-2 {
      font-size: 12px;
      font-weight: 400;
      color: #264653;
      margin-left: 10px;
    }

    .btn-2 {
      padding-top: 9px;
      padding-right: 25px;
      padding-left: 25px;
      padding-bottom: 9px;
      border: none;
      background-color: #009de0;
      border-radius: 5px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .pr-e {
    padding-top: 52px;
  }
}

button.btnReport_issue {
  background: #db4a4d;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  padding: 20px;
  border: 2px solid transparent;
}

.bl-main {
  background-color: #009de0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  display: none;

  .pe-1 {
    margin-left: 5px;
    margin-top: 3px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .pe-2 {
    font-size: 15px;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.di-main {
  border-radius: 5px;
  // margin-bottom: 308px;
  // display: none;

  .t-main {
    // border: none;

    .th-1 {
      background-color: #ededed;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 40px;
      color: #747474;
      font-size: 18px;
      font-weight: 500;
    }

    .t-main-order {
      padding-left: 70px !important;
    }

    .tr-1 {
      padding-top: 47px !important;
      padding-bottom: 30px;
      background-color: #f7f7f7;
      padding-left: 40px;
      color: #264653;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;

      .t1 {
        margin-top: -13px;
      }

      .btn-2 {
        padding-top: 10px;
        padding-right: 34px;
        padding-left: 34px;
        padding-bottom: 10px;
        border: none;
        background-color: #009de0;
        border-radius: 5px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        margin-top: -5;
        text-transform: uppercase;
      }

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;
        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }
    }

    .tr-1 {
      // padding-top: 47px !important;
      // padding-bottom: 30px;
      background-color: #f7f7f7;
      padding-left: 40px;
      color: #264653;
      font-size: 16px;
      font-weight: 500;

      .t1 {
        margin-top: -13px;
      }

      .btn-2 {
        padding-top: 10px;
        padding-right: 34px;
        padding-left: 34px;
        padding-bottom: 10px;
        border: none;
        background-color: #009de0;
        border-radius: 5px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        margin-top: -5;
        text-transform: uppercase;
      }

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;
        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }
    }

    .tr-1 {
      padding-top: 47px !important;
      padding-bottom: 30px;
      background-color: #f7f7f7;
      padding-left: 40px;
      color: #264653;
      font-size: 16px;
      font-weight: 500;

      .t1 {
        margin-top: -13px;
      }

      .btn-2 {
        padding-top: 10px;
        padding-right: 34px;
        padding-left: 34px;
        padding-bottom: 10px;
        border: none;
        background-color: #009de0;
        border-radius: 5px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        margin-top: -5;
        text-transform: uppercase;
      }

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;
        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }

      .table>tbody {
        vertical-align: unset !important;
      }
    }

    .co-1 {
      color: #00ae11;
    }

    .co-2 {
      color: #ffb800;
    }

    .co-3 {
      color: #009de0;
    }

    .co-4 {
      color: #00ae11;
    }

    .tr-2 {
      padding-top: 60px;
      padding-bottom: 50px;
      background-color: #f7f7f7;
      padding-left: 40px;
      color: #264653;
      font-size: 16px;
      font-weight: 500;
      width: 44%;

      .t1 {
        margin-top: -13px;
      }

      .pr-1 {
        color: #264653;
        font-size: 14px;
        font-weight: 500;
        font-family: poppins;

        margin-left: 10px;
      }

      .pr-2 {
        color: #264653;
        font-size: 12px;
        margin-left: 10px;
        font-weight: 400;
      }
    }
  }
}

// right up
.up-main {
  background-color: #f7f7f7;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px 15px;

  .df-main {
    display: flex;
    justify-content: space-between;

    .to-1 {
      color: black;
      font-size: 18px;
      font-weight: 600;
      font-family: poppins;
    }
  }

  .df-main p {
    color: #747474;
    font-size: 18px;
    font-weight: 500;
    font-family: poppins;
  }

  .hr-x {
    background-color: #efefef;
  }
}

.sub-red2 {
  background: #db4a4d;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 14px 50px;
  border: none;
  margin-top: -4px;
  margin-bottom: 30px;
}

.sub-red2:hover {
  background: #db4a4d;
  color: #ffffff;
}

.right-mainmm {
  margin-top: 38px;
  margin-bottom: 90px;
  background-color: #f7f7f7;
  padding: 25px 40px;

  //    width: 110%;
  .right-1mm {
    display: flex;
    justify-content: space-between;

    .pa-1x {
      font-size: 15px;
      color: #747474;
      font-weight: 500;
    }

    .pa-2x {
      font-size: 15px;
      color: #264653;
      font-weight: 500;
    }

    .pa-3 {
      font-size: 15px;
      color: #009de0;
      font-weight: 500;
    }
  }

  .hr-1x {
    background-color: #efefef;
  }
}

.upload-parent {
  .headingsss {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #264653;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;

    .uploadhead {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      color: #000000;
      text-align: center;
      margin-top: 5px;
    }

    .uploadpara {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 170%;
      color: #6d6d6d;
      margin-top: 5px;
      text-align: center;
    }
  }

  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px dashed #d9f0fa;
    height: 242px;
    overflow: hidden;
  }

  .after-upload {
    background: #0f0f0f;
    border: 1px solid #151515;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .parent-main {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .left {
        width: 72px;
        height: 54px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .right {
        margin-left: 20px;

        h6 {
          font-family: "Akira Expanded" !important;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 170%;
          color: #ffffff;
        }

        p {
          font-family: "Akira Expanded";
          font-style: normal;
          font-weight: 800;
          font-size: 10px;
          line-height: 170%;
          color: #797979;
        }
      }
    }

    .loading {
      opacity: 0.4;
    }

    .parent-right {
      i {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
      }

      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .parentinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .dropdown .dropdown-toggle::after {
    margin-left: 13.255em;
  }

  .mblrespon {
    width: 100% !important;
  }

  .billing_details .mainaddress .inneradress {
    padding: 10px 12px;
    position: relative;
  }

  .billing_details .mainaddress .inneradress .right {
    position: absolute;
    right: 5px;
  }

  .billing_details .mainaddress .inneradress .right .icons {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}

.changepassword {
  .modal-dialog {
    .modal-content {
      background: #ffffff;
      backdrop-filter: blur(32px);
      /* Note: backdrop-filter has minimal browser support */
      padding: 25px;
      width: 514px;
      border-radius: 5px;
      margin: 0 auto;

      .modal-header {
        margin-bottom: 38px;

        .modal-title {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 100%;
          color: #264653;
        }

        .btn-close {
          background: url("../../../src/assets/cross.svg");
        }
      }

      .modal-body {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          color: #264653;
          margin-bottom: 10px;
        }

        input {
          background: #ffffff;
          border: 1px solid #d9f0fa;
          border-radius: 5px;
          padding: 10px 15px;
          width: 100%;
          margin-bottom: 25px;

          &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #bdd8e3;
          }
        }
      }

      .modal-footer {
        .save {
          background: #009de0;
          border-radius: 5px;
          padding: 20px;
          width: 100%;
          border: none;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          /* identical to box height, or 14px */

          color: #ffffff;
        }
      }
    }
  }
}

.locationmodal {
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      padding: 25px;
      min-width: 832px;
      background: #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 5px;
      margin: 0 auto;

      .modal-header {
        padding: 8px 0px;
        border: none;

        .modal-title {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 100%;
          color: #264653;
        }

        .btn-close {
          background: url("../../assets/entypo_cross.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          right: 25px;
          top: 25px;
          width: 40px;
          height: 40px;
          margin: 0px;
          padding: 0px;
        }
      }

      .modal-body {
        padding: 0px;

        .map-div {
          // width: 738px;
          height: 500px;
          margin-top: 15px;
        }

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: #363636;
          margin: 25px 0px;
        }

        .map {
          margin-top: 30px;
          margin-bottom: 25px;
          height: 353px;
          width: 100%;
        }

        .formlocation {
          .headmodal {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #363636;
            margin-bottom: 25px;
          }
        }

        .maininput {
          background: #ffffff;
          border: 1px solid #d9f0fa;
          border-radius: 5px;
          padding: 10px 15px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          color: #000000;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 25px;

          &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #bdd8e3;
          }
        }

        .maininput::-webkit-inner-spin-button,
        .maininput::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .maininput {
          -moz-appearances: textfield;
        }

        .inputss {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .innerinputs {
            width: 49%;
          }
        }

        .input-star {
          color: red;
        }

        .address-error-text {
          font-size: 12px !important;
          margin-top: -20px !important;
          margin-bottom: 8px;
          font-weight: 500;
          color: red;
        }

        .error-input-account {
          border: 2px solid red !important;
        }

        .error-input-password {
          border: 2px solid red !important;
        }

        .spanbtns {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .spanicon {
            height: 25px;
            width: 25px;
          }

          span {
            cursor: pointer;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border: 1px solid #d1d1d1;
            border-radius: 20px;
            padding: 12px;
            font-size: 16px;
            line-height: 100%;
            color: #565656;
            gap: 5px;
            width: 33%;

            &.active {
              background: #e6f6fc;
              border: 1px solid #009de0;
            }
          }
        }

        .savebtn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 20px;
          background: #009de0;
          border-radius: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
          border: none;
          width: 100%;
          margin-top: 38px;
        }
      }
    }
  }

  .suggestions {
    li {
      margin: 10px;
    }
  }
}

.deletemodal {
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      padding: 25px;
      min-width: 270px;
      background: #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 5px;
      margin: 0 auto;

      .modal-header {
        padding: 8px 0px;
        border: none;

        .modal-title {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 100%;
          color: #264653;
        }

        .btn-close {
          background: url("../../assets/entypo_cross.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          right: 25px;
          top: 25px;
          width: 40px;
          height: 40px;
          margin: 0px;
          padding: 0px;
        }
      }

      .modal-body {
        padding: 0px;
        padding-top: 20px;

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
          color: #000000;
          text-align: center;
        }

        .deletebtns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 20px;

          .dark {
            width: 49%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px;
            background: #009de0;
            border-radius: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #ffffff;
            border: 1px solid transparent;

            &:hover {
              border: 1px solid #009de0;
              background-color: transparent;
              color: #009de0;
            }
          }

          .light {
            width: 49%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px;
            background: transparent;
            border-radius: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #009de0;
            border: 1px solid #009de0;

            &:hover {
              border: 1px solid transparent;
              background-color: #009de0;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 860px) {
  .locationmodal .modal-dialog .modal-content {
    min-width: 100%;
  }

  .locationmodal .modal-dialog .modal-content .modal-body .inputss {
    flex-direction: column;
    width: 100%;

    .innerinputs {
      width: 100%;
    }
  }

  .locationmodal .modal-dialog .modal-content .modal-body .map {
    height: 250px;
  }
}

.spanactive {
  background: #e6f6fc !important;
  border: 1px solid #009de0 !important;
}

.selectmissing-items {
  margin: 22px 0;

  .top-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #264653;
    margin-bottom: 10px;
  }

  .tail-box {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 5px;

    .heading-text {
      padding: 19px 40px;
      background: #fafafa;

      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #747474;
      }
    }
  }

  .bottom-content {
    padding: 19px 40px;

    .selection {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1.5px solid #d9f0fa;
      padding: 18px 0;

      &:last-child {
        border-bottom: none;
      }

      .right-side {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .brand-img {
          width: 71px;
          height: 71px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .brand-name {
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #264653;
            margin-bottom: 9px;
          }

          h6 {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: #264653;
          }
        }
      }
    }
  }

  .check-main {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .check-main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: #ffffff;
    border: 1.5px solid #e2e2e2;
    border-radius: 6px;
  }

  /* When the checkbox is checked, add a blue background */
  .check-main input:checked~.checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .check-main input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .check-main .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.loader-image-myaccounts {
  text-align: center;

  img {
    width: 150px;
  }
}

.mainaddress-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 290px;

  .address-box {
    background: #ffffff;
    border: 1px solid #d9f0fa;
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 20px;
    height: 200px;
    flex: 0 1 49%;

    .upper-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .left {
        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #363636;
          overflow: hidden;
          white-space: nowrap;
          width: 200px;
          text-overflow: ellipsis;
        }
      }

      .right {
        .right-edit {
          padding-right: 10px;
          cursor: pointer;

          &:hover {
            margin-top: -5px;
          }
        }
      }
    }

    .static-address {
      background: #f5fbfe;
      border: 1px solid #dcf2fb;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 10px;
      max-width: 84px;
      width: 100%;
      margin-bottom: 10px;

      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #009de0;
      }
    }

    .address-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #777676;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      width: 400px;
      text-overflow: ellipsis;
    }

    .phone-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #a7a7a7;
    }
  }
}

@media (max-width: 1415px) {
  .mainaddress-new .address-box {
    flex: 0 1 48.5%;
  }
}

@media (max-width: 800px) {
  .mainaddress-new .address-box {
    flex: 0 1 100%;
  }
}

// lo
.log___outtt__btn {
  background: #db4a4d;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  padding: 20px;
  grid-gap: 10px;
  gap: 10px;
  // width: 100%;
  color: white !important;
  border: 2px solid transparent;
}

.bankreceipt {
  .modal-header {
    border: none;
    // padding: 20px;
  }

  .modal-body {
    padding: 0;
  }

  .modal-dialog {
    max-width: 407px;
  }

  .btn-close {
    background: url("../../assets/close-icon.svg") !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
}

.reciept-img {
  max-width: 410px;
  width: 100%;
  height: 354px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.upload-img {
  background: #ffffff;
  // border: 1px dashed #E9E9E9;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  h6 {
    font-size: 12px;
    line-height: 100%;
    color: #848a8d;
  }

  .upload-img-set {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.orderbtn {
  width: 100%;

  button {
    background: #009de0;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    height: 42px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-footer-footer {
  justify-content: space-evenly;
  padding: 10px 20px;
}

.orderid-validation-error {
  font-size: 13px !important;
  margin-top: 3px;
  font-weight: 500;
  color: red !important;
}

.password-validionss-error {
  font-size: 12px !important;
  margin-top: -18px !important;
  margin-bottom: 8px;
  font-weight: 500;
  color: red !important;
}

.accountss-validations {
  font-size: 12px !important;
  margin-bottom: 8px !important;
  font-weight: 500;
  color: red !important;
}

.error-inputs-passwords {
  border: 2px solid red !important;
}

.error-input-accountdetail {
  border: 2px solid red !important;
}

.orderid-input-error {
  border: 2px solid red !important;
}
.eye-icon {
  position: absolute;
  top: 60px;
  right: 30px;
}

