.main-payment {
    background: #FFFFFF;
    padding: 28px 0px;
    padding-bottom: 90px;

    .maincart-card {
        background: #F3F3F3;
        padding: 27px 60px;
        border-width: 1px 0px;

        .bread {
            li {
                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 155%;
                    color: #264653;
                }
            }
        }
    }

    .backbtn {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 170%;
        color: #009DE0;
        cursor: pointer;
        margin-top: 50px;
        margin-bottom: 30px;
        text-decoration: underline;
    }

    .general-heading {
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 45px;
            line-height: 100%;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 20px;
            text-align: center;
        }

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 27px;
            line-height: 155%;
            text-transform: uppercase;
            color: #000000;
            margin: 60px 0 20px 0;

        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23.8px;
            margin-bottom: 30px;
            color: #000000;

            span {
                font-style: normal;
                font-weight: 800;
                font-size: 42px;
                line-height: 23.8px;
                margin-bottom: 40px;
                color: #000000;
            }

        }
        .side-spacing{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23.8px;
            margin-bottom: 30px;
            color: #000000;
            margin-left: 30px;
        }
        .hyperlink {
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 23.8px;
            margin-bottom: 30px;
            color: #000000;
        }
        .font-bold {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 23.8px;
            margin-bottom: 30px;
            color: #000000;
        }

    }

    .complaints-heading {
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 100%;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 20px;
            text-align: center;
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 155%;
            text-transform: uppercase;
            color: #000000;
            margin: 80px 0 20px 0;

        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23.8px;
            margin-bottom: 40px;
            color: #000000;


        }

        .complain {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23.8px;
            margin-bottom: 20px;
            color: #000000;
        }
    }

    .orders-heading {
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 100%;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 20px;
            text-align: center;
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 155%;
            text-transform: uppercase;
            color: #000000;
            margin: 80px 0 20px 0;

        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23.8px;
            margin-bottom: 40px;
            color: #000000;


        }

        .orders {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23.8px;
            margin-bottom: 20px;
            color: #000000;
        }
    }

    .main-para {
        ol {
            li {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23.8px;
                margin-bottom: 40px;
                color: #000000;
            }
        }
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {  
    .main-term .general-heading h2 {
        font-size: 32px;
    }
    .main-term .general-heading h4 {
        font-size: 22px;
    }
}

@media(max-width:600px) {
    .main-term .main-heading h2 {
        font-size: 22px;
        white-space: nowrap;
    }

    .main-term .main-heading h3 {
        font-size: 18px;
    }

    .main-term .main-heading p {
        font-size: 14px;
    }

    .main-term ol {
        padding-left: 15px;
    }
}
