.wrapper-slider {
  padding: 35px 0;
  background: url("../../assets/banner-bg.png");
  background-position: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-category {
  .row>*{
    padding-left: 7.5px !important;
    padding-right: 7.5px !important;
  }
  background: #ffffff;
  padding: 28px 0px;
  padding-top: 135px;

  .headingall {
    margin-top: 20px;
    margin-bottom: 35px;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 110%;
      color: #264653;
    }

    .top-saver {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      color: #757575;
      background: #f3f3f3;
      border-radius: 5px;
      padding: 12px;
      white-space: nowrap;
      border: none;
      margin-top: 20px;

      &:hover {
        background: #009de0;
        color: #ffff;
      }
    }
  }

  .mobile-btns {
    .left-side {
      margin-bottom: 10px;

      h6 {
        color: #929292;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;

        span {
          color: #264653;
        }
      }

      p {
        color: #A3A3A3;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.4px */
      }
    }

    h3 {
      font-size: 24px !important;
      color: #264653;
      font-weight: 700 !important;
      margin-top: 10px !important;
      display: none;
    }

    .button2 {
      width: 100% !important;
      background: #ffffff;
      border: 1px solid #D9F0FA;
      border-radius: 5px;
      padding: 10px 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #009DE0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      margin-top: 3px;
      transition: 0.3s linear;

      &:focus {
        border: 1.5px solid #009DE0;
      }
    }

    .dropdown {
      width: 48%;

      .dropdown-toggle {
        border-radius: 8px;
        border: 1px solid #D9F0FA;
        background: #FFF;
        padding: 11px 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        color: #616161;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.4px */
        width: 100%;
        transition: 0.3s linear;

        &:focus {
          border: 1.5px solid #009DE0;
        }

        span {
          color: #000;
        }

        .twice-text {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          h6 {
            color: #616161;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 14.4px */
            margin-bottom: 5px;
          }

          p {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 14.4px */
          }
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 8px;
        border: 1px solid #D9F0FA;
        background: #FFF;
        padding: 11px 17px;

        a {
          color: #616161;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-transform: capitalize;
          /* 14.4px */
        }
      }

    }
  }

  .shop-category {
    .categoryhead {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        color: #264653;
        margin-bottom: 20px;
      }
    }
  }

  .accordion-item {
    border: none !important;

    .accordion-header {
      .twice-btns {
        margin-bottom: 10px;

        .accordion-button {
          box-shadow: none !important;
        }

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #757575;

          background: #f3f3f3;
          border-radius: 5px;
          padding: 12px;
          white-space: nowrap;

          &:hover {
            background: #009de0;
            color: #ffff;
          }
        }
      }

      .fruits {
        background: transparent;
        border-radius: 5px;
        padding: 8px 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #ffffff;
        border: none !important;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .accordion-body {
      .buttonbody {
        .btn1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          border: none;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            border: none;
            color: #757575;
            display: flex;
            justify-content: space-between;
          }

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #b2b2b2;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .pricerange {

    .categormblmain {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      white-space: wrap;
      width: 100%;
      max-height: 250px;
      gap: 10px;
    }

    .rangehead {
      margin-bottom: 20px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        margin-top: 30px;
        color: #264653;
      }
    }

    .categoryhead {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        margin-top: 30px;
        color: #264653;
        padding-bottom: 20px;
      }
    }

    .buttons {
      .allbtn {
        margin-bottom: 10px;

        button {
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #757575;
          border: none;
          margin-bottom: 10px;
          text-align: start;

          &:hover {
            background: #009de0;
            color: #ffff;
            cursor: pointer;
          }

          &.active {
            background: #009de0;
            color: #ffff;
            cursor: pointer;
          }

          // &:focus {
          //   font-style: normal;
          //   font-weight: 500;
          //   font-size: 12px;
          //   line-height: 120%;

          //   background: #009de0;
          //   color: #ffff;
          //   border-radius: 5px;
          //   padding: 8px 12px;
          //   white-space: nowrap;

          //   &:hover {
          //     background: #5ba7c8;
          //     color: rgb(194, 194, 194);
          //     cursor: pointer;
          //   }
          // }
        }

      }
    }


  }

  .main-para {
    margin-bottom: 40px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      color: #92a2a9;
    }

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      color: #000000;
    }
  }


  .main-cardsss {
    border: 1px solid #d9f0fa;
    filter: drop-shadow(0px 13px 30px rgba(0, 157, 224, 0.1));
    border-radius: 10px;
    padding: 15px;

    .upperimg {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background: #009de0;
        border-radius: 5px;
        padding: 4px 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 155%;
        color: #ffffff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
      }
    }

    .content {
      p {
        margin-top: 13px;
        margin-bottom: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-transform: uppercase;
        color: #92a2a9;
      }

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 110%;
        color: #264653;
        margin-bottom: 15px;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 110%;
        color: #009de0;
      }
    }

    .cardbutton {
      margin-top: 15px;

      button {
        // background: #009de0;
        border-radius: 5px;
        padding: 12px 18px;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 155%;
        text-align: center;
        text-transform: uppercase;
        border: none;
        color: #ffffff;
      }
    }
  }

  .showmore {
    background: #264653;
    border-radius: 5px;
    padding: 10px;
    width: 40%;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 90px;
    // margin: 0 auto;
  }

  .left-sidee {
    margin-top: -125px;

    .loader-image-categories {
      text-align: center;

      img {
        width: 150px;
        margin-top: 200px;
      }

    }
  }

  .card-topsaver {
    background: #ffffff;
    border: 1px solid #d9f0fa;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    transition: 0.3s linear;
    height: 295px;
    overflow: hidden;

    &:hover {
      background: #ffffff;
      border: 1px solid #d9f0fa;
      box-shadow: 0px 13px 30px rgba(0, 157, 224, 0.1);
      border-radius: 10px;
    }

    .inner-img {
      width: 100%;
      height: 190px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      .btn-add{
        position: absolute;
        right: 5px;
        bottom: 8px;
    }
    .add-counter{
        border-radius: 51px;
        border: 1px solid #009DE0;
        background: #FFF;
        box-shadow: 0px 1px 7px 0px rgba(0, 157, 224, 0.30);
        padding: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 96px;
        width: 100%;
        span{
            color: #264653;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 24.8px */
        }
    }

      img {
        width: 100% !important;
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
    }

    .bottom-text {
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-transform: uppercase;
        color: #92a2a9;
        margin-top: 12px;
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 105%;
        color: #264653;
      }

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #009de0;
        margin-top: 10px;
      }
      .dis-price {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #009de0;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    button {
      padding: 12px 16px;
      width: 100%;
    }

    .top-abs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: left;

      .off {
        background: #009de0;
        border-radius: 5px;
        padding: 4px 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 155%;
        color: #ffffff;
      }
    }
  }
  .card-topsaverrr {
    background: #ffffff;
    border: 1px solid #d9f0fa;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    transition: 0.3s linear;
    height: 295px;
    overflow: hidden;

    &:hover {
      background: #ffffff;
      border: 1px solid #d9f0fa;
      box-shadow: 0px 13px 30px rgba(0, 157, 224, 0.1);
      border-radius: 10px;
    }

    .inner-img {
      width: 100%;
      height: 190px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      .btn-add{
        position: absolute;
        right: 5px;
        bottom: 8px;
    }
    .add-counter{
        border-radius: 51px;
        border: 1px solid #009DE0;
        background: #FFF;
        box-shadow: 0px 1px 7px 0px rgba(0, 157, 224, 0.30);
        padding: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 96px;
        width: 100%;
        span{
            color: #264653;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 155%; /* 24.8px */
        }
    }

      img {
        width: 100% !important;
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
    }

    .bottom-text {
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-transform: uppercase;
        color: #92a2a9;
        margin-top: 12px;
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 105%;
        color: #264653;
      }

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #92a2a9;
        margin-top: 10px;
      }
      .dis-price {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        color: #009de0;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    button {
      padding: 12px 16px;
      width: 100%;
    }

    .top-abs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: left;

      .off {
        background: #009de0;
        border-radius: 5px;
        padding: 4px 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 155%;
        color: #ffffff;
      }
    }
  }

  .new-category {
    border-radius: 10px;
    border: 1px solid #ECF9FF;
    background: #FFF;

    .main-heading {
      border-bottom: 1px solid #ECF9FF;
      padding: 15px;

      h6 {
        color: #264653;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
    }

    .accordion {
      .accordion-item {
        border-radius: 0;
        background-color: transparent;

        .accordion-button {
          border-radius: 0;
          background-color: transparent;
          color: #264653;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          padding: 14px 15px;
          box-shadow: none;

          &::after {
            background-image: url("../../assets/plus-icon.svg") !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

          }

          &[aria-expanded="true"] {
            &::after {
              background-image: url("../../assets/minus.svg") !important;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;

            }
          }
        }

        .accordion-body {
          padding: 10px 15px;

          .inner-content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            flex-direction: column;

            a {
              color: #36535F;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              // text-transform: lowercase;
            }
            .a-active {
             a{
              color: #009DE0 !important;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              svg{
                display: block !important;
              }
             }
            }
          }

          .brand-sec {
            .option-field {
              position: relative;
              border-bottom: 1px solid #ECF9FF;
              background: #FFF;
              padding: 8px 0;

              input {
                border: none;
                color: #36535F;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                width: 100%;
                padding-left: 24px;

                &::placeholder {
                  color: #A3A3A3;
                }
              }

              .search-iconn {
                position: absolute;
                top: 12px;
                left: 0px;
              }
            }

            .bottom-content {
              max-height: 300px;
              overflow-y: auto;
              &::-webkit-scrollbar{
                display: none;
              }
              .twice-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 12px;

                a {
                  color: #36535F;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  text-transform: capitalize;
                }
              

                p {
                  color: #A3A3A3;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  /* 12px */
                }
              }
              .a-active {
                a{
                 color: #009DE0 !important;
                 font-size: 16px;
                 font-style: normal;
                 font-weight: 400;
                 line-height: 120%;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 gap: 5px;
                 svg{
                   display: block !important;
                 }
                }
               }
            }
          }

          .shopbyprice {
            .twice-field {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;

              .option-field {
                flex: 0 0 48%;

                label {
                  color: #264653;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  margin-bottom: 5px;
                }

                input {
                  border-radius: 4px;
                  border: 1px solid #ECF9FF;
                  background: #FFF;
                  padding: 10px;
                  color: #264653;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  /* 14.4px */
                  width: 100%;

                  &::placeholder {
                    color: #A3A3A3;
                  }
                }
              }
            }

            button {
              border-radius: 4px;
              border: 1px solid #009DE0;
              background: #FFF;
              padding: 8px 10px;
              color: #009DE0;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              /* 14.4px */
              width: 100%;
            }
          }
        }
      }

    }
  }

  .upper-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;
    margin-bottom: 20px;

    .left-side {
      h6 {
        color: #929292;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;

        span {
          color: #264653;
        }
      }

      p {
        color: #A3A3A3;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.4px */
      }
    }

    .right-side {
      .dropdown {
        .dropdown-toggle {
          border-radius: 8px;
          border: 1px solid #D9F0FA;
          background: #FFF;
          padding: 11px 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          color: #616161;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;

          /* 14.4px */
          span {
            color: #000;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 8px;
          border: 1px solid #D9F0FA;
          background: #FFF;
          padding: 11px 17px;

          a {
            color: #616161;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 14.4px */
          }
        }

      }
    }
  }


}

@media (max-width: 600px) {
  .main-category .headingall {
    margin-top: 50px;
    margin-bottom: 25px;
  }


  .main-category .new-category {
    display: none;
  }

  .main-category .upper-content {
    display: none;
  }

  .main-category {
    padding-top: 115px;
  }
  .main-category .row > *{
    padding: 0 !important;
  }
  // .main-category .mobile-btns .button2{
  //   width: 100% !important;
  //   margin: 0 auto;
  // }
}

@media (max-width: 991px) {
  .showmore {
    display: block !important;
    background: #264653;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 90px;
  }

  .main-category .wrapper-shop {
    display: none !important;
  }

  .main-category .main-para p {
    display: none !important;
  }

  .mobile-btns {
    display: block !important;
    // margin-top: -70px;
    margin-bottom: 20px;
  }

  .cart-sidebar .offcanvas-header .btn-close {
    position: absolute;
    top: 18px !important;
    right: 31px !important;
  }

  .main-category .headingall {
    margin-top: 50px;
    margin-bottom: 25px;
  }
}

.cart-sidebar {
  .offcanvas-header {
    background: transparent;
  }

  .shop-category {
    .categoryhead {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        color: #264653;
        margin-bottom: 20px;
      }
    }

    .categormblmain {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      white-space: wrap;
      width: 100%;
      max-height: 250px;
      gap: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .accordion-item {
    border: none !important;

    .accordion-header {
      .twice-btns {
        margin-bottom: 10px;

        .accordion-button {
          box-shadow: none !important;
        }

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #757575;

          background: #f3f3f3;
          border-radius: 5px;
          padding: 12px;
          white-space: nowrap;

          &:hover {
            background: #009de0;
            color: #ffff;
          }
        }
      }

      .fruits {
        background: transparent;
        border-radius: 5px;
        padding: 8px 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #ffffff;
        border: none !important;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .accordion-body {
      .buttonbody {
        .btn1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          border: none;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            border: none;
            color: #757575;
            display: flex;
            justify-content: space-between;
          }

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #b2b2b2;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .pricerange {
    .categormblmain {
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      white-space: wrap;
      width: 100%;
      max-height: 250px;
      gap: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .rangehead {
      margin-bottom: 20px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        margin-top: 30px;
        color: #264653;
      }
    }

    .buttons {
      .allbtn {
        margin-bottom: 10px;

        button {
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #757575;
          border: none;
          margin-bottom: 10px;
          text-align: start;

          &:hover {
            // background: #009de0;
            color: #ffff;
            cursor: pointer;
          }

          &.active {
            background: #009de0;
            color: #ffff;
            cursor: pointer;
          }
        }

        .button-active {
          background: #f3f3f3;
          border-radius: 5px;
          padding: 8px 12px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          background: #009de0;
          color: #ffff;
          border: none;
          margin-bottom: 10px;
          text-align: start;

          &:hover {
            background: #5ba7c8;
            color: rgb(194, 194, 194);
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sub-items {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}

.twice-btns {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #757575;

  background: #f3f3f3;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #009de0;
    color: #ffff;
    cursor: pointer;
  }
}

.twice-btns-active {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;

  background: #009de0;
  color: #ffff;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #5ba7c8;
    color: rgb(194, 194, 194);
    cursor: pointer;
  }
}

.price-range-filter-btns {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #757575;

  background: #f3f3f3;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #009de0;
    color: #ffff;
    cursor: pointer;
  }
}

.price-range-filter-active {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;

  background: #009de0;
  color: #ffff;
  border-radius: 5px;
  padding: 12px;
  white-space: nowrap;

  &:hover {
    background: #5ba7c8;
    color: rgb(194, 194, 194);
    cursor: pointer;
  }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .main-category .left-sidee .loader-image img {
    margin-top: 135px;
  }

  .main-category .headingall h2 {
    margin-top: 110px;
    color: white;
  }

  .twice-btns {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #757575 !important;

    background: #f3f3f3 !important;
    border-radius: 5px;
    padding: 12px;
    white-space: nowrap;


  }

  .twice-btns-active {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;

    background: #009de0;
    color: #ffff;
    border-radius: 5px;
    padding: 12px;
    white-space: nowrap;

    &:hover {
      background: #5ba7c8;
      color: rgb(194, 194, 194);
      cursor: pointer;
    }
  }

  .apply22 {
    background-color: #009DE0;
    padding: 12px 18px;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    width: 97%;
    margin-right: 20px;
  }
}

.no-products {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-top: 300px;
}


.set-offcanvas-new-style {
  height: 80vh !important;
  width: 100% !important;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #ECF9FF;
  background: #FFF;

  .new-category {
    border-radius: 10px;
    border: 1px solid #ECF9FF;
    background: #FFF;

    .main-heading {
      border-bottom: 1px solid #ECF9FF;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        color: #264653;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
    }

    .accordion {
      .accordion-item {
        border-radius: 0;
        background-color: transparent;

        .accordion-button {
          border-radius: 0;
          background-color: transparent;
          color: #264653;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          padding: 14px 15px;
          box-shadow: none;

          &::after {
            background-image: url("../../assets/plus-icon.svg") !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

          }

          &[aria-expanded="true"] {
            &::after {
              background-image: url("../../assets/minus.svg") !important;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;

            }
          }
        }

        .accordion-body {
          padding: 10px 15px;

          .inner-content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            flex-direction: column;

            a {
              color: #36535F !important;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              background-color: transparent !important;
              border: none !important;
              padding: 0 !important;
            }
            .a-active {
              a{
               color: #009DE0 !important;
               font-size: 16px;
               font-style: normal;
               font-weight: 400;
               line-height: 120%;
               display: flex;
               align-items: center;
               justify-content: center;
               gap: 5px;
               svg{
                 display: block !important;
               }
              }
             }
          }

          .brand-sec {
            .option-field {
              position: relative;
              border-bottom: 1px solid #ECF9FF;
              background: #FFF;
              padding: 8px 0;

              input {
                border: none;
                color: #36535F;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                width: 100%;
                padding-left: 24px;

                &::placeholder {
                  color: #A3A3A3;
                }
              }

              .search-iconn {
                position: absolute;
                top: 12px;
                left: 0px;
              }
            }

            .bottom-content {
              .twice-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 12px;

                a {
                  color: #36535F !important;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  background-color: transparent !important;
                  border: none !important;
                  text-transform: capitalize;
                }

                p {
                  color: #A3A3A3;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  /* 12px */
                }
              }
              .a-active {
                a{
                 color: #009DE0 !important;
                 font-size: 16px;
                 font-style: normal;
                 font-weight: 400;
                 line-height: 120%;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 gap: 5px;
                 svg{
                   display: block !important;
                 }
                }
               }
            }
          }

          .shopbyprice {
            .twice-field {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;

              .option-field {
                flex: 0 0 48%;

                label {
                  color: #264653;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  margin-bottom: 5px;
                }

                input {
                  border-radius: 4px;
                  border: 1px solid #ECF9FF;
                  background: #FFF;
                  padding: 10px;
                  color: #264653;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  /* 14.4px */
                  width: 100%;

                  &::placeholder {
                    color: #A3A3A3;
                  }
                }
              }
            }

            button {
              border-radius: 4px;
              border: 1px solid #009DE0;
              background: #FFF;
              padding: 8px 10px;
              color: #009DE0;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              /* 14.4px */
              width: 100%;
            }
          }
        }
      }

    }
  }

  .offcanvas-header {
    padding: 0;
  }

  .offcanvas-body {
    padding: 0 !important;
  }

  .bottom-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 15px 25px;
    background-color: #fff;
    z-index: 99;

    button {
      flex: 0 0 48%;
      padding: 17px 35px;
      border: none;
    }

    .btn-closee {
      border-radius: 5px;
      background: #ECF9FF;
      color: #009DE0;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      text-transform: uppercase;
    }
    .btn-showw{
      border-radius: 5px;
      background: #009DE0;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      text-transform: uppercase;
    }
  }
}