.main-footer {
    border-top: 1px solid #D1EDFA;
    .link {
        display: flex;
        margin-top: 20px;

        p {
            font-size: 12px;
            font-weight: 500;
            color: #92A2A9;
            margin-top: 5px;
        }

    }

    .card {
        position: relative;
        border: none;
        margin-top: 20px;

        .handbox {
            width: 39px;
            height: 39px;
            position: absolute;
        }

        .text {
            margin-left: 50px;
            text-align: left;


            h6 {
                font-size: 18px;
                font-weight: 600;
                color: #264653;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #92A2A9;
            }
        }

    }

    hr {
        color: #D1EDFA;
        margin: 50px 0;
        margin-top: 25px;
    }

    .info {

        .logo {
            width: 92.75px;
            height: 42px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        p {
            font-size: 12px !important;
            color: #92A2A9;
            margin: 20px 0;
            font-weight: 400;
        }

    }

    .num {
        margin-top: 8px;
        display: flex;
        margin-bottom: 14px;

        p {
            color: #264653;
            font-size: 12px !important;
            font-weight: 500;
            margin-left: 10px;
        }
    }

    .line {

        h4 {
            color: #264653;
            font-size: 16px;
            font-weight: 600;
            margin-top: 20px;
        }

        .list {
            margin-top: 20px;

            p {
                color: #92A2A9;
                font-size: 12px !important;
                font-weight: 500;
                margin-top: 8px;
            }

            .para {
                color: #92A2A9;
                font-size: 12px !important;
                font-weight: 500;
                margin-top: 5px;
            }
        }

        .input {
            margin-top: 20px;
            position: relative;

            .email {
                width: 100%;
                padding: 12px 35px;
                border: 1px solid #D1EDFA;
                border-radius: 2px;

            }

            .email::placeholder {
                color: #92A2A9;
                font-size: 12px;
                font-weight: 500;

            }

            .sms {
                position: absolute;
                left: 12px !important;
                top: 18px;
            }

            .send {
                background: #009DE0;
                border-radius: 2px;
                padding: 8px 22px;
                border: none;
                font-size: 12px;
                font-weight: 500;
                color: white;
                position: absolute;
                right: 12px;
                top: 8px;
            }
        }

   
    }

    .hr {
        color: 1px solid #D1EDFA;
        margin-top: 60px;
        border: none !important;
    }

    .last {
        p {
            color: #92A2A9;
            font-size: 10px;
            font-weight: 500;
            margin-top: -30px;
            padding: 10px 0;
            background: #FFFFFF;
            border-top: 1px solid #D1EDFA;
        }

        .spa {
            color: #009DE0;
        }
    }
}






@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {

    .main-footer {

        .link{
            display: flex;
            justify-content: center;
        }
        .line {

            // text-align: left;
            .list {
                .para {
                    font-size: 12px !important;
                }
            }

            .link {
                display: flex;
                justify-content: center;
            }
        }
    }

    .main-footer .num {
        display: flex;
        justify-content: center;
    }

    .main-footer .card {
        display: -webkit-inline-box;
    }
}




@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .main-footer {
        .info {
            p {
                font-size: 10px !important;
            }
        }

        .line {
            .link {
                p {
                    font-size: 12px;
                }
            }

            .list {
                .para {
                    font-size: 9px !important;
                }
            }
        }
    }


}







@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) {

    .main-footer {
        .card {
            .text {
                h6 {
                    font-size: 13px;
                }

                p {
                    font-size: 10px;
                }
            }
        }

        .info {
            p {
                font-size: 10px !important;
            }
        }

        .line {
            .list {
                .para {
                    font-size: 10px !important;
                }
            }
        }

    }

}


@media (max-width:600px) {
    .main-footer .num {
        margin-bottom: 15px;
    }

    .main-footer .line .list p {
        margin-top: 15px;
    }

    .main-footer .social-icons-footer {
        display: flex;
        align-items: center;
    }

    .main-footer .card {
        margin-top: 40px;
    }
}

.line-handle {
    border-top: 1.5px solid #D1EDFA;
    opacity: 1;
    width: 100%;
}