.main-faqs {
    background: #FFFFFF;
    padding: 28px 0px;
    padding-bottom: 90px;

    .main-parent{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .info{
            width: 20%;
        }
        .head{
            width: 38%;
        }
        .head1{
            width: 35%;
        }
    }

    .faqshead {
        padding-top: 50px;
        padding-bottom: 46px;
        
        h2 {
            font-weight: 500;
            font-size: 40px;
            line-height: 100%;
            color: #264653;
            text-align: center;
        }
    }

    .info {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            margin-bottom: 30px;
            color: #264653;
        }

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            color: #264653;
            margin-bottom: 30px;
        }

        h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            margin-bottom: 50px;
            color: #264653;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            color: #264653;
            margin-bottom: 15px;
        }

        button {
            background: #009DE0;
            border-radius: 5px;
            padding: 20px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            border: none;
            width: 70%;
        }
    }

    .head {
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 135%;
            color: #264653;
            margin-bottom: 10px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 135%;
            color: #A4A4A4;
        }
        
    .brdr {

        border: 1px solid rgba(0, 157, 224, 0.05);
        margin-top: 25px;
        margin-bottom: 25px;

    }
    }
    .head1 {
        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 135%;
            color: #264653;
            margin-bottom: 10px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 135%;
            color: #A4A4A4;
        }
        
    .brdr {

        border: 1px solid rgba(0, 157, 224, 0.05);
        margin-top: 25px;
        margin-bottom: 25px;

    }
    }

}

.faqs1{
    h3{
        color: #009DE0 !important;
    }
}

.faqs2{
    h4{
        color: #009DE0 !important;
    }
}

.faqs3{
    .color-spec{
        color: #009DE0 !important;
    }
}
@media(max-width:600px){
    .main-faqs .faqshead h2{
        font-size: 30px;
    }
    .main-faqs .info button{
        margin-bottom: 68px;
    }
    .main-faqs .faqshead{
        margin-top: 56px;
    }
    .main-faqs .maincart-card{
        display: none !important;
    }
    .main-faqs .main-parent{
        flex-direction: column;
    }
    .main-faqs .main-parent .info{
        width: 100%;
    }
    .main-faqs .main-parent .head{
        width: 100%;
    }
    .main-faqs .main-parent .head1{
        width: 100%;
    }
    .brdr-mbl{
        border: 1px solid rgba(0, 157, 224, 0.05);
        margin-top: 25px;
        margin-bottom: 25px;
    }
}


.marg-set{
    margin-bottom: 50px !important;
}